export const COUNT_ATTEMPTS_LEFT = 4;

export class CreatePin {
  pinSetup: string;
  confirmPinSetup: string;
  error: string;

  constructor() {
    this.pinSetup = "";
    this.confirmPinSetup = "";
    this.error = "";
  }
}

export class ChangePin {
  pinChange: string;
  confirmPinChange: string;
  error: string;

  constructor() {
    this.pinChange = "";
    this.confirmPinChange = "";
    this.error = "";
  }
}

export class VerifyPin {
  resetClicked: boolean;
  error: string;
  attemptsLeft: number;
  isPinVerified: boolean;

  constructor() {
    this.resetClicked = false;
    this.error = "";
    this.attemptsLeft = COUNT_ATTEMPTS_LEFT;
    this.isPinVerified = false;
  }
}

export class FlagMySelf {
  isEnablePin: boolean;
  isLocked: boolean;
  isPageLoading: boolean;
  pinCodeHide: string;
  constructor() {
    this.flag = false;
    this.isLocked = false;
    this.isPageLoading = true;
    this.pinCodeHide = "";
  }
}
