import * as React from "react";
import TableHead from "@mui/material/TableHead";
import { TableRow, TableSortLabel } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import type ColumnModel from "model/Column.model";

export interface IEnhancedTableProps {
  order: "asc" | "desc";
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<>, property: any) => void;
  columns: ColumnModel[];
}

const EnhancedTableHead: React.FC<IEnhancedTableProps> = (props) => {
  const { order, orderBy, onRequestSort, columns } = props;

  const createSortHandler = (property: any) => (event: React.MouseEvent<>) => {
    if (onRequestSort) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead className="table-header">
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth, ...column.styleHeader }}
          >
            {column.sortable && (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}

            {!column.sortable && <>{column.label}</>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
