import { toast } from "react-toastify";
import { isRejectedWithValue } from "@reduxjs/toolkit";

const errorBlackList = ["refreshToken"];

export const apiErrorMiddleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { meta, payload } = action;
    const endpoint = meta.arg?.endpointName ?? "";
    if (errorBlackList.includes(endpoint)) {
      return next(action);
    }

    const statusCode = payload?.status;
    if (statusCode === 404) {
      toast.error("Resource not found (404)", {
        data: {
          title: "404 Not Found",
          text: "The requested resource could not be found.",
        },
      });
    } else if (statusCode === 500) {
      toast.error("Internal server error (500)", {
        data: {
          title: "500 Internal Server Error",
          text: "Something went wrong on the server.",
        },
      });
    }
  }
  return next(action);
};
