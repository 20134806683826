import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";
import { WorkshopTabEnum } from "data/my-development/MyDevelopment.data";
import { WorkshopUtil } from "utilities/workshopUtil";

const initialState = {
  callGetPhaseBoardOfCourseTab: {
    data: null,
    isLoading: false,
    error: null,
  },
  callGetListPhaseOfCourseTab: {
    data: null,
    isLoading: false,
    error: null,
  },
  callGetDataPageWorkshopTab: {
    data: null,
    isLoading: false,
    error: null,
    dataTableAvailable: [],
    dataTableRegistered: [],
    dataTableAttended: [],
  },
  callGetDataPageObservationTab: {
    data: [],
    currentPage: 0,
    pageSize: 5,
    totalPage: 0,
    totalElement: 0,
    isLoading: false,
    error: null,
    filter: {
      date: null,
      observation_type: [],
    },
  },
  callGetDataDetailObservationTab: {
    data: {
      // listHaveScore: [],
      // listNotHaveScore: [],
      mapGroups: {},
      dataHaveScore: {}, // convert listHaveScore to dataHaveScore to show in dialog
      listDataNotHaveScore: [], // convert listNotHaveScore to dataNotHaveScore to show in dialog
      probationResult: null, // convert listNotHaveScore to dataNotHaveScore to show in dialog
    },
    isLoading: false,
    error: null,
  },
};

const sliceMyDevelopment = createSlice({
  name: "myDevelopment",
  initialState,
  reducers: {
    setCallGetDataPageObservationTabPagination: (state, action) => {
      const { currentPage, pageSize } = action.payload;
      state.callGetDataPageObservationTab.currentPage = currentPage;
      state.callGetDataPageObservationTab.pageSize = pageSize;
    },
    setCallGetDataPageObservationTabFilter: (state, action) => {
      const { date, observation_type } = action.payload;
      state.callGetDataPageObservationTab.filter.date = date || null;
      state.callGetDataPageObservationTab.filter.observation_type = observation_type || [];
    },
  },
  extraReducers: (builder) => {
    // getPhaseBoardOfCourseTab API handlers
    builder
      .addMatcher(api.endpoints.getPhaseBoardOfCourseTab.matchPending, (state) => {
        state.callGetPhaseBoardOfCourseTab.isLoading = true;
        state.callGetPhaseBoardOfCourseTab.error = null;
      })
      .addMatcher(api.endpoints.getPhaseBoardOfCourseTab.matchFulfilled, (state, action) => {
        state.callGetPhaseBoardOfCourseTab.isLoading = false;
        state.callGetPhaseBoardOfCourseTab.data = action?.payload?.data || null;
      })
      .addMatcher(api.endpoints.getPhaseBoardOfCourseTab.matchRejected, (state, action) => {
        state.callGetPhaseBoardOfCourseTab.isLoading = false;
        state.callGetPhaseBoardOfCourseTab.error = action?.payload;
      });

    // getListPhaseOfCourseTab API handlers
    builder
      .addMatcher(api.endpoints.getListPhaseOfCourseTab.matchPending, (state) => {
        state.callGetListPhaseOfCourseTab.isLoading = true;
        state.callGetListPhaseOfCourseTab.error = null;
      })
      .addMatcher(api.endpoints.getListPhaseOfCourseTab.matchFulfilled, (state, action) => {
        state.callGetListPhaseOfCourseTab.isLoading = false;
        state.callGetListPhaseOfCourseTab.data = action?.payload?.data || null;
      })
      .addMatcher(api.endpoints.getListPhaseOfCourseTab.matchRejected, (state, action) => {
        state.callGetListPhaseOfCourseTab.isLoading = false;
        state.callGetListPhaseOfCourseTab.error = action?.payload;
      });

    // getDataOfWorkshopTab API handlers
    builder
      .addMatcher(api.endpoints.getDataOfWorkshopTab.matchPending, (state) => {
        state.callGetDataPageWorkshopTab.isLoading = true;
        state.callGetDataPageWorkshopTab.error = null;
      })
      .addMatcher(api.endpoints.getDataOfWorkshopTab.matchFulfilled, (state, action) => {
        state.callGetDataPageWorkshopTab.isLoading = false;
        state.callGetDataPageWorkshopTab.data = action?.payload;

        state.callGetDataPageWorkshopTab.dataTableAvailable =
          WorkshopUtil.convertDataFormWeLearnAPI(
            state.callGetDataPageWorkshopTab.data,
            WorkshopTabEnum.Available
          );
        state.callGetDataPageWorkshopTab.dataTableRegistered =
          WorkshopUtil.convertDataFormWeLearnAPI(
            state.callGetDataPageWorkshopTab.data,
            WorkshopTabEnum.Registered
          );
        state.callGetDataPageWorkshopTab.dataTableAttended = WorkshopUtil.convertDataFormWeLearnAPI(
          state.callGetDataPageWorkshopTab.data,
          WorkshopTabEnum.Attended
        );
      })
      .addMatcher(api.endpoints.getDataOfWorkshopTab.matchRejected, (state, action) => {
        state.callGetDataPageWorkshopTab.isLoading = false;
        state.callGetDataPageWorkshopTab.error = action?.payload;
      });

    // getDataPageOfObservationTab API handlers
    builder
      .addMatcher(api.endpoints.getDataPageOfObservationTab.matchPending, (state) => {
        state.callGetDataPageObservationTab.isLoading = true;
        state.callGetDataPageObservationTab.error = null;
      })
      .addMatcher(api.endpoints.getDataPageOfObservationTab.matchFulfilled, (state, action) => {
        state.callGetDataPageObservationTab.isLoading = false;
        state.callGetDataPageObservationTab.data = action?.payload?.data?.data || [];
        // state.callGetDataPageObservationTab.pageSize = action.payload?.data?.pageSize || 0;
        state.callGetDataPageObservationTab.totalPage = action?.payload?.data?.totalPage || 0;
        state.callGetDataPageObservationTab.totalElement = action?.payload?.data?.totalElement || 0;
        state.callGetDataPageObservationTab.currentPage = action?.payload?.data?.currentPage || 0;
      })
      .addMatcher(api.endpoints.getDataPageOfObservationTab.matchRejected, (state, action) => {
        state.callGetDataPageObservationTab.isLoading = false;
        state.callGetDataPageObservationTab.error = action?.payload;
      });

    builder
      .addMatcher(api.endpoints.getDataDetailOfObservationTab.matchPending, (state) => {
        state.callGetDataDetailObservationTab.isLoading = true;
        state.callGetDataDetailObservationTab.error = null;
      })
      .addMatcher(api.endpoints.getDataDetailOfObservationTab.matchFulfilled, (state, action) => {
        state.callGetDataDetailObservationTab.isLoading = false;

        const { listHaveScore, listNotHaveScore, probationResult } = action?.payload?.data || {};
        const map = new Map();
        state.callGetDataDetailObservationTab.data.dataHaveScore = listHaveScore?.reduce(
          (acc, item) => {
            if (!acc[item.group.code]) {
              acc[item.group.code] = [];
            }
            acc[item.group.code].push(item);
            map.set(item.group.code, item.group);
            return acc;
          },
          {}
        );
        state.callGetDataDetailObservationTab.data.mapGroups = map;

        let listDataNotHaveScore = [];
        let array = [];
        listNotHaveScore?.forEach((item) => {
          if (array.length === 3) {
            listDataNotHaveScore.push(array);
            array = [];
          }

          if (item.colSpan === 1) {
            array.push(item);
          }

          if (item.colSpan === 3) {
            listDataNotHaveScore.push([item]);
          }
        });
        listDataNotHaveScore.push(array);
        array = [];

        state.callGetDataDetailObservationTab.data.listDataNotHaveScore = listDataNotHaveScore;
        state.callGetDataDetailObservationTab.data.probationResult = probationResult;
        state.callGetDataDetailObservationTab.error = null;
      })
      .addMatcher(api.endpoints.getDataDetailOfObservationTab.matchRejected, (state, action) => {
        state.callGetDataDetailObservationTab.isLoading = false;
        state.callGetDataDetailObservationTab.error = action?.payload;
      });
  },
});

export const {
  setCallGetDataPageObservationTabFilter,
  setCallGetDataPageObservationTabPagination,
} = sliceMyDevelopment.actions;
export default sliceMyDevelopment.reducer;
