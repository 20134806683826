export interface Slot {
  id: number;
  start_time: string;
  duration: number;
  location: string;
  locationlink: string;
  max_participants: number;
  visible: number;
  trainers: string[];
  availability: {
    available_from: string | null,
  };
  registration_status: {
    is_registered: boolean,
    has_attended: boolean | null,
    grade: string | null,
    feedback: string | null,
  };
}

export interface Organizer {
  id: number;
  name: string;
  description: string;
  registration_period: {
    start: string,
    end: string | null,
  };
  slots: {
    registered: Slot[],
    unregistered: Slot[],
    old: Slot[],
  };
}

export interface Course {
  course_info: {
    id: number,
    fullname: string,
    category_name: string,
  };
  organizers: Organizer[];
}

export interface WorkshopDataResult {
  status: boolean;
  message: string;
  user_info: {
    username: string,
    status: string,
  };
  courses: Course[];
}

export type Tab = {
  index: number,
  title: string,
  link: string,
};

export class FlagMyDevelopment {
  isPageLoading: boolean;
  isLoadingTab: boolean;

  constructor() {
    this.isPageLoading = true;
    this.isLoadingTab = true;
  }
}
