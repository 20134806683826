import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import WeeklyCalendar from "./components/WeeklCalendar";
import MonthCalendar, { IMonthCalendarProps } from "./components/MonthCalendar";
import calendarStyles from "./style";
import { useCallback, useState } from "react";
import moment from "moment";
import { useMyDevelopmentAPIs } from "shared/redux/endpoints/endPointMyDevelopment";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
import type { Error400 } from "utilities/toastUtil";
import { ToastUtil } from "utilities/toastUtil";
import { resetCallGetCalendarWorkshop } from "shared/redux/slices/sliceMyCalendar";
import type { IWeeklyCalendarProps } from "./components/WeeklCalendar";

function MyCalendar() {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const { user } = auth;
  const [start, setStart] = useState(moment().startOf("isoWeek").weekday(1).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(moment().startOf("isoWeek").weekday(7).format("YYYY-MM-DD"));

  // get instance call API
  const getDataPageOfWorkshopTab = useMyDevelopmentAPIs.useGetDataPageOfWorkshopTab();

  // func call API
  const callGetDataPageOfWorkshopTab = useCallback(async () => {
    let email = user.email;
    await getDataPageOfWorkshopTab({ email }, undefined, undefined, (error: Error400) => {
      ToastUtil.showToastError400(error);
    });
  }, [getDataPageOfWorkshopTab]);

  // result API
  const { dataWorkShop } = useSelector((state) => state.myCalendar.callGetCalendarWorkshop);

  const propertiesWeeklyCalendar: IWeeklyCalendarProps = {
    outerDate: start,
    dataWorkShop: dataWorkShop,
    onChangeTab: async (tab) => {
      if (tab.value === "all" || tab.value === "training") {
        await callGetDataPageOfWorkshopTab();
      } else {
        dispatch(resetCallGetCalendarWorkshop());
      }
    },
  };

  const propertiesMonthCalendar: IMonthCalendarProps = {
    start,
    setStart,
    end,
    setEnd,
    dataWorkShop,
  };

  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={calendarStyles}>
        <MDBox
          sx={() => ({
            width: "100%",
          })}
        >
          <Grid container columnSpacing={2} rowGap={2}>
            <Grid item xs={12} xl>
              <WeeklyCalendar {...propertiesWeeklyCalendar} />
            </Grid>
            <Grid item xs={12} xl="auto" mb={{ xs: 3, xl: 0 }}>
              <MonthCalendar {...propertiesMonthCalendar} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default MyCalendar;
