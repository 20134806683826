import { api } from "../api";
import { useLazyQueryWithCallbacks } from "../../hooks/useLazyQueryWithCallbacks";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPhaseBoardOfCourseTab: build.query({
      query: () => {
        return {
          url: `/private/development/v1/progress`,
        };
      },
    }),
    getListPhaseOfCourseTab: build.query({
      query: () => {
        return {
          url: `/private/development/v1/course`,
        };
      },
    }),
    getDataOfWorkshopTab: build.query({
      query: (params) => {
        const { email } = params;
        return {
          url: `https://dl.apollo.vn/api/v1/workshops/?format=json&username=${email}`,
        };
      },
    }),
    getDataPageOfObservationTab: build.query({
      query: ({ studentCode, page, size, observationType, dateStart, dateEnd }) => {
        // Convert observationType array to a comma-separated string
        const observationTypeParam = observationType?.join(",");

        // Function to format the date to 'yyyy-MM-dd'
        const formatDate = (date) => {
          if (date instanceof Date) {
            return date.toLocaleDateString("en-CA"); // "en-CA" returns date in 'yyyy-MM-dd' format
          }
          return "";
        };

        // Initialize the base URL
        let url = `/private/my-development/v1/observation?page=${page}&size=${size}&sort=id%2Casc`;

        // Add dateStart and dateEnd parameters if they are Date objects
        if (dateStart instanceof Date) {
          url += `&dateStart=${formatDate(dateStart)}`;
        }
        if (dateEnd instanceof Date) {
          url += `&dateEnd=${formatDate(dateEnd)}`;
        }

        // Add observationType parameter if it's provided
        if (observationTypeParam) {
          url += `&observationType=${observationTypeParam}`;
        }

        // Return the URL with the parameters
        return {
          url: url,
        };
      },
    }),
    getDataDetailOfObservationTab: build.query({
      query: (params) => {
        const { id, isOnline } = params;
        return {
          url: `/private/my-development/v1/observation/detail/${id}?isOnline=${isOnline}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetPhaseBoardOfCourseTabQuery,
  useLazyGetListPhaseOfCourseTabQuery,
  useLazyGetDataOfWorkshopTabQuery,
  useLazyGetDataPageOfObservationTabQuery,
  useLazyGetDataDetailOfObservationTabQuery,
} = injectedRtkApi;

export const useMyDevelopmentAPIs = {
  useGetPhaseBoardOfCourseTab: useLazyQueryWithCallbacks(useLazyGetPhaseBoardOfCourseTabQuery),
  useGetListPhaseOfCourseTab: useLazyQueryWithCallbacks(useLazyGetListPhaseOfCourseTabQuery),
  useGetDataPageOfWorkshopTab: useLazyQueryWithCallbacks(useLazyGetDataOfWorkshopTabQuery),
  useGetDataPageOfObservationTab: useLazyQueryWithCallbacks(
    useLazyGetDataPageOfObservationTabQuery
  ),
  useGetDataDetailOfObservationTab: useLazyQueryWithCallbacks(
    useLazyGetDataDetailOfObservationTabQuery
  ),
};
