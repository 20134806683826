import { useLazyQueryWithCallbacks } from "../../hooks/useLazyQueryWithCallbacks";
import { api } from "../api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getListLink: build.query({
      query: () => {
        return {
          url: `/private/home-page/v1/links`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetListLinkQuery } = injectedRtkApi;

export const useHomePage = {
  useGetListLink: useLazyQueryWithCallbacks(useLazyGetListLinkQuery),
};
