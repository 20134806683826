import Card from "@mui/material/Card";
import RocketPin from "assets/images/apollo-english/rocket-pin.png";
import PinInput from "react-pin-input";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import type { VerifyPin } from "model/MySelfContainer.model";

export interface PinInputCardProps {
  verifyPin: VerifyPin;
  setVerifyPin: (value: VerifyPin) => void;
  onPinVerification: (verifyPin: VerifyPin) => void;
  onResetPin: () => void;
}

const PinInputCard = (props: PinInputCardProps) => {
  const { isLoading: isLoadingPostVerifyPin } = useSelector(
    (state) => state.mySelf.callPostVerifyPin
  );
  const { isLoading: isLoadingPostResetPin } = useSelector(
    (state) => state.mySelf.callPostResetPin
  );

  const { verifyPin, setVerifyPin, onPinVerification, onResetPin } = props;
  const pinInputRef = useRef(null);

  const onChange = (value) => {
    if (!isLoadingPostVerifyPin && value.length === 4) {
      onPinVerification(value);
    }
  };

  const onClickResetPin = (e) => {
    e.preventDefault();
    if (!isLoadingPostResetPin) {
      onResetPin();
    }
  };

  useEffect(() => {
    if (verifyPin.error && pinInputRef.current) {
      setVerifyPin((prevState) => {
        return { ...prevState, resetClicked: false };
      });
      pinInputRef.current.clear();
    }
  }, [isLoadingPostVerifyPin, verifyPin.error]);

  return (
    <Card className="pin-input-card-container" style={{ backgroundColor: "#CCE7FF" }}>
      <img src={RocketPin} alt="RocketPin" />
      <h2>Enter your PIN</h2>
      <div className="pin-input">
        <PinInput
          ref={pinInputRef}
          length={4}
          initialValue=""
          secret
          secretDelay={500}
          type="numeric"
          inputMode="number"
          style={{ padding: "10px 0 20px 0px" }}
          inputStyle={{
            width: "64px",
            height: "65px",
            borderRadius: "8px",
            border: "1px solid #E0E0E0",
            fontSize: "24px",
            textAlign: "center",
            backgroundColor: "#FFFFFF",
          }}
          inputFocusStyle={{
            border: "1px solid #575757",
          }}
          onChange={onChange}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          disabled={isLoadingPostVerifyPin}
        />
      </div>
      {verifyPin.error ? (
        verifyPin.resetClicked ? (
          <p className="error">Reset instructions have been sent to your email.</p>
        ) : (
          <>
            <p className="error">PIN does not match. Please try again.</p>
            <p className="error">You have {verifyPin.attemptsLeft} attempts.</p>
            <p className="error">
              Or don’t remember the PIN? Click{" "}
              <a onClick={onClickResetPin} style={{ cursor: "pointer", color: "#F5365C" }}>
                {isLoadingPostResetPin && <CircularProgress size={12} />}
                here
              </a>{" "}
              to reset.
            </p>
          </>
        )
      ) : (
        <p className="forgot-pin">
          {verifyPin.resetClicked ? (
            "Reset instructions have been sent to your email."
          ) : (
            <>
              Forgot your PIN code? Click{" "}
              <a onClick={onClickResetPin} style={{ cursor: "pointer", color: "#8392AB" }}>
                {isLoadingPostResetPin && <CircularProgress size={12} />}
                here
              </a>{" "}
              to reset.
            </>
          )}
        </p>
      )}
    </Card>
  );
};

export default PinInputCard;
