import React, { useCallback, useEffect, useState } from "react";
import { Button, Table, TableBody, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import {
  tableTextStyle,
  tableContainerStyle,
  tableStyle,
  teacherManagementStyle,
  titleStyle,
  tableHeadStyle,
  pagingItemStyle,
  subTitleStyle,
} from "./style";
import {
  Add,
  ChevronLeft,
  ChevronRight,
  Delete,
  EditNote,
  ExpandLess,
  ExpandMore,
  RemoveRedEye,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import CreateBanner from "../CreateBanner";
import { descriptionTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";
import DeleteBanner from "../DeleteBanner";
import UploadImage from "../UploadImage";
import ChangeStatus from "../ChangeStatus";
import EditLink from "../EditLink";
import { CustomSelect } from "shared/components/ui/CustomSelect";
import { useLazyGetBannersQuery } from "shared/redux/endpoints/banner";
import _ from "lodash";
import { Loading } from "shared/components/ui/Loading";

const EnhancedTableHead = ({ title }) => {
  return (
    <MDBox
      sx={({ breakpoints, functions: { pxToRem } }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: pxToRem(24),

        [breakpoints.down(1367)]: {
          marginRight: pxToRem(16),
        },
      })}
    >
      <MDTypography sx={(theme) => tableHeadStyle(theme)}>{title}</MDTypography>
      <MDBox sx={() => ({ display: "flex", alignItems: "center" })}>
        <ExpandLess sx={({ palette }) => ({ color: palette.textColors.secondary })} />
        <ExpandMore sx={({ palette }) => ({ color: palette.textColors.secondary })} />
      </MDBox>
    </MDBox>
  );
};

EnhancedTableHead.propTypes = {
  title: PropTypes.string.isRequired,
};

function BannerManagement() {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);

  const [sort, changeSort] = useState("created%2Cdesc");
  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");
  const [size, setSize] = useState(10);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [bannerIdToDelete, setBannerIdToDelete] = useState("");

  const [triggerBannerss, { isLoading }] = useLazyGetBannersQuery();

  const fetchBanners = async () => {
    const response = await triggerBannerss({
      sort: sort ?? "",
      page,
      size,
      title: query,
      type: "",
    });
    if (response?.isSuccess && response?.data?.status) {
      setTotalPage(response?.data?.data?.totalPage ?? 0);
      setData(response?.data?.data?.data);
    }
  };

  const debouncedFetchResults = useCallback(
    _.debounce((searchTerm) => setQuery(searchTerm), 500),
    []
  );

  useEffect(() => {
    fetchBanners();
  }, [page, sort, query, size]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpload, setOpenUpload] = useState(false);
  const [bannerToUpload, setBannerToUpload] = useState(null);
  const handleOpenUpload = () => setOpenUpload(true);
  const handleCloseUpload = () => setOpenUpload(false);

  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [bannerToChangeStatus, setBannerToChangeStatus] = useState(null);
  const handleOpenChangeStatus = () => setOpenChangeStatus(true);
  const handleCloseChangeStatus = () => setOpenChangeStatus(false);

  const [openEditLink, setOpenEditLink] = useState(false);
  const [bannerToEditLink, setBannerToEditLink] = useState(null);
  const handleOpenEditLink = () => setOpenEditLink(true);
  const handleCloseEditLink = () => setOpenEditLink(false);

  const pageRange = [];
  for (let i = 1; i <= totalPage; i++) {
    pageRange.push(i);
  }

  const filteredPages = pageRange.slice(Math.max(page - 2, 0), Math.min(page + 3, totalPage + 1));

  const navigation = useNavigate();

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      columnSpacing={3}
      sx={(theme) => teacherManagementStyle(theme)}
    >
      <MDBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        sx={({ breakpoints }) => ({
          [breakpoints.down(768)]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        })}
      >
        <MDBox
          display="flex"
          sx={() => ({
            flexDirection: "column",
            alignItems: "flex-start",
          })}
        >
          <MDTypography sx={(theme) => titleStyle(theme)}>
            BANNER AND ANNOUNCEMENT MANAGEMENT
          </MDTypography>
          <MDTypography sx={(theme) => subTitleStyle(theme)}>
            Where you can create banner and announcement
          </MDTypography>
        </MDBox>
        <MDBox
          sx={({ breakpoints }) => ({
            [breakpoints.down(768)]: {
              width: "100%",
            },
          })}
        >
          <Button
            variant="contained"
            sx={({ breakpoints }) => ({
              color: "#FFFFFF",
              backgroundColor: "#5E72E4",
              fontFamily: "Lexend, san-serif",
              textTransform: "unset",
              minHeight: 30,
              height: 30,

              [breakpoints.down(768)]: {
                width: "100%",
              },
            })}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={() => {
              handleOpen();
            }}
          >
            <Add sx={{ color: "#FFFFFF", mr: 1 }} /> Add new
          </Button>
        </MDBox>
      </MDBox>

      <MDBox
        sx={({ breakpoints }) => ({
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,

          [breakpoints.down(768)]: {
            flexDirection: "column",
            gap: 2,
            alignItems: "flex-start",
          },
        })}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <CustomSelect
            options={[
              { value: 5, label: 5 },
              { value: 10, label: 10 },
              { value: 15, label: 15 },
              { value: 20, label: 20 },
              { value: 25, label: 25 },
            ]}
            setOuterValue={(value) => {
              setPage(0);
              setSize(value);
            }}
            defaultValue={size}
          />
          <MDTypography sx={({ breakpoints }) => ({ ...descriptionTypography(breakpoints) })}>
            entries per page
          </MDTypography>
        </MDBox>

        <MDInput
          placeholder="Search here"
          sx={({ breakpoints }) => ({
            [breakpoints.down(768)]: {
              width: "100%",
            },
          })}
          value={title}
          onChange={(event) => {
            const value = event.target.value;
            setTitle(value);
            debouncedFetchResults(value);
          }}
        />
      </MDBox>
      {isLoading ? (
        <MDBox width="100%" height={300} display="flex" justifyContent="center" alignItems="center">
          <Loading style={{ width: 150, height: 100 }} />
        </MDBox>
      ) : (
        <MDBox width="100%">
          <MDBox sx={(theme) => tableContainerStyle(theme)}>
            <Table sx={(theme) => tableStyle(theme)}>
              <MDBox component="thead" sx={{ borderBottom: "1px solid #DEE2E6" }}>
                <TableRow
                  sx={() => ({
                    height: "50px",
                    textAlign: "left",
                  })}
                >
                  <MDBox component="th">
                    <EnhancedTableHead title="ITEM" />
                  </MDBox>
                  <MDBox component="th">
                    <EnhancedTableHead title="CATEGORY" />
                  </MDBox>
                  <MDBox component="th">
                    <EnhancedTableHead title="IMAGE" />
                  </MDBox>
                  <MDBox component="th">
                    <EnhancedTableHead title="BY USER" />
                  </MDBox>
                  <MDBox component="th">
                    <EnhancedTableHead title="LINK" />
                  </MDBox>
                  <MDBox component="th">
                    <EnhancedTableHead title="STATUS" />
                  </MDBox>
                  <MDBox component="th">
                    <EnhancedTableHead title="ACTION" />
                  </MDBox>
                </TableRow>
              </MDBox>

              <TableBody>
                {(data ?? []).map((item, index) => (
                  <TableRow
                    key={index}
                    sx={({ breakpoints, typography, functions: { pxToRem } }) => ({
                      height: pxToRem(50),
                      textAlign: "left",
                      fontFamily: typography.lexendFont,
                      fontWeight: 700,
                      fontSize: 12,
                      lineHeight: 1.5,
                      color: "#344767",
                      borderBottom: "1px solid #DEE2E6",
                      cursor: "pointer",

                      [breakpoints.down(1367)]: {
                        height: pxToRem(40),
                      },
                    })}
                    onClick={() => {
                      // navigation(
                      //   `/notification-management/send-notification?id=${item.notificationContentId}`
                      // );
                    }}
                  >
                    <MDBox component="td">
                      <MDTypography
                        variant="span"
                        sx={(theme) => ({
                          ...tableTextStyle(theme),
                          fontWeight: 600,
                          color: "#344767",
                        })}
                      >
                        {item.title}
                      </MDTypography>
                    </MDBox>

                    <MDBox component="td">
                      <MDTypography
                        variant="span"
                        sx={(theme) => ({
                          ...tableTextStyle(theme),
                          fontWeight: 600,
                          color: "#344767",
                        })}
                      >
                        {item.type}
                      </MDTypography>
                    </MDBox>

                    <MDBox component="td">
                      <MDTypography
                        variant="span"
                        sx={(theme) => tableTextStyle(theme)}
                        onClick={(e) => {
                          e.stopPropagation();
                          setBannerToUpload(item);
                          setOpenUpload(true);
                        }}
                      >
                        Upload
                      </MDTypography>
                    </MDBox>
                    <MDBox component="td">
                      <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                        {item.createBy}
                      </MDTypography>
                    </MDBox>
                    <MDBox component="td">
                      <MDTypography
                        variant="span"
                        sx={(theme) => tableTextStyle(theme)}
                        onClick={(e) => {
                          e.stopPropagation();
                          setBannerToEditLink(item);
                          setOpenEditLink(true);
                        }}
                      >
                        Click to edit
                      </MDTypography>
                    </MDBox>
                    <MDBox component="td">
                      <MDBox
                        sx={{
                          background: item.active ? "#B0EED3" : "#FDD1DA",
                          borderRadius: "6px",
                          padding: "4px 8px",
                          width: "fit-content",
                        }}
                      >
                        {!item.active ? (
                          <MDTypography
                            sx={({ breakpoints }) => ({
                              ...titleTypography(breakpoints),
                              color: "#F80031",
                              fontSize: 12,
                            })}
                          >
                            DISABLE
                          </MDTypography>
                        ) : (
                          <MDTypography
                            sx={({ breakpoints }) => ({
                              ...titleTypography(breakpoints),
                              color: "#1AAE6F",
                              fontSize: 12,
                            })}
                          >
                            ENABLE
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox
                      component="td"
                      sx={({ breakpoints }) => ({
                        display: "flex",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 16,
                        [breakpoints.down(1367)]: { fontSize: 14 },
                      })}
                    >
                      <EditNote
                        fontSize="inherit"
                        sx={({ breakpoints }) => ({
                          color: "#8392AB",
                          margin: 2,
                          marginLeft: 0,
                          cursor: "pointer",
                          [breakpoints.down(1367)]: { margin: 1 },
                        })}
                        onClick={(e) => {
                          e.stopPropagation();
                          setBannerToChangeStatus(item);
                          setOpenChangeStatus(true);
                        }}
                      />
                      {/*<RemoveRedEye*/}
                      {/*  fontSize="inherit"*/}
                      {/*  sx={({ breakpoints }) => ({*/}
                      {/*    color: "#8392AB",*/}
                      {/*    margin: 2,*/}
                      {/*    [breakpoints.down(1600)]: { margin: 1 },*/}
                      {/*  })}*/}
                      {/*/>*/}
                      <Delete
                        fontSize="inherit"
                        sx={({ breakpoints }) => ({
                          color: "#8392AB",
                          margin: 2,
                          fontSize: "10px",
                          cursor: "pointer",
                          [breakpoints.down(1600)]: { margin: 1 },
                        })}
                        onClick={(e) => {
                          e.stopPropagation();
                          setBannerIdToDelete(item.id);
                          setOpenDeleteModal(true);
                        }}
                      />
                    </MDBox>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </MDBox>
          <MDBox
            sx={({ breakpoints }) => ({
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "16px 0px",

              [breakpoints.down(1367)]: {
                padding: "12px 0px",
              },
            })}
          >
            <MDBox
              sx={({}) => ({
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "16px 0px",
                gap: 1,
              })}
            >
              <MDBox
                sx={(theme) =>
                  pagingItemStyle(theme, {
                    active: false,
                    isArrow: true,
                    disable: page === 0,
                  })
                }
                onClick={() => {
                  if (!(page === 0)) {
                    setPage((page) => page - 1);
                  }
                }}
              >
                <ChevronLeft sx={() => ({ color: "#8392AB" })} />
              </MDBox>

              {filteredPages.map((item, index) => (
                <MDBox
                  key={index}
                  sx={(theme) =>
                    pagingItemStyle(theme, { active: item === page + 1, isArrow: false })
                  }
                  onClick={() => setPage(item - 1)}
                >
                  {item}
                </MDBox>
              ))}
              <MDBox
                sx={(theme) =>
                  pagingItemStyle(theme, {
                    active: false,
                    isArrow: true,
                    disable: page === totalPage - 1 || page === totalPage,
                  })
                }
                onClick={() => {
                  if (!(page === totalPage - 1 || page === totalPage)) {
                    setPage((page) => page + 1);
                  }
                }}
              >
                <ChevronRight sx={() => ({ color: "#8392AB" })} />
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      )}

      <CreateBanner
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        template={null}
        refetch={fetchBanners}
      />
      <DeleteBanner
        open={openDeleteModal}
        handleOpen={() => setOpenDeleteModal(true)}
        handleClose={() => setOpenDeleteModal(false)}
        id={bannerIdToDelete}
        refetch={fetchBanners}
      />
      <UploadImage
        open={openUpload}
        handleOpen={handleOpenUpload}
        handleClose={handleCloseUpload}
        refetch={fetchBanners}
        bannerId={bannerToUpload?.id}
        imageUrl={bannerToUpload?.imgUrl}
      />
      <ChangeStatus
        open={openChangeStatus}
        handleOpen={handleOpenChangeStatus}
        handleClose={() => {
          handleCloseChangeStatus();
          setBannerToChangeStatus(null);
        }}
        id={bannerToChangeStatus?.id}
        active={bannerToChangeStatus?.active}
        refetch={fetchBanners}
      />
      <EditLink
        open={openEditLink}
        handleOpen={handleOpenEditLink}
        handleClose={() => {
          handleCloseEditLink();
          setBannerToEditLink(null);
        }}
        bannerId={bannerToEditLink?.id}
        linkUrl={bannerToEditLink?.linkUrl}
        refetch={fetchBanners}
      />
    </MDBox>
  );
}

export { BannerManagement };
