import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";

const baseApi = {
  data: null,
  isLoading: false,
  error: null,
};

const initialState = {
  callPutUnLockPin: baseApi,
};

const sliceAdmin = createSlice({
  name: "admin",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // putUpdatePin API handlers
    builder
      .addMatcher(api.endpoints.putUnLockPin.matchPending, (state) => {
        state.callPutUnLockPin.isLoading = true;
        state.callPutUnLockPin.error = null;
      })
      .addMatcher(api.endpoints.putUnLockPin.matchFulfilled, (state, action) => {
        state.callPutUnLockPin.isLoading = false;
        state.callPutUnLockPin.data = action.payload;
      })
      .addMatcher(api.endpoints.putUnLockPin.matchRejected, (state, action) => {
        state.callPutUnLockPin.isLoading = false;
        state.callPutUnLockPin.error = action.payload;
      });
  },
});

export default sliceAdmin.reducer;
