import { useEffect, useMemo, useState } from "react";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import AssistantMail from "components/icons/AssistantMail";
import AssistantBox from "components/icons/AssistantBox";
import AssistantGear from "components/icons/AssistantGear";
import { MyTasks } from "./components/Table/Tasks";
import { Students } from "./components/Table/Students/temp";
import { Classes } from "./components/Table/Classes/temp";
import { EPP } from "./components/Table/EPP/index";
import PropTypes from "prop-types";
import { selectAuth } from "shared/redux/slices/authSlice";
import { useSelector } from "react-redux";
import { titleTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import { useParams, useNavigate } from "react-router-dom";

const tabs = [
  {
    icon: () => <AssistantBox />,
    title: "My tasks",
    link: "my-tasks",
  },
  {
    icon: () => <AssistantMail />,
    title: "My classes",
    link: "my-classes",
  },
  {
    icon: () => <AssistantMail />,
    title: "My students",
    link: "my-students",
  },
  {
    icon: () => <AssistantGear />,
    title: "My EPP",
    link: "my-epp",
  },
];

function Assistant() {
  const navigate = useNavigate();
  const { tab } = useParams();

  const tabIndex = useMemo(() => {
    return tab ? parseInt(tabs.findIndex((_tab) => _tab.link === tab)) : 0;
  }, [tab]);

  useEffect(() => {
    if (!tabIndex) {
      // navigate("/assistant/my-tasks");
    }
  }, [tabIndex]);

  const auth = useSelector(selectAuth);

  const renderContent = useMemo(() => {
    const title = tabs[tabIndex]?.title;
    switch (title) {
      case "My classes": {
        return <Classes />;
      }
      case "My students": {
        return <Students />;
      }
      case "My tasks": {
        return <MyTasks />;
      }
      case "My EPP": {
        return <EPP />;
      }
      default: {
        return null;
      }
    }
  }, [tabIndex]);

  return (
    <DashboardLayout>
      <Header />
      <MDBox
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          position: "relative",
          top: pxToRem(-20),
          margin: "0 auto",

          [breakpoints.up("xxl")]: {
            width: `calc(100% - 150px)`,
          },

          [breakpoints.up("xl")]: {
            width: `calc(100% - 120px)`,
          },

          [breakpoints.up("lg")]: {
            width: `calc(100% - 100px)`,
          },

          [breakpoints.up("md")]: {
            width: `calc(100% - 80px)`,
          },

          [breakpoints.down("md")]: {
            width: `calc(100% - 40px)`,
          },
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={3}
          sx={({ breakpoints, functions: { pxToRem } }) => ({
            borderRadius: pxToRem(16),
            background: "#fff",
            padding: `${pxToRem(24)}`,
            marginBottom: pxToRem(24),

            [breakpoints.down(1600)]: {
              rowGap: 2.5,
              borderRadius: pxToRem(8),
            },

            [breakpoints.down(1366)]: {
              flexDirection: "column",
            },

            [breakpoints.down("md")]: {
              rowGap: 2,
            },
          })}
        >
          <MDBox display="flex" alignItems="center" gap={3}>
            <MDBox
              component="img"
              src={auth?.user?.avatar}
              sx={{ width: "74px", height: "74px" }}
            ></MDBox>
            <MDBox>
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...titleTypography(breakpoints),
                  lineHeight: 1.37,
                  letterSpacing: "-0.8px",
                  marginBottom: "4px",
                })}
              >
                {auth?.user?.fullName}
              </MDTypography>
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...descriptionTypography(breakpoints),
                  lineHeight: 1.5,
                })}
              >
                {auth?.role}
              </MDTypography>
            </MDBox>
          </MDBox>

          <Grid
            container
            alignItems="center"
            sx={({ functions: { pxToRem } }) => ({
              borderRadius: pxToRem(12),
              padding: pxToRem(4),
              background: "#F6F9FC",
              maxWidth: "550px",
            })}
          >
            {tabs.map((item, index) => {
              return (
                <Grid
                  item
                  xs={6}
                  lg={4}
                  xl={3}
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                  sx={({ functions: { pxToRem } }) => ({
                    height: pxToRem(35),
                    borderRadius: pxToRem(8),
                    background: tabIndex === index ? "white" : "inherite",
                    boxShadow: tabIndex === index ? "0px 2px 6px 0px #00000040" : "unset",
                    cursor: "pointer",
                  })}
                  onClick={() => {
                    navigate(`/assistant/${item.link}`);
                  }}
                >
                  <Icon component={item.icon} />
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...noteTypography(breakpoints),
                      lineHeight: 1.25,
                    })}
                  >
                    {item.title}
                  </MDTypography>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
        {renderContent}
      </MDBox>
    </DashboardLayout>
  );
}

Assistant.propTypes = {
  tab: PropTypes.number,
};

export default Assistant;
