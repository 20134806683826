export const useMutationWithCallbacks = (mutationHook: Function) => {
  return () => {
    const [mutation] = mutationHook();

    return async (
      body: any,
      onPending?: () => void,
      onSuccess?: (data: any) => void,
      onFail?: (error: any) => void
    ) => {
      try {
        if (onPending) {
          onPending?.();
        }

        const response = await mutation(body).unwrap();
        if (onSuccess) {
          onSuccess?.(response);
        }
      } catch (error) {
        if (onFail) {
          onFail?.(error);
        }
      }
    };
  };
};
