import { Button, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";
import moment from "moment";
import ModalIcon from "assets/images/modal-training.png";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
  padding: 3,
  maxWidth: 400,
};

const ModalWorkShop = ({ data, isOpen, onClose }) => {
  console.log(11111);
  const dataPopup = data?.extendedProps.data;
  const { courseId, organizerId } = dataPopup;
  const auth = useSelector(selectAuth);
  const { user } = auth;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <MDBox sx={{ ...style }}>
        <MDBox display="flex" gap={1} alignItems="center" mb={3}>
          <MDBox
            sx={{
              width: 48,
              height: 48,
              background: "#2576B9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <MDBox sx={{ width: 29 }} component="img" src={ModalIcon}></MDBox>
          </MDBox>
          <MDBox>
            <MDTypography
              sx={({ breakpoints }) => ({
                ...titleTypography(breakpoints),
                lineHeight: 1.37,
              })}
            >
              {dataPopup?.eventTypeName}
            </MDTypography>
            <MDTypography
              sx={({ breakpoints }) => ({
                ...noteTypography(breakpoints),
                lineHeight: 1.5,
              })}
            >
              Type: Training/Workshop
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            Date: {moment(dataPopup?.startTime).format("D MMMM YYYY")}
          </MDTypography>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            Time: {moment(dataPopup?.startTime).format("hh:mm")} -{" "}
            {moment(dataPopup?.endTime).format("hh:mm")}
          </MDTypography>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            {dataPopup.locationlink ? (
              <span>
                Link:{" "}
                <a href={dataPopup.locationlink} target="_blank" rel="noreferrer">
                  {dataPopup.location}
                </a>
              </span>
            ) : (
              <span>Location: {dataPopup.location}</span>
            )}
          </MDTypography>

          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
              lineHeight: 1.5,
            })}
          >
            Trainer: {dataPopup?.trainers && dataPopup?.trainers.join(", ")}
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            mt: 3,
            color: "#FFFFFF",
            fontFamily: "Lexend, san-serif",
            textTransform: "unset",
            width: "350px",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
          component="label"
          role={undefined}
          tabIndex={-1}
        >
          <Button
            variant="contained"
            sx={{
              mt: 3,
              color: "#FFFFFF",
              backgroundColor: "#2576B9",
              fontFamily: "Lexend, san-serif",
              textTransform: "unset",
              width: "150px",
              maxWidth: "50%",
            }}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={async () => {
              onClose();
            }}
          >
            OK
          </Button>

          <Button
            variant="contained"
            sx={{
              mt: 3,
              color: "#FFFFFF",
              backgroundColor: "#2576B9",
              fontFamily: "Lexend, san-serif",
              textTransform: "unset",
              width: "50%",
              boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
            }}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={async () => {
              let url = ` https://welearn.apollo.vn/dl-api/workshop?email=${user.email}&course=${courseId}&instance=${organizerId}`;
              window.open(url, "_blank");
              onClose();
            }}
          >
            GO TO WELEARN
          </Button>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalWorkShop;
