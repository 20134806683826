import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "shared/layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/apollo-english/Login.png";
import GoogleImage from "assets/images/apollo-english/google.jpg";
import { useLoginV2Mutation } from "shared/redux/endpoints/auth";
import { selectAuth } from "shared/redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { form, initialValues, validations } from "./form";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import pxToRem from "assets/theme/functions/pxToRem";
import typography from "assets/theme/base/typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import queryString from "query-string";
import {
  containerStyle,
  errorMessageStyle,
  forgotPasswordButtonStyle,
  forgotPasswordLinkStyle,
  googleButtonStyle,
  googleTextStyle,
  headingStyle,
  orTextStyle,
  signInButtonStyle,
  signInTextStyle,
  subHeadingStyle,
} from "./style";
import { IconButton } from "@mui/material";
import { globalConfigs } from "configs";
import ModalAccountLockedCard from "components/ModalAccountLockedCard/ModalAccountLockedCard";
import { clearAllMySelf } from "../../shared/redux/slices/sliceMySelf";

const customStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    height: "100%",
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    paddingLeft: pxToRem(20),
  },

  "& input::placeholder": {
    fontFamily: typography.lexendFont,
  },

  "& label": {
    fontSize: 12,
    lineHeight: "16px",
    fontFamily: typography.lexendFont,
    color: "#A0AEC0",
    left: 6,
  },

  "& legend": {
    marginLeft: 6,
  },
};

function SignIn() {
  const dispatch = useDispatch();
  const { formId, formField } = form;
  const { email, password } = formField;

  const [showPassword, setShowPassword] = useState(false);

  const [isOpenModalAccountLock, setIsOpenModalAccountLock] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [login, { isLoading }] = useLoginV2Mutation();
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLocked } = useSelector((state) => state.mySelf);

  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: async (values) => {
      const base64Token = btoa(`${values.email}:${values.password}`);

      const result = await login({
        token: base64Token,
      });
      if (result.error && result.error.status === 423) {
        setIsOpenModalAccountLock(true);
        return;
      }

      if (result.data) {
        if (result.data?.user?.firstLogin) navigate("/change-password");
        else {
          const { redirectTo } = queryString.parse(location.search);
          navigate(redirectTo == null ? "/" : `/${redirectTo}`);
        }

        const script = document.createElement("script");
        script.src = "https://dl.apollo.vn/static/csat/apollochatv3-o.js";
        script.type = "text/javascript";
        script.id = "apollo-chat-script"; // Gán ID để dễ xóa
        script.async = true;

        document.body.appendChild(script);
      } else {
        toast.error(
          "Oops! Did you mistype? Or you might have changed your password and it's slipped your mind? A quick reset should do the trick!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              fontFamily: "Lexend",
              fontSize: 14,
            },
          }
        );
      }
    },
  });

  const emailError = formik.touched.email && Boolean(formik.errors.email);
  const passwordError = formik.touched.password && Boolean(formik.errors.password);

  useEffect(() => {
    if (isLocked) {
      setIsOpenModalAccountLock(true);
      dispatch(clearAllMySelf());
    }

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        document.querySelector(`#${formId} button[type=submit]`).click();
      }
    };

    document.body.addEventListener("keypress", handleKeyPress);

    return () => {
      document.body.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const [searchParams] = useSearchParams();

  const redirectTo = searchParams.get("redirectTo");

  useEffect(() => {
    const { accessToken, refreshToken } = auth;
    if (!!accessToken && !!refreshToken) {
      if (redirectTo) navigate(`/${redirectTo}`);
      else navigate("/");
    }
  }, [redirectTo]);

  const handleSignWithGoogle = () => {
    window.location.href = `${globalConfigs.publicApiBaseUrl}/oauth2/login`;
  };

  return (
    <CoverLayout image={bgImage}>
      <MDBox
        position="absolute"
        width="calc(100% - 48px)"
        left={24}
        zIndex={99}
        sx={(theme) => containerStyle(theme)}
      >
        <MDTypography textAlign="center" sx={(theme) => headingStyle(theme)}>
          Welcome to My Apollo
        </MDTypography>
        <MDTypography textAlign="center" color="white" sx={(theme) => subHeadingStyle(theme)}>
          It&apos;s like your favorite digital playground, but way cooler.
        </MDTypography>
      </MDBox>
      <Card>
        <MDBox p={{ xs: 1.5, xl: 2, xxl: 3, xxxl: 4 }}>
          <MDTypography textAlign="center" sx={(theme) => signInTextStyle(theme)}>
            Sign in with
          </MDTypography>
          <MDBox px={{ xs: 2, xxxl: 3 }} mb={{ xs: 2, xxxl: 3 }} sx={{ width: "100%" }}>
            <MDButton
              variant="outlined"
              display="flex"
              sx={(theme) => googleButtonStyle(theme)}
              onClick={handleSignWithGoogle}
            >
              <MDBox component="img" src={GoogleImage} width="16px" />
              <MDTypography sx={(theme) => googleTextStyle(theme)}>Google</MDTypography>
            </MDButton>
          </MDBox>
          <MDTypography textAlign="center" sx={(theme) => orTextStyle(theme)}>
            Or sign in with credentials
          </MDTypography>

          <MDBox component="form" id={formId} onSubmit={formik.handleSubmit}>
            <MDBox mb={{ xs: 2, lg: 3 }}>
              <MDInput
                id="email"
                type={email.type}
                label={email.label}
                name={email.name}
                variant="outlined"
                fullWidth
                placeholder="john@example.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={emailError}
                InputLabelProps={{ shrink: true }}
                sx={({ typography, functions: { pxToRem } }) => ({
                  height: pxToRem(50),
                  fontFamily: typography.lexendFont,
                })}
                customStyle={customStyle}
                autoComplete="off"
              />
              {emailError ? (
                <MDTypography sx={(theme) => errorMessageStyle(theme)}>
                  {formik.errors.email}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={{ xs: 2, lg: 3 }}>
              <MDInput
                type={showPassword ? "text" : password.type}
                label={password.label}
                name={password.name}
                variant="outlined"
                fullWidth
                placeholder="************"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={passwordError}
                sx={({ functions: { pxToRem } }) => ({
                  height: pxToRem(50),
                })}
                isPassword
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                customStyle={customStyle}
              />
              {passwordError ? (
                <MDTypography sx={(theme) => errorMessageStyle(theme)}>
                  {formik.errors.password}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mt={{ xs: 2, lg: 3 }} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                sx={(theme) => signInButtonStyle(theme)}
              >
                Sign in
              </MDButton>
            </MDBox>
            <MDBox mt={{ xs: 2, xxxl: 3 }} textAlign="center">
              <MDTypography variant="button" sx={(theme) => forgotPasswordButtonStyle(theme)}>
                Password&apos;s gone incognito?{" "}
                <MDTypography
                  component={Link}
                  to="/reset-password-validate"
                  variant="button"
                  fontWeight="medium"
                  sx={(theme) => forgotPasswordLinkStyle(theme)}
                >
                  Reset it
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      {/* Modal for Locked Account */}
      <ModalAccountLockedCard
        isOpenModalAccountLock={isOpenModalAccountLock}
        onClose={() => setIsOpenModalAccountLock(false)}
      />
    </CoverLayout>
  );
}

export default SignIn;
