import { api } from "shared/redux/api";
import { useMutationWithCallbacks } from "../../hooks/useMutationWithCallbacks";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTeachers: build.query({
      query: (arg) => {
        const { page, size, sort, search } = arg;
        if (search) {
          return {
            url: `/private/teacher/v1?page=${page}&size=${size}&sort=${sort}&search=${search}`,
          };
        }
        return {
          url: `/private/teacher/v1?page=${page}&size=${size}&sort=${sort}`,
        };
      },
    }),
    createTemplate: build.mutation({
      query: (arg) => {
        return {
          url: `/private/notification/v1/template`,
          method: "POST",
          body: arg,
        };
      },
    }),
    updateTemplate: build.mutation({
      query: (arg) => {
        return {
          url: `/private/notification/v1/template`,
          method: "POST",
          body: arg,
        };
      },
    }),
    getTemplates: build.query({
      query: (arg) => {
        const { key, page, size, sort } = arg;
        return {
          url: `/private/notification/v1/template?key=${key}&page=${page}&size=${size}&sort=${sort}`,
        };
      },
    }),
    deleteTemplate: build.mutation({
      query: (arg) => {
        const { templateId } = arg;
        return {
          url: `/private/notification/v1/template/${templateId}`,
          method: "DELETE",
        };
      },
    }),
    getAdmins: build.query({
      query: (arg) => {
        const { page, size, sort } = arg;
        return {
          url: `/private/admin/v1?page=${page}&size=${size}&sort=${sort}`,
        };
      },
    }),
    putUnLockPin: build.mutation({
      query: (arg) => {
        const { email } = arg;
        return {
          url: `/private/admin/v1/unlock-pin/${email}`,
          method: "PUT",
          body: arg,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTeachersQuery,
  useLazyGetTeachersQuery,
  useCreateTemplateMutation,
  useLazyGetTemplatesQuery,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
  useLazyGetAdminsQuery,
  usePutUnLockPinMutation,
} = injectedRtkApi;

export const useAdminAPIs = {
  usePutUnLockPinWithCallbacks: useMutationWithCallbacks(usePutUnLockPinMutation),
};
