import PropTypes from "prop-types";

export const IconLinkMgr = ({ width = 18, height = 16, fill = "#2376BC" }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.8558 4.2558L27.7438 2.1438C25.9518 0.351805 23.0078 0.351805 21.2158 2.1438L15.4558 7.9038C13.6638 9.6958 13.6638 12.5758 15.4238 14.3998L14.3998 15.4238C12.6078 13.6638 9.6958 13.6638 7.90381 15.4558L2.14381 21.2158C1.2798 22.1118 0.799805 23.2638 0.799805 24.4798C0.799805 25.6958 1.2798 26.8798 2.1438 27.7438L4.2558 29.8558C5.1198 30.7198 6.2718 31.1998 7.5198 31.1998C8.76781 31.1998 9.91981 30.7198 10.7838 29.8558L16.5438 24.0958C18.3358 22.3038 18.3358 19.4238 16.5758 17.5998L17.5998 16.5758C18.4638 17.4078 19.6158 17.8878 20.8318 17.8878C22.0798 17.8878 23.2318 17.4078 24.0958 16.5438L29.8558 10.7838C30.7198 9.9198 31.1998 8.7678 31.1998 7.5198C31.1998 6.2718 30.7198 5.1198 29.8558 4.2558ZM14.3678 21.9198L8.60781 27.6798C8.03181 28.2558 7.00781 28.2558 6.43181 27.6798L4.31981 25.5678C3.71181 24.9598 3.71181 23.9998 4.31981 23.3918L10.0798 17.6318C10.3678 17.3438 10.7838 17.1838 11.1678 17.1838C11.5518 17.1838 11.9358 17.3118 12.2238 17.5998L10.9118 18.9118C10.3038 19.5198 10.3038 20.4798 10.9118 21.0878C11.1998 21.3758 11.6158 21.5358 11.9998 21.5358C12.3838 21.5358 12.7998 21.3758 13.0878 21.0878L14.3998 19.7758C14.9438 20.3838 14.9438 21.3438 14.3678 21.9198ZM27.6798 8.6078L21.9198 14.3678C21.3438 14.9438 20.3518 14.9438 19.7758 14.3998L21.0878 13.0878C21.6958 12.4798 21.6958 11.5198 21.0878 10.9118C20.4798 10.3038 19.5198 10.3038 18.9118 10.9118L17.5998 12.2238C17.0238 11.6158 17.0558 10.6878 17.6318 10.0798L23.3918 4.3198C23.6798 4.0318 24.0958 3.8718 24.4798 3.8718C24.8638 3.8718 25.2798 4.0318 25.5678 4.3198L27.6798 6.4318C28.2878 7.0078 28.2878 7.9998 27.6798 8.6078Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

IconLinkMgr.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
