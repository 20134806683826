import PropTypes from "prop-types";

import { RocketIcon } from "./packages/RocketIcon";
import { BooksIcon } from "./packages/BooksIcon";
import { AtomIcon } from "./packages/Atom";
import { VisaIcon } from "./packages/Visa";
import { CartIcon } from "./packages/CartIcon";
import { MarkIcon } from "./packages/MarkIcon";
import { BellIcon } from "./packages/Bell";
import { HomeIcon } from "./packages/Home";
import { TrendingUpIcon } from "./packages/TrendingUp";
import { UploadIcon } from "./packages/UploadIcon";
import { ArrowRightIcon } from "./packages/ArrowRightIcon";
import { ArrowLeftIcon } from "./packages/ArrowLeftIcon";
import { ArrowUpIcon } from "./packages/ArrowUpIcon";
import { FileIcon } from "./packages/FileIcon";
import { SunnyIcon } from "./packages/SunnyIcon";
import { ChevonRight } from "./packages/ChevonRight";
import { IconInformation } from "./packages/IconInformation";
import { IconLocation } from "./packages/Location";
import { IconLinkMgr } from "./packages/IconLinkMgr";
import { IconOnlineStatus } from "./packages/IconOnlineStatus";

export const SVGIcon = ({ name, width, height, fill, stroke, opacity }) => {
  switch (name) {
    case "atom":
      return <AtomIcon width={width} height={height} fill={fill} />;
    case "books":
      return <BooksIcon width={width} height={height} fill={fill} />;
    case "rocket":
      return <RocketIcon width={width} height={height} fill={fill} />;
    case "visa":
      return <VisaIcon width={width} height={height} fill={fill} />;
    case "cart":
      return <CartIcon width={width} height={height} fill={fill} />;
    case "mark":
      return <MarkIcon width={width} height={height} fill={fill} />;
    case "bell-outline":
      return <BellIcon width={width} height={height} stroke={stroke} />;
    case "home-outline":
      return <HomeIcon width={width} height={height} stroke={stroke} />;
    case "trending-up":
      return <TrendingUpIcon width={width} height={height} stroke={stroke} />;
    case "upload":
      return <UploadIcon width={width} height={height} fill={fill} />;
    case "arrow-left":
      return <ArrowLeftIcon width={width} height={height} fill={fill} />;
    case "arrow-right":
      return <ArrowRightIcon width={width} height={height} fill={fill} />;
    case "arrow-up":
      return <ArrowUpIcon width={width} height={height} fill={fill} />;
    case "file":
      return <FileIcon width={width} height={height} fill={fill} />;
    case "sunny":
      return <SunnyIcon width={width} height={height} fill={fill} />;
    case "chevon-right":
      return <ChevonRight width={width} height={height} fill={fill} />;
    case "information":
      return <IconInformation width={width} height={height} fill={fill} />;
    case "location":
      return <IconLocation width={width} height={height} fill={fill} />;
    case "online_status":
      return <IconOnlineStatus width={width} height={height} fill={fill} />;
    case "links-manager":
      return <IconLinkMgr width={width} height={height} fill={fill} />;
    default:
      return null;
  }
};

SVGIcon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  opacity: PropTypes.number,
};
