import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { AdminHeader } from "shared/components/common/AdminHeader";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import { CardContent, CardHeader, CircularProgress } from "@mui/material";
import ButtonAdminAddNew from "components/Button/ButtonAdminAddNew";
import UIDataTable from "components/UIDataTable/UIDataTable";
import type { IUIDataTableProps } from "components/UIDataTable/UIDataTable";
import { TableUtil } from "utilities/tableUtil";
import { useSelector } from "react-redux";
import type { Error400, Success200 } from "utilities/toastUtil";
import { ToastUtil } from "utilities/toastUtil";
import { useAdminLinkAPIs } from "shared/redux/endpoints/admin/endPointAdminMgrLink";
import { Loading } from "shared/components/ui/Loading";
import DialogCreateOrUpdateLink from "components/DialogCreateOrUpdateLink/DialogCreateOrUpdateLink";
import type { IModalCreateOrUpdateLinkProps } from "components/DialogCreateOrUpdateLink/DialogCreateOrUpdateLink";
import Tooltip from "@mui/material/Tooltip";
import { Delete, EditNote } from "@mui/icons-material";

export const MgrLinkContainer = () => {
  const [openModalCreateOrUpdate, setOpenModalCreateOrUpdate] = useState(false);
  const formDataAddNew = {
    id: null,
    url: "",
    title: "",
    description: "",
  };
  const [formData, setFormData] = useState(formDataAddNew);

  const [isLoadingTab, setIsLoadingTab] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [loadingDeleteIds, setLoadingDeleteIds] = useState([]);

  const columns = useMemo(() => [
    {
      id: "id",
      label: "No",
      minWidth: 80,
      styleHeader: {
        width: 80,
      },
      styleCell: { ...TableUtil.styleCell, whiteSpace: "nowrap" },
      sortable: true,
      align: "left",
    },
    {
      id: "title",
      label: "Title",
      minWidth: 200,

      styleCell: { ...TableUtil.styleCell },
      sortable: true,
      align: "left",
    },
    {
      id: "description",
      label: "Description",
      minWidth: 230,
      styleCell: { ...TableUtil.styleCell, fontWeight: 400 },
    },
    {
      id: "url",
      label: "Link",
      minWidth: 250,
      styleCell: { ...TableUtil.styleCell, fontWeight: 400 },
      format: (value: string) => {
        return (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      minWidth: 20,
      format: (value: string, item: any) => {
        return (
          <>
            <Tooltip title={`Update`} enterDelay={2000}>
              <EditNote
                fontSize="small"
                style={{ cursor: "pointer", marginRight: 10 }}
                onClick={handleOpenEditLink.bind(this, item)}
              />
            </Tooltip>
            <Tooltip title={`Delete`} enterDelay={2000}>
              {loadingDeleteIds.includes(item.id) ? (
                <CircularProgress size={12} />
              ) : (
                <Delete
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={handleDeleteLink.bind(this, item)}
                />
              )}
            </Tooltip>
          </>
        );
      },
    },
  ]);
  const { data: rows, isLoading: isLoading } = useSelector(
    (state) => state.adminMgrLink.callGetPageLink
  );

  const getPageLink = useAdminLinkAPIs.useGetPageLinkWithCallbacks();
  const postCreateLink = useAdminLinkAPIs.usePostCreateLinkWithCallbacks();
  const putUpdateLink = useAdminLinkAPIs.usePutUpdateLinkWithCallbacks();
  const deleteLink = useAdminLinkAPIs.useDeleteLinkWithCallbacks();

  const callGetPageLink = useCallback(
    async (param?: {}) => {
      await getPageLink(
        param,
        undefined,
        (response: Success200) => {
          let data = response.data;
          // Update UI
          setCurrentPage(data.currentPage);
          setPageSize(data.pageSize);
          setTotalPage(data.totalPage);
          setTotalElement(data.totalElement);
        },
        (error: Error400) => {
          ToastUtil.showToastError400(error);
        }
      );
    },
    [getPageLink]
  );

  const onChangePage = async (event: React.ChangeEvent<>, value: number) => {
    const newPage = value - 1;

    // Update the current page in the UI
    setCurrentPage(newPage);

    // Fetch the updated page data
    await callGetPageLink({
      page: newPage,
      size: pageSize,
      search,
      sort: `${orderBy},${order}`,
    });
  };

  const onChangePageSize = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(event.target.value);
    let newPage;

    setPageSize(newSize);

    // Adjust the current page if the new page size makes the current page invalid
    if (newSize * currentPage >= totalElement) {
      newPage = Math.floor(totalElement / newSize);
    } else {
      newPage = currentPage; // Keep the current page if it's still valid
    }

    // Fetch the updated page data
    await callGetPageLink({
      page: newPage,
      size: newSize,
      search,
      sort: `${orderBy},${order}`,
    });
  };

  const onSearch = async (value: string) => {
    setSearch(value);
    await callGetPageLink({
      page: currentPage,
      size: pageSize,
      search: value,
      sort: `${orderBy},${order}`,
    });
  };

  const onSort = async (event: React.MouseEvent<>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    await callGetPageLink({
      page: currentPage,
      size: pageSize,
      search,
      sort: `${property},${newOrder}`,
    });
  };

  const handleOpenEditLink = (data) => {
    setFormData({
      id: data.id,
      url: data.url,
      title: data.title,
      description: data.description,
    });
    setOpenModalCreateOrUpdate(true);
  };

  const handleDeleteLink = async (data) => {
    setLoadingDeleteIds((prev) => [...prev, data.id]);
    await deleteLink(
      { id: data.id },
      undefined,
      async (response: Success200) => {
        // update row table
        // const updatedRows = rows.filter((row) => row.id !== data.id);
        // dispatch(updateData(updatedRows));
        await refetch();
        ToastUtil.showToastSuccess200(response);
      },
      (error: Error400) => {
        ToastUtil.showToastError400(error);
      }
    );
  };

  const refetch = async () => {
    await callGetPageLink({
      page: currentPage,
      size: pageSize,
      search,
      sort: `${orderBy},${order}`,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData().then(setIsLoadingTab(false));
  }, []);

  const handleAddNew = () => {
    setFormData(formDataAddNew);
    setOpenModalCreateOrUpdate(true);
  };

  const propertiesUIDataTable: IUIDataTableProps = {
    className: "table-mgr-link-container",
    isLoading,
    columns,
    rows,
    currentPage,
    pageSize,
    search,
    order,
    orderBy,
    totalPage,
    totalElement,
    onChangePage,
    onChangePageSize,
    onSearch,
    onSort,
  };

  const propertiesModalCreateOrUpdateLink: IModalCreateOrUpdateLinkProps = {
    dataUpdate: formData,
    open: openModalCreateOrUpdate,
    onClose: () => setOpenModalCreateOrUpdate(!openModalCreateOrUpdate),
    onCreate: async (data: any, cbClose: any) => {
      await postCreateLink(
        data,
        undefined,
        async (response: Success200) => {
          await refetch();
          ToastUtil.showToastSuccess200(response);
          if (cbClose) {
            cbClose();
          }
        },
        (error: Error400) => {
          ToastUtil.showToastError400(error);
        }
      );
    },
    onEdit: async (data: any, cbClose: any) => {
      const param = { id: data.id, body: data };
      await putUpdateLink(
        param,
        undefined,
        async (response: Success200) => {
          await refetch();
          ToastUtil.showToastSuccess200(response);
          if (cbClose) {
            cbClose();
          }
        },
        (error: Error400) => {
          ToastUtil.showToastError400(error);
        }
      );
    },
  };

  return (
    <DashboardLayout>
      <AdminHeader />
      <DashboardNavbar light />
      <MDBox className="admin-mgr-link-container">
        <Card>
          {isLoadingTab && <Loading style={{ width: 250, height: 200, margin: "150px auto" }} />}
          {!isLoadingTab && (
            <>
              <CardHeader
                className="card-header"
                title="Useful Links Management"
                subheader="Manage and update links for staff to access updated files easily."
                action={<ButtonAdminAddNew onClick={handleAddNew} />}
              />
              <CardContent>
                <UIDataTable {...propertiesUIDataTable} />
              </CardContent>
            </>
          )}
          <DialogCreateOrUpdateLink {...propertiesModalCreateOrUpdateLink} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
