import { api } from "shared/redux/api";
import { useLazyQueryWithCallbacks } from "../../../hooks/useLazyQueryWithCallbacks";
import { useMutationWithCallbacks } from "../../../hooks/useMutationWithCallbacks";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPageLink: build.query({
      query: ({ page, size, sort, search }) => {
        const params = new URLSearchParams();

        // Add parameters conditionally only if they are defined
        if (page) params.append("page", page);
        if (size) params.append("size", size);
        if (sort) params.append("sort", sort);
        if (search) params.append("search", search);

        return {
          url: `/private/v1/admin/link/list?${params.toString()}`,
        };
      },
    }),
    postCreateLink: build.mutation({
      query: (body) => ({
        url: "/private/v1/admin/link",
        method: "POST",
        body,
      }),
    }),
    putUpdateLink: build.mutation({
      query: ({ id, body }) => ({
        url: `/private/v1/admin/link/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteLink: build.mutation({
      query: ({ id }) => ({
        url: `/private/v1/admin/link/${id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetPageLinkQuery,
  usePostCreateLinkMutation,
  usePutUpdateLinkMutation,
  useDeleteLinkMutation,
} = injectedRtkApi;

export const useAdminLinkAPIs = {
  useGetPageLinkWithCallbacks: useLazyQueryWithCallbacks(useLazyGetPageLinkQuery),
  usePostCreateLinkWithCallbacks: useMutationWithCallbacks(usePostCreateLinkMutation),
  usePutUpdateLinkWithCallbacks: useMutationWithCallbacks(usePutUpdateLinkMutation),
  useDeleteLinkWithCallbacks: useMutationWithCallbacks(useDeleteLinkMutation),
};
