import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";
import { WorkshopUtil } from "utilities/workshopUtil";

const initialState = {
  callGetCountLegend: {
    data: null,
    isLoading: false,
    error: null,
    response: null,
  },
  callGetCalendarClassSession: {
    data: null,
    isLoading: false,
    error: null,
    response: null,
  },
  callGetCalendarWorkshop: {
    response: null,
    data: null,
    isLoading: false,
    error: null,
    dataWorkShop: [],
  },
};

const sliceMyCalendar = createSlice({
  name: "myCalendar",
  initialState,
  reducers: {
    resetCallGetCalendarWorkshop: (state) => {
      state.callGetCalendarWorkshop = {
        response: null,
        data: null,
        isLoading: false,
        error: null,
        dataWorkShop: [],
      };
    },
  },
  extraReducers: (builder) => {
    // getCountLegend API
    builder
      .addMatcher(api.endpoints.getCountLegend.matchPending, (state) => {
        state.callGetCountLegend.isLoading = true;
        state.callGetCountLegend.error = null;
      })
      .addMatcher(api.endpoints.getCountLegend.matchFulfilled, (state, action) => {
        state.callGetCountLegend.isLoading = false;
        state.callGetCountLegend.data = action?.payload?.data;
      })
      .addMatcher(api.endpoints.getCountLegend.matchRejected, (state, action) => {
        state.callGetCountLegend.isLoading = false;
        state.callGetCountLegend.error = action?.payload;
      });

    // getClassSession API
    builder
      .addMatcher(api.endpoints.getClassSession.matchPending, (state) => {
        state.callGetCalendarClassSession.isLoading = true;
        state.callGetCalendarClassSession.error = null;
      })
      .addMatcher(api.endpoints.getClassSession.matchFulfilled, (state, action) => {
        state.callGetCalendarClassSession.isLoading = false;
        state.callGetCalendarClassSession.data = action?.payload?.data;
      })
      .addMatcher(api.endpoints.getClassSession.matchRejected, (state, action) => {
        state.callGetCalendarClassSession.isLoading = false;
        state.callGetCalendarClassSession.error = action?.payload;
      });

    // getDataOfWorkshopTab API handlers
    builder
      .addMatcher(api.endpoints.getDataOfWorkshopTab.matchPending, (state) => {
        state.callGetCalendarWorkshop.isLoading = true;
        state.callGetCalendarWorkshop.error = null;
      })
      .addMatcher(api.endpoints.getDataOfWorkshopTab.matchFulfilled, (state, action) => {
        state.callGetCalendarWorkshop.isLoading = false;
        state.callGetCalendarWorkshop.response = action?.payload;

        state.callGetCalendarWorkshop.dataWorkShop = WorkshopUtil.getDataWorkShopForCalendar(
          state.callGetCalendarWorkshop.response
        );
      })
      .addMatcher(api.endpoints.getDataOfWorkshopTab.matchRejected, (state, action) => {
        state.callGetCalendarWorkshop.isLoading = false;
        state.callGetCalendarWorkshop.error = action?.payload;
      });
  },
});

export const { resetCallGetCalendarWorkshop } = sliceMyCalendar.actions;
export default sliceMyCalendar.reducer;
