const styleCell = {
  fontFamily: "Open Sans",
  fontSize: 16,
  fontWeight: 600,
  color: "#344767",
};

export const TableUtil = {
  styleCell,
};
