import PropTypes from "prop-types";

export const IconOnlineStatus = ({ width, height, fill }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 11 11"
        fill="none"
      >
        {fill && <circle cx="5.5" cy="5.5" r="5.5" fill={fill} />}
        {!fill && <circle cx="5.5" cy="5.5" r="5.5" fill="#2DCE89" />}
      </svg>
    </>
  );
};

IconOnlineStatus.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
