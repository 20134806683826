// Default toast options
import { toast } from "react-toastify";

const defaultOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  style: {
    fontFamily: "Lexend",
    fontSize: 14,
  },
};

// Structure of error 400 form server
export interface Error400 {
  status: number;
  data: {
    data: {
      code: string,
      message: string,
    },
    status: number,
  };
}

export interface Success200 {
  data: {};
  message: string;
  status: boolean;
}

export const showToastError = (message, options = {}) => {
  toast.error(message, { ...defaultOptions, ...options });
};

export const showToastError400 = (error: Error400, options = {}) => {
  const code = error.data?.data?.code;
  let message = error?.data?.data?.message;
  if (code === "CLIENT_EX002") {
    message = error?.data?.data?.message.split(",")[0];
  }

  toast.error(message, { ...defaultOptions, ...options });
};

export const showToastSuccess200 = (success: Success200, options = {}) => {
  let message = success.message;
  toast.success(message, { ...defaultOptions, ...options });
};

export const showToastSuccess = (message, options = {}) => {
  toast.success(message, { ...defaultOptions, ...options });
};

export const showToastInfo = (message, options = {}) => {
  toast.info(message, { ...defaultOptions, ...options });
};

export const showToastWarning = (message, options = {}) => {
  toast.warn(message, { ...defaultOptions, ...options });
};

export const ToastUtil = {
  showToastError,
  showToastSuccess,
  showToastInfo,
  showToastWarning,
  showToastError400,
  showToastSuccess200,
};
