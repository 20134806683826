import { combineReducers } from "@reduxjs/toolkit";
import { api } from "./api";

import authReducer from "./slices/authSlice";
import themeReducer from "./slices/themeSlice";
import notificationReducer from "./slices/notificationSlice";
import uploadReducer from "./slices/uploadSlice";
import loadingSlice from "./slices/loadingSlice";
import bannerSlice from "./slices/bannerSlice";
import sliceMySelf from "./slices/sliceMySelf";
import sliceMyAssistant from "./slices/sliceMyAssistant";
import sliceMyDevelopment from "./slices/sliceMyDevelopment";
import sliceMyCalendar from "./slices/sliceMyCalendar";

import sliceAdmin from "./slices/sliceAdmin";
import sliceAdminMgrLink from "./slices/admin/sliceAdminMgrLink";
import sliceHomePage from "./slices/sliceHomePage";

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,

  auth: authReducer,
  theme: themeReducer,
  notification: notificationReducer,
  upload: uploadReducer,
  loading: loadingSlice,
  banner: bannerSlice,

  homePage: sliceHomePage,
  myCalendar: sliceMyCalendar,
  myAssistant: sliceMyAssistant,
  myDevelopment: sliceMyDevelopment,
  mySelf: sliceMySelf,

  admin: sliceAdmin,
  adminMgrLink: sliceAdminMgrLink,
});
