import { Button, Modal } from "@mui/material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { TypeClassSession } from "constant/TypeClassSession";

export interface IModalMyCalendarProps {
  isOpen: boolean;
  setOpen: any;
  icon: any;
  dataExtendedProps: any;
  onClose: any;
  dispatch: any;
}

export const ModalMyCalendarStudentAtRisk = (props: IModalMyCalendarProps) => {
  const { isOpen, setOpen, icon, dataExtendedProps, onClose, dispatch } = props;
  const navigate = useNavigate();
  const type = dataExtendedProps?.type;
  const item = dataExtendedProps?.data;
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="modal-my-calendar-container">
        <MDBox className="modal-my-calendar-header">
          <MDBox className="modal-my-calendar-icon">
            <MDBox sx={{ width: 29 }} component="img" src={icon}></MDBox>
          </MDBox>
          <MDBox>
            <MDTypography className="modal-my-calendar-title">Details</MDTypography>
            <MDTypography className="modal-my-calendar-type">
              Type: {dataExtendedProps?.type}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox className="modal-my-calendar-content">
          {dataExtendedProps?.type === TypeClassSession.STUDENTS_AT_RISK && (
            <>
              <MDTypography className="modal-text">
                Class name: {dataExtendedProps?.studentAtRiskClassName}
              </MDTypography>
              <MDTypography className="modal-text">Number of students: {item?.length}</MDTypography>
              <MDTypography className="modal-text">
                Start time: {moment(item?.[0]?.startTime).format("DD-MM-YYYY HH:mm")}
              </MDTypography>
              <MDTypography className="modal-text">
                End time: {moment(item?.[0]?.endTime).format("DD-MM-YYYY HH:mm")}
              </MDTypography>
            </>
          )}
        </MDBox>

        <MDBox className="modal-my-calendar-footer">
          <Button
            className="modal-my-calendar-button"
            variant="contained"
            role={undefined}
            tabIndex={-1}
            onClick={() => {
              const id = item?.[0]?.id;
              let startTime = item?.[0]?.startTime
                ? moment(item?.[0]?.startTime).add(-7, "h").format("MM-DD-YYYY")
                : "";
              let endTime = item?.[0]?.endTime
                ? moment(item?.[0]?.endTime).add(-7, "h").format("MM-DD-YYYY")
                : "";
              navigate(
                `/assistant/my-tasks?id=${id}&start=${startTime}&end=${endTime}&type=${type}`
              );
            }}
          >
            Go to To-do list
          </Button>
          <Button
            className="modal-my-calendar-button"
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
          >
            OK
          </Button>
        </MDBox>
      </div>
    </Modal>
  );
};
