import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";

const initialState = {
  callGetPageLink: {
    data: [],
    isLoading: false,
    error: null,
    response: null,
  },
};

const sliceAdminMgrLink = createSlice({
  name: "admin-mgr-link",
  initialState,
  reducers: {
    updateData: (state, action) => {
      state.callGetPageLink.data = action.payload;
    },
  },

  extraReducers: (builder) => {
    // putUpdatePin API handlers
    builder
      .addMatcher(api.endpoints.getPageLink.matchPending, (state) => {
        state.callGetPageLink.isLoading = true;
        state.callGetPageLink.error = null;
      })
      .addMatcher(api.endpoints.getPageLink.matchFulfilled, (state, action) => {
        state.callGetPageLink.isLoading = false;
        state.callGetPageLink.data = action.payload?.data?.data || [];
        state.callGetPageLink.response = action.payload || null;
      })
      .addMatcher(api.endpoints.getPageLink.matchRejected, (state, action) => {
        state.callGetPageLink.isLoading = false;
        state.callGetPageLink.error = action.payload;
      });
  },
});

export default sliceAdminMgrLink.reducer;
export const { updateData } = sliceAdminMgrLink.actions;
