import PropTypes from "prop-types";
import { IdleTimerProvider } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { logOut } from "shared/redux/slices/authSlice";
import { clearAllMySelf } from "../redux/slices/sliceMySelf";

const TIME_OUT = 60 * 60 * 1000; // 1 hrs

export const ReactIdleTimerProviders = ({ children }) => {
  const dispatch = useDispatch();
  return (
    <IdleTimerProvider
      timeout={TIME_OUT}
      throttle={500}
      onIdle={() => {
        dispatch(clearAllMySelf());
        dispatch(logOut());
      }}
      crossTab
    >
      {children}
    </IdleTimerProvider>
  );
};

ReactIdleTimerProviders.propTypes = {
  children: PropTypes.node,
};
