import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import {
  changePasswordItemStyle,
  changePasswordSubTitleStyle,
  changePasswordTitleStyle,
  containerStyle,
  errorMessage,
  titleStyle,
  updatePasswordButtonStyle,
} from "../styles/ChangePassword";

import pxToRem from "assets/theme/functions/pxToRem";
import typography from "assets/theme/base/typography";
import { useChangePasswordV2Mutation } from "shared/redux/endpoints/auth";
import { useFormik } from "formik";
import { initialValues, validations, form } from "../schemas/changePasswordForm";
import { ChangePasswordLoading } from "shared/components/ui/ChangePasswordLoading";
import { useMinimumFetchTimeElapsed } from "shared/hooks/useMinimumFetchTime";
import { toast } from "react-toastify";
import { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import InfoIcon, { TooltipIconColor, TooltipType } from "../../../components/InfoIcon";
import { teacherInfoIconBlock } from "../../../components/InfoIcon/teacherInfoIcon";
import makeStyles from "../../../pages/style";

const customInputStyle = {
  height: pxToRem(40),
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    height: "100%",
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    paddingLeft: pxToRem(20),
  },

  "& input::placeholder": {
    fontFamily: typography.lexendFont,
  },

  "& label": {
    fontSize: 14,
    top: -2,
    fontFamily: typography.lexendFont,
    color: "#A0AEC0",
    left: 4,
  },

  "& legend": {
    marginLeft: 6,
  },
};

export const ChangePassword = () => {
  const classes = makeStyles();
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    repeat: false,
  });

  const handleClickShowPassword = (type) =>
    setShowPassword((show) => ({ ...show, [type]: !show[type] }));

  const passwordRequirements = [
    "One upper case",
    "One lower case",
    "One number",
    "Min 8 characters, Max 20 characters",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography
          variant="button"
          color="text"
          verticalAlign="middle"
          sx={(theme) => changePasswordItemStyle(theme)}
        >
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const { formId, formField } = form;
  const { currentPassword, newPassword, confirmPassword } = formField;

  const [changePassword, { isLoading }] = useChangePasswordV2Mutation();

  const minimumFetchingTime = useMinimumFetchTimeElapsed(1000, isLoading);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validations,
    onSubmit: async (values, { resetForm }) => {
      const response = await changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });

      if (minimumFetchingTime) {
        if (response.error) {
          toast.error("Can't change password, please check again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              fontFamily: "Lexend",
              fontSize: 14,
            },
          });
        } else {
          toast.success("Change password successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              fontFamily: "Lexend",
              fontSize: 14,
            },
          });
        }
        resetForm();
      }
    },
  });

  const currentPasswordError =
    formik.touched.currentPassword && Boolean(formik.errors.currentPassword);
  const newPasswordError = formik.touched.newPassword && Boolean(formik.errors.newPassword);
  const confirmPasswordError =
    formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword);

  return (
    <MDBox sx={{ minHeight: "calc(100vh - 24px)" }}>
      <Card sx={(theme) => containerStyle(theme)}>
        {!minimumFetchingTime ? (
          <MDBox
            sx={() => ({
              width: "100%",
              height: "fit-content",
              top: 0,
              left: 0,
              zIndex: 10,
              background: "#EDF2F7",
              borderRadius: "0.75rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <ChangePasswordLoading style={{ width: 200, height: 400 }} />
          </MDBox>
        ) : (
          <>
            <MDBox pb={3}>
              <InfoIcon
                type={TooltipType.TYPE_1}
                teacherInfoIconBlock={teacherInfoIconBlock.CHANGE_PASSWORD}
                color={TooltipIconColor.BLUE}
                className={classes.containerInfoIconWithHeading}
              >
                <MDTypography variant="h5" sx={(theme) => titleStyle(theme)}>
                  Change Password
                </MDTypography>
              </InfoIcon>
            </MDBox>
            <MDBox component="form" role="form" id={formId} onSubmit={formik.handleSubmit} pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    label={currentPassword.label}
                    name={currentPassword.name}
                    type={showPassword["current"] ? "text" : currentPassword.type}
                    customStyle={customInputStyle}
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={currentPasswordError}
                    isPassword
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword("current")}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                            size="small"
                          >
                            {showPassword["current"] ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {currentPasswordError ? (
                    <MDTypography sx={(theme) => errorMessage(theme)}>
                      {formik.errors.currentPassword}
                    </MDTypography>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    label={newPassword.label}
                    name={newPassword.name}
                    type={showPassword["new"] ? "text" : currentPassword.type}
                    customStyle={customInputStyle}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={newPasswordError}
                    isPassword
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword("new")}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                            size="small"
                          >
                            {showPassword["new"] ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {newPasswordError ? (
                    <MDTypography sx={(theme) => errorMessage(theme)}>
                      {formik.errors.newPassword}
                    </MDTypography>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    label={confirmPassword.label}
                    name={confirmPassword.name}
                    type={showPassword["repeat"] ? "text" : currentPassword.type}
                    customStyle={customInputStyle}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={confirmPasswordError}
                    isPassword
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword("repeat")}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                            size="small"
                          >
                            {showPassword["repeat"] ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {confirmPasswordError ? (
                    <MDTypography sx={(theme) => errorMessage(theme)}>
                      {formik.errors.confirmPassword}
                    </MDTypography>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <MDBox mt={6}>
                <MDTypography variant="h5" sx={(theme) => changePasswordTitleStyle(theme)}>
                  Password requirements
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography
                  variant="body2"
                  color="text"
                  sx={(theme) => changePasswordSubTitleStyle(theme)}
                >
                  Please follow this guide for a strong password
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                <MDBox
                  component="ul"
                  m={0}
                  pl={3.25}
                  mb={{ xs: 8, sm: 0 }}
                  sx={{ listStyleType: "disc" }}
                >
                  {renderPasswordRequirements}
                </MDBox>
                <MDBox ml="auto">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                    sx={(theme) => updatePasswordButtonStyle(theme)}
                  >
                    Update Password
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </>
        )}
      </Card>
    </MDBox>
  );
};
