import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CategoryArrowIcon from "components/icons/CategoryArrowIcon";
import {
  containerStyle,
  headerStyle,
  itemStyle,
  logoImageStyle,
  logoStyle,
  subTitleStyle,
  titleStyle,
} from "./style";
import { useLazyGetDashboardTeacherQuery } from "shared/redux/endpoints/sso";
import { useSelector } from "react-redux";
import { useGetHubMutation } from "shared/redux/endpoints/sso";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";
import makeStyles from "pages/style";
import React, { useEffect, useState } from "react";
import { Loading } from "shared/components/ui/Loading";
import CategoryBoxIcon from "components/icons/CategoryBoxIcon";

export const Category = ({ index, category, dataLink }) => {
  const [trigger] = useLazyGetDashboardTeacherQuery();
  const [getHub] = useGetHubMutation();
  const user = useSelector((state) => state.auth.user);
  const email = user.email;
  const classes = makeStyles();
  const [dataCategory, setDataCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (category.header === "LINKS") {
      if (dataLink && dataLink.length > 0) {
        let logo = <CategoryBoxIcon />;
        setIsLoading(true);
        const dataLinkNew = dataLink.map((item) => ({
          logo,
          title: item.title,
          subtitle: item.description,
          link: item.url,
        }));
        setDataCategory(dataLinkNew);
        setIsLoading(false);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      }
    } else {
      setDataCategory(category.items);
      setIsLoading(false);
    }
  }, [category.header, dataLink]);

  return (
    <MDBox key={index} width="100%" sx={(theme) => containerStyle(theme, { index })}>
      <MDBox className={classes.containerDisplayFlex}>
        {category.header === "WORK STATION" && (
          <InfoIcon
            type={TooltipType.TYPE_2}
            teacherInfoIconBlock={teacherInfoIconBlock.WORK_STATION}
            color={TooltipIconColor.BLUE}
            className={classes.containerInfoIconWith2}
          >
            <MDTypography variant="h5" sx={(theme) => headerStyle(theme, { index })}>
              {category.header}
            </MDTypography>
          </InfoIcon>
        )}

        {category.header === "LINKS" && (
          <InfoIcon
            type={TooltipType.TYPE_2}
            teacherInfoIconBlock={teacherInfoIconBlock.LINKS}
            color={TooltipIconColor.BLUE}
            className={classes.containerInfoIconWith2}
          >
            <MDTypography variant="h5" sx={(theme) => headerStyle(theme, { index })}>
              {category.header}
            </MDTypography>
          </InfoIcon>
        )}
      </MDBox>

      <MDBox>
        {isLoading && (
          <MDBox
            width="100%"
            height={300}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loading style={{ width: 150, height: 100 }} />
          </MDBox>
        )}
        {!isLoading &&
          dataCategory.map((item, _index) => {
            return (
              <MDBox
                key={`category-${_index}`}
                sx={(theme) => itemStyle(theme, { index })}
                onClick={async () => {
                  if (item.title === "LMS") {
                    const res = await trigger();
                    const data = res?.data?.data?.url;
                    if (data) {
                      window.open(data, "_blank");
                    }
                  } else if (item.title === "WeLearn") {
                    const linkToWeLearn = `https://welearn.apollo.vn/s-s-o?magic=${encodeURIComponent(
                      email
                    )}&type=${1}`;
                    window.open(linkToWeLearn, "_blank");
                  } else if (item.title === "HUB 24") {
                    const res = await getHub({
                      redirectURL: "http://hub24.apollo.vn:10001/user/profile",
                    });
                    const data = res?.data?.data;
                    if (data) {
                      window.open(data, "_blank");
                    }
                  } else {
                    window.open(item.link, "_blank");
                  }
                }}
              >
                <MDBox sx={(theme) => logoStyle(theme, { index })}>
                  {typeof item.logo === "string" ? (
                    <MDBox component="img" src={item.logo} sx={(theme) => logoImageStyle(theme)} />
                  ) : (
                    item.logo
                  )}
                </MDBox>
                <MDBox sx={{ flexGrow: 1, flexBasis: 0 }}>
                  <MDTypography sx={(theme) => titleStyle(theme, { index })}>
                    {item.title}
                  </MDTypography>
                  <MDTypography paragraph sx={(theme) => subTitleStyle(theme, { index })}>
                    {item.subtitle}
                  </MDTypography>
                </MDBox>

                <MDBox>
                  <Icon component={() => <CategoryArrowIcon fill={"#172B4D"} />} />
                </MDBox>
              </MDBox>
            );
          })}
      </MDBox>
    </MDBox>
  );
};

Category.propTypes = {
  index: PropTypes.number.isRequired,
  category: PropTypes.shape({
    header: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
  }),
};
