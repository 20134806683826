import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDBox from "../MDBox";
import { Loading } from "shared/components/ui/Loading";
import { SVGIcon } from "../icons";

export interface IModalObservationDetailProps {
  isOpen: boolean;
  detailLoading: boolean;
  detail: any;
  onClose: () => void;
}

const DialogObservationDetail = (props: IModalObservationDetailProps) => {
  const { isOpen, detail, detailLoading, onClose } = props;

  const mapGroups = detail.mapGroups;
  const dataHaveScore = detail?.dataHaveScore;
  const dataNotHaveScore = detail?.listDataNotHaveScore;
  const probationResult = detail?.probationResult;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className="dialog-observation-detail-container"
    >
      {detailLoading && (
        <MDBox style={{ width: 450, height: 400 }}>
          <Loading style={{ width: 150, height: 100, margin: "150px auto" }} />
        </MDBox>
      )}

      {!detailLoading && (
        <>
          <DialogTitle id="scroll-dialog-title" className="dialog-observation-detail-header">
            {probationResult && (
              <MDBox className="probation-observation-result-container">
                <MDBox className="probation-observation-result">
                  <h3>probation observation result</h3>
                  <MDBox style={{ display: "flex" }}>
                    {probationResult.isPass && (
                      <>
                        <SVGIcon name={"online_status"} width={6} height={8} fill={"#2DCE89"} />
                        <span className="success">{probationResult.label}</span>
                      </>
                    )}
                    {!probationResult.isPass && (
                      <>
                        <SVGIcon name={"online_status"} width={6} height={8} fill={"#F5365C"} />
                        <span className="fail">{probationResult.label}</span>
                      </>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            )}
            this section is used for all types of observation
          </DialogTitle>
          <DialogContent dividers={true} className="dialog-observation-detail-content">
            <MDBox className="table-container">
              <MDBox>
                <p className="sub-title">
                  3 = Above Standard, 2 = Standard, 1 = Below Standard, 0 = Very Poor/Way Below
                  Standard
                </p>
              </MDBox>
              <table className="custom-table">
                <thead>
                  <tr>
                    <th style={{ width: "100px" }}>Code</th>
                    <th>Question</th>
                    <th style={{ width: "70px" }}>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {dataHaveScore &&
                    Object.keys(dataHaveScore).map((name, index) => (
                      <React.Fragment key={index}>
                        <tr className="group-header">
                          <td colSpan={1} style={{ paddingLeft: 28 }}>
                            {mapGroups.get(name).code}
                          </td>
                          <td
                            colSpan={1}
                            style={{
                              borderRight: "none",
                            }}
                          >
                            {mapGroups.get(name).name}
                          </td>
                          <td colSpan={1}></td>
                        </tr>
                        {dataHaveScore[name].map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td style={{ paddingLeft: 28 }}>{row.code}</td>
                            <td>{row.question}</td>
                            <td className="item-score">
                              {row.score !== undefined && row.score !== "-"
                                ? parseInt(row.score)
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
              <table className="custom-table">
                <tbody>
                  {dataNotHaveScore &&
                    dataNotHaveScore.map((row, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        {row.length === 3 && (
                          <>
                            <tr className="group-header-v2 group-header-with-col3">
                              <td colSpan={1}>{row[0].code}</td>
                              <td colSpan={1}>{row[1].code}</td>
                              <td colSpan={1}>{row[2].code}</td>
                            </tr>
                            <tr>
                              <td
                                colSpan={1}
                                className={row[0].question === "-" ? "center-text" : ""}
                              >
                                {row[0].question}
                              </td>
                              <td
                                colSpan={1}
                                className={row[1].question === "-" ? "center-text" : ""}
                              >
                                {row[1].question}
                              </td>
                              <td
                                colSpan={1}
                                className={row[2].question === "-" ? "center-text" : ""}
                              >
                                {row[2].question}
                              </td>
                            </tr>
                          </>
                        )}

                        {row.length === 1 && (
                          <>
                            <tr className="group-header-v2">
                              <td colSpan={3}>{row[0].code}</td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className={row[0].question === "-" ? "center-text" : ""}
                              >
                                {row[0].question}
                              </td>
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </MDBox>
          </DialogContent>
        </>
      )}

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogObservationDetail;
