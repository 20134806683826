import * as React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "../MDBox";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";

export interface IModalCreateOrUpdateLinkProps {
  dataUpdate: {
    id: number,
    url: string,
    title: string,
    description: string,
  };
  open: boolean;
  onClose: () => void;
  onCreate: (data: any, cbClose: any) => void;
  onEdit: (data: any, cbClose: any) => void;
}

const DialogCreateOrUpdateLink = (props: IModalCreateOrUpdateLinkProps) => {
  const { dataUpdate, open, onClose, onCreate, onEdit } = props;
  const [formData, setFormData] = useState(dataUpdate);
  const [loadingButtonSubmit, setLoadingButtonSubmit] = useState(false);

  const [errors, setErrors] = useState({});

  const validate = () => {
    let validationErrors = {};
    // Link validation: must be a valid URL
    const urlPattern = /^(https?:\/\/)?([\w\d\-]+\.)+\w{2,}(\/.*)?$/;
    if (!formData.url) {
      validationErrors.url = "Link is required.";
    } else if (!urlPattern.test(formData.url)) {
      validationErrors.url = "Enter a valid URL.";
    }

    // Title validation: must not be empty
    if (!formData.title) {
      validationErrors.title = "Title is required.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      if (loadingButtonSubmit) {
        return;
      }
    }
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoadingButtonSubmit(true);
      try {
        if (!formData.id) {
          await onCreate(formData, onClose);
        } else {
          await onEdit(formData, onClose);
        }
      } catch (error) {
      } finally {
        setLoadingButtonSubmit(false);
      }
    }
  };

  useEffect(() => {
    if (errors) {
      setErrors({});
    }
  }, [open]);

  const renderAddNewLink = () => {
    return (
      <>
        <DialogTitle className="title-container">
          <Typography variant="h6" component="div" className="title">
            {formData?.id ? "Update links" : "Add new links"}
          </Typography>
        </DialogTitle>
        <DialogContent className="content-container">
          <MDBox component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Typography variant="h6" component="div" className="label">
              Links
            </Typography>
            <TextField
              className="text-field"
              fullWidth
              margin="normal"
              name="url"
              variant="outlined"
              placeholder="Click to edit"
              value={formData.url}
              onChange={handleChange}
              error={!!errors.url}
              helperText={errors.url}
              disabled={loadingButtonSubmit}
              autoFocus
            />
            <MDBox className="divider" />
            <Typography variant="h6" component="div" className="label">
              Title
            </Typography>
            <TextField
              className="text-field"
              fullWidth
              margin="normal"
              name="title"
              variant="outlined"
              placeholder="Set a title here"
              value={formData.title}
              onChange={handleChange}
              error={!!errors.title}
              helperText={errors.title}
              disabled={loadingButtonSubmit}
            />
            <MDBox className="divider" />
            <Typography variant="h6" component="div" className="label">
              Description
            </Typography>
            <TextField
              className="text-field"
              fullWidth
              margin="normal"
              name="description"
              variant="outlined"
              placeholder="Description ..."
              multiline
              rows={4}
              value={formData.description}
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors.description}
              disabled={loadingButtonSubmit}
            />
          </MDBox>
        </DialogContent>
        <DialogActions className="actions-container">
          <Button onClick={handleClose} className="cancel" disabled={loadingButtonSubmit}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            className="submit"
            disabled={loadingButtonSubmit}
          >
            {loadingButtonSubmit ? (
              <Box display="flex" alignItems="center" gap={1}>
                <CircularProgress size={20} color="inherit" />
                <span>Saving...</span>
              </Box>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </>
    );
  };

  useEffect(() => {
    if (dataUpdate) {
      setFormData(dataUpdate);
    }
  }, [dataUpdate]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
      className="dialog-create-or-update-link-container"
      disableEscapeKeyDown={loadingButtonSubmit}
    >
      {renderAddNewLink()}
    </Dialog>
  );
};

export default DialogCreateOrUpdateLink;
