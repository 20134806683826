import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

export interface IPageSizeProps {
  pageSize: number;
  onChangePageSize: (event: any) => void;
}

const PageSize = (props: IPageSizeProps) => {
  const { pageSize, onChangePageSize } = props;
  return (
    <>
      <FormControl size="small" sx={{ minWidth: 60 }}>
        <Select
          className="page-size-select"
          value={pageSize}
          onChange={onChangePageSize}
          displayEmpty
          inputProps={{ "aria-label": "Rows per page" }}
          variant="outlined"
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
      <Typography className="page-size-description">entries per page</Typography>
    </>
  );
};

export default PageSize;
