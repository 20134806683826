import { FaSpinner } from "react-icons/fa";
import { ChangePin } from "model/MySelfContainer.model";
import { useState } from "react";
import MDBox from "components/MDBox";

export interface IPinCodeChangeFormProps {
  changePin: ChangePin;
  handlePinChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPinChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoadingSubmit: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  pinCodeHide: string;
}

const PinCodeChangeForm = (props: IPinCodeChangeFormProps) => {
  const {
    changePin,
    handlePinChange,
    handleConfirmPinChange,
    handleSubmit,
    isLoadingSubmit,
    pinCodeHide,
  } = props;
  const [openFormChangePin, setOpenFormChangePin] = useState(false);
  return openFormChangePin ? (
    <form onSubmit={handleSubmit} className="pin-change-form">
      <div className="pin-change-field">
        <label htmlFor="pin" className="pin-change-label">
          Enter your PIN code here
        </label>
        <input
          id="pin"
          value={changePin.pinChange}
          onChange={handlePinChange}
          required
          maxLength="4"
          placeholder="Numbers only (4 digits)"
          className="pin-change-input"
          pattern="\d*"
        />
      </div>

      <div className="pin-change-field">
        <label htmlFor="pin" className="pin-change-label">
          Confirm your PIN code again
        </label>
        <input
          id="confirmPinChange"
          value={changePin.confirmPinChange}
          onChange={handleConfirmPinChange}
          required
          maxLength="4"
          placeholder="Numbers only (4 digits)"
          className="pin-change-input"
          pattern="\d*"
        />
      </div>

      <MDBox style={{ display: "flex" }}>
        <button type="submit" className="pin-change-button" disabled={isLoadingSubmit}>
          {isLoadingSubmit ? (
            <>
              <FaSpinner className="spinner-icon" /> CONFIRM CHANGE...
            </>
          ) : (
            "CONFIRM CHANGE"
          )}
        </button>
        {changePin.error && <p className="pin-change-error">{changePin.error}</p>}
      </MDBox>
    </form>
  ) : (
    <div className="pin-change-form">
      <div className="pin-change-field">
        <label htmlFor="pin" className="pin-change-label">
          Your current PIN code
        </label>
        <input
          id="pin"
          required
          maxLength="4"
          placeholder={pinCodeHide}
          className="pin-change-input disabled"
          pattern="\d*"
          disabled={true}
        />
      </div>
      <button
        type="submit"
        className="pin-change-button"
        onClick={() => setOpenFormChangePin(true)}
      >
        Change My Pin
      </button>
    </div>
  );
};

export default PinCodeChangeForm;
