import React from "react";
import PropTypes from "prop-types";
import MDBox from "./MDBox";
import MDTypography from "./MDTypography";
import { boxItem, containerBoxStyle, descStyle, titleStyle } from "pages/development/style";
import { Course } from "pages/development/components/Course";

const PhaseBox = ({ item, index, open, isScrolling }) => {
  return (
    <>
      <MDBox
        sx={{
          borderRadius: "12px",
          background: "white",
          padding: "20px",
          cursor: "pointer",
          display: open[index] ? "block" : "none",
        }}
        mt={2}
        key={item.title}
      >
        <MDBox
          sx={{
            borderRadius: "12px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <MDTypography sx={(theme) => titleStyle(theme)}>
            <>{item.title}</>
          </MDTypography>
          <MDTypography sx={(theme) => descStyle(theme)}>
            <>{item.desc}</>
          </MDTypography>
          <MDBox
            sx={(theme) => containerBoxStyle(theme, { isScrolling })}
            style={{
              display: open[index] ? "flex" : "none",
            }}
          >
            {item?.data
              ?.filter((_item) => _item.status === "Not Complete")
              ?.map((item1) => (
                <MDBox sx={(theme) => boxItem(theme)} key={item1.id}>
                  <Course {...item1} disable={item1.disable} />
                </MDBox>
              ))}
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

// Define prop types
PhaseBox.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  open: PropTypes.arrayOf(PropTypes.bool).isRequired,
  isScrolling: PropTypes.bool,
};

export default PhaseBox;
