import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "./api";
import { apiErrorMiddleware } from "./middleware/apiErrorHandler";

// Config for redux-persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

// Create persistedReducer with redux-persist
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.middleware)
      .concat(apiErrorMiddleware),
});

// Persist store
store.__persist = persistStore(store);

export { store };
