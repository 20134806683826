import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";

export const localStorageMySelfEnum = {
  PIN_VERIFIED_TIME: "pinVerifiedTime",
  ATTEMPTS_LEFT: "attemptsLeft",
};

const baseApi = {
  data: null,
  isLoading: false,
  error: null,
};

const initialState = {
  isLocked: false,
  callGetMySelf: baseApi,
  callPostCreatePin: baseApi,
  callPutUpdatePin: baseApi,
  callPostVerifyPin: baseApi,
  callPostResetPin: baseApi,
  callLockAccountPin: baseApi,
};

const sliceMySelf = createSlice({
  name: "mySelf",
  initialState,
  reducers: {
    setIsLocked: (state, action) => {
      state.isLocked = action.payload;
    },
    clearAllMySelf: () => initialState,
  },

  extraReducers: (builder) => {
    // getMySelf API handlers
    builder
      .addMatcher(api.endpoints.getMySelf.matchPending, (state) => {
        state.callGetMySelf.isLoading = true;
        state.callGetMySelf.error = null;
      })
      .addMatcher(api.endpoints.getMySelf.matchFulfilled, (state, action) => {
        state.callGetMySelf.isLoading = false;
        state.callGetMySelf.data = action.payload;
      })
      .addMatcher(api.endpoints.getMySelf.matchRejected, (state, action) => {
        state.callGetMySelf.isLoading = false;
        state.callGetMySelf.error = action.payload;
      });

    // postCreatePin API handlers
    builder
      .addMatcher(api.endpoints.postCreatePin.matchPending, (state) => {
        state.callPostCreatePin.isLoading = true;
        state.callPostCreatePin.error = null;
      })
      .addMatcher(api.endpoints.postCreatePin.matchFulfilled, (state, action) => {
        state.callPostCreatePin.isLoading = false;
        state.callPostCreatePin.data = action.payload;
      })
      .addMatcher(api.endpoints.postCreatePin.matchRejected, (state, action) => {
        state.callPostCreatePin.isLoading = false;
        state.callPostCreatePin.error = action.payload;
      });

    // putUpdatePin API handlers
    builder
      .addMatcher(api.endpoints.putUpdatePin.matchPending, (state) => {
        state.callPutUpdatePin.isLoading = true;
        state.callPutUpdatePin.error = null;
      })
      .addMatcher(api.endpoints.putUpdatePin.matchFulfilled, (state, action) => {
        state.callPutUpdatePin.isLoading = false;
        state.callPostCreatePin.data = action.payload;
      })
      .addMatcher(api.endpoints.putUpdatePin.matchRejected, (state, action) => {
        state.callPutUpdatePin.isLoading = false;
        state.callPostCreatePin.error = action.payload;
      });

    // postVerifyPin API handlers
    builder
      .addMatcher(api.endpoints.postVerifyPin.matchPending, (state) => {
        state.callPostVerifyPin.isLoading = true;
        state.callPutUpdatePin.error = null;
      })
      .addMatcher(api.endpoints.postVerifyPin.matchFulfilled, (state, action) => {
        state.callPostVerifyPin.isLoading = false;
        state.callPostVerifyPin.data = action.payload;
      })
      .addMatcher(api.endpoints.postVerifyPin.matchRejected, (state, action) => {
        state.callPostVerifyPin.isLoading = false;
        state.callPutUpdatePin.error = action.payload;
      });

    // postResetPin API handlers
    builder
      .addMatcher(api.endpoints.postResetPin.matchPending, (state) => {
        state.callPostResetPin.isLoading = true;
        state.callPostResetPin.error = null;
      })
      .addMatcher(api.endpoints.postResetPin.matchFulfilled, (state, action) => {
        state.callPostResetPin.isLoading = false;
        state.callPostResetPin.data = action.payload;
      })
      .addMatcher(api.endpoints.postResetPin.matchRejected, (state, action) => {
        state.callPostResetPin.isLoading = false;
        state.callPostResetPin.error = action.payload;
      });

    // lockAccountPin API handlers
    builder
      .addMatcher(api.endpoints.postLockAccount.matchPending, (state) => {
        state.callLockAccountPin.isLoading = true;
        state.callLockAccountPin.error = null;
      })
      .addMatcher(api.endpoints.postLockAccount.matchFulfilled, (state, action) => {
        state.callLockAccountPin.isLoading = false;
        state.callLockAccountPin.data = action.payload;
      })
      .addMatcher(api.endpoints.postLockAccount.matchRejected, (state, action) => {
        state.callLockAccountPin.isLoading = false;
        state.callLockAccountPin.error = action.payload;
      });
  },
});

export const { setIsLocked, clearAllMySelf } = sliceMySelf.actions;

export default sliceMySelf.reducer;
