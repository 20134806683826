export const useLazyQueryWithCallbacks = (queryHook: Function) => {
  return () => {
    const [triggerQuery, { data, error, isLoading }] = queryHook();

    return async (
      params: any,
      onPending?: () => void,
      onSuccess?: (response: any) => void,
      onFail?: (error: any) => void
    ) => {
      try {
        if (onPending) {
          onPending();
        }

        const result = await triggerQuery(params).unwrap();

        if (onSuccess) {
          onSuccess(result);
        }
      } catch (error) {
        if (onFail) {
          onFail(error);
        }
      }
    };
  };
};
