const getStartOfWeek = (date) => {
  const day = date.getDay(); // 0 (Chủ nhật) đến 6 (Thứ bảy)
  const diff = date.getDate() - day;
  return new Date(date.setDate(diff));
};

const getEndOfWeek = (date) => {
  const startOfWeek = getStartOfWeek(date);
  return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
};

const getStartOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);

const getEndOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const addMonths = (date, months) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};

export const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [getStartOfWeek(new Date()), getEndOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [addDays(new Date(), -6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [addDays(new Date(), -29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [getStartOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [getStartOfMonth(addMonths(new Date(), -1)), getEndOfMonth(addMonths(new Date(), -1))],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear() - 1, 11, 31),
    ],
    placement: "left",
  },
  {
    label: "All time",
    value: [new Date(2000, 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last week",
    value: (value) => {
      const start = value?.[0] || new Date();
      return [addDays(getStartOfWeek(start), -7), addDays(getEndOfWeek(start), -7)];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    value: (value) => {
      const start = value?.[0] || new Date();
      return [addDays(getStartOfWeek(start), 7), addDays(getEndOfWeek(start), 7)];
    },
    appearance: "default",
  },
];

export const DateUtil = {
  predefinedRanges,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
  addDays,
  addMonths,
};
