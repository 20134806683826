import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import UserCard from "components/UserCard/UserCard";
import Grid from "@mui/material/Grid";
import GroupTabButton from "components/GroupTabButton/GroupTabButton";
import TabCourse, { ITabCourseProps } from "components/TabCourse/TabCourse";
import type { Tab } from "model/MyDevelopmentContainer.model";
import {
  MyDevelopmentData,
  MyDevelopmentTabEnum,
  WorkshopTabEnum,
} from "data/my-development/MyDevelopment.data";
import { useMyDevelopmentAPIs } from "shared/redux/endpoints/endPointMyDevelopment";
import type { Error400 } from "utilities/toastUtil";
import { ToastUtil } from "utilities/toastUtil";
import { FlagMyDevelopment } from "model/MyDevelopmentContainer.model";
import { Loading } from "shared/components/ui/Loading";
import TabWorkshop, { ITabWorkshopProps } from "components/TabWorkshop/TabWorkshop";
import TabObservation, { ITabObservationProps } from "components/TabObservation/TabObservation";
import {
  setCallGetDataPageObservationTabFilter,
  setCallGetDataPageObservationTabPagination,
} from "shared/redux/slices/sliceMyDevelopment";

const MyDevelopmentContainer = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState([true, false, false]);
  const [flagMyDevelopment, setFlagMyDevelopment] = useState(new FlagMyDevelopment());
  const { user, role } = useSelector((state) => state.auth);
  const [tabMyDevelopment, setTabMyDevelopment] = useState(MyDevelopmentTabEnum.Course);
  const [tabWorkshop, setTabWorkshop] = useState(WorkshopTabEnum.Available);
  const [isScrolling, setIsScrolling] = useState(false);

  // Call API
  const getPhaseBoardOfCourseTab = useMyDevelopmentAPIs.useGetPhaseBoardOfCourseTab();
  const getListPhaseOfCourseTab = useMyDevelopmentAPIs.useGetListPhaseOfCourseTab();
  const getDataPageOfWorkshopTab = useMyDevelopmentAPIs.useGetDataPageOfWorkshopTab();
  const getDataPageOfObservationTab = useMyDevelopmentAPIs.useGetDataPageOfObservationTab();
  const getDataDetailOfObservationTab = useMyDevelopmentAPIs.useGetDataDetailOfObservationTab();

  // Redux
  const { data: dataPhaseBoard } = useSelector(
    (state) => state.myDevelopment.callGetPhaseBoardOfCourseTab
  );
  const { data: listPhaseBoard } = useSelector(
    (state) => state.myDevelopment.callGetListPhaseOfCourseTab
  );
  const {
    dataTableAvailable,
    dataTableRegistered,
    dataTableAttended,
    isLoading: isLoadingDataWorkShop,
  } = useSelector((state) => state.myDevelopment.callGetDataPageWorkshopTab);
  const {
    isLoading: isLoadingDataObservation,
    data: rowsTableObservation,
    currentPage: currentPageTableObservation,
    pageSize: pageSizeTableObservation,
    totalPage: totalPageTableObservation,
    totalElement: totalElementTableObservation,
    filter: filterTableObservation,
  } = useSelector((state) => state.myDevelopment.callGetDataPageObservationTab);
  const { isLoading: isLoadingdataDetailObservation, data: dataDetailObservation } = useSelector(
    (state) => state.myDevelopment.callGetDataDetailObservationTab
  );

  const callGetListPhaseOfCourseTab = useCallback(async () => {
    await getListPhaseOfCourseTab({}, undefined, undefined, (error: Error400) => {
      ToastUtil.showToastError400(error);
    });
    setFlagMyDevelopment((prevState: FlagMyDevelopment) => {
      return { ...prevState, isLoadingTab: false };
    });
  }, [getListPhaseOfCourseTab]);

  const callGetPhaseBoardOfCourseTab = useCallback(async () => {
    await getPhaseBoardOfCourseTab({}, undefined, undefined, (error: Error400) => {
      ToastUtil.showToastError400(error);
    });
    setFlagMyDevelopment((prevState: FlagMyDevelopment) => {
      return { ...prevState, isLoadingTab: false };
    });
  }, [getPhaseBoardOfCourseTab]);

  const callGetDataPageOfWorkshopTab = useCallback(async () => {
    const email = user.email;
    await getDataPageOfWorkshopTab({ email }, undefined, undefined, (error: Error400) => {
      ToastUtil.showToastError400(error);
    });
    setFlagMyDevelopment((prevState: FlagMyDevelopment) => {
      return { ...prevState, isLoadingTab: false };
    });
  }, [getDataPageOfWorkshopTab]);

  const callGetDataDetailOfObservationTab = useCallback(
    async (params) => {
      await getDataDetailOfObservationTab(params, undefined, undefined, (error: Error400) => {
        ToastUtil.showToastError400(error);
      });
      setFlagMyDevelopment((prevState: FlagMyDevelopment) => {
        return { ...prevState, isLoadingTab: false };
      });
    },
    [getDataDetailOfObservationTab]
  );

  const callGetDataPageOfObservationTab = async (params) => {
    const paramsDefault = {
      page: currentPageTableObservation,
      pageSize: pageSizeTableObservation,
      filter: filterTableObservation,
    };
    params = { ...paramsDefault, ...params };
    const { page, pageSize, filter } = params;
    const dateStart = filter?.date ? filter?.date[0] : null;
    const dateEnd = filter?.date ? filter?.date[1] : null;
    const paramsEnd = {
      page: page,
      size: pageSize,
      observationType: filter?.observation_type,
      dateStart,
      dateEnd,
    };
    await getDataPageOfObservationTab(paramsEnd, undefined, undefined, (error: Error400) => {
      ToastUtil.showToastError400(error);
    });
    setFlagMyDevelopment((prevState: FlagMyDevelopment) => {
      return { ...prevState, isLoadingTab: false };
    });
  };

  const onChangeTabMyDevelopment = async (tag: Tab) => {
    setTabMyDevelopment(tag.title);
    setFlagMyDevelopment((prevState: FlagMyDevelopment) => {
      return { ...prevState, isLoadingTab: true };
    });

    if (tag.title === MyDevelopmentTabEnum.Course) {
      await callGetListPhaseOfCourseTab();
      await callGetPhaseBoardOfCourseTab();
    }

    if (tag.title === MyDevelopmentTabEnum.Workshop) {
      await callGetDataPageOfWorkshopTab();
    }

    if (tag.title === MyDevelopmentTabEnum.Observation) {
      await callGetDataPageOfObservationTab();
    }
  };

  const propertiesUserCard = { user, role };

  const propertiesTabCourse: ITabCourseProps = {
    open,
    setOpen,
    dataList: listPhaseBoard,
    dataBoard: dataPhaseBoard,
    isScrolling,
    setIsScrolling,
  };

  const propertiesTabWorkshop: ITabWorkshopProps = {
    tabWorkshop,
    dataTableAvailable,
    dataTableRegistered,
    dataTableAttended,
    isLoadingData: isLoadingDataWorkShop,
    onClickTab: async (tab: WorkshopTabEnum) => {
      setTabWorkshop(tab);
      await callGetDataPageOfWorkshopTab();
    },
    onCheckSchedule: (courseId: number, organizerId: number) => {
      const url = `https://welearn.apollo.vn/dl-api/workshop?email=${user.email}&course=${courseId}&instance=${organizerId}`;
      window.open(url, "_blank");
    },
  };

  const propertiesTabObservation: ITabObservationProps = {
    isLoading: isLoadingDataObservation,
    rows: rowsTableObservation,
    currentPage: currentPageTableObservation,
    pageSize: pageSizeTableObservation,
    totalPage: totalPageTableObservation,
    totalElement: totalElementTableObservation,
    filter: filterTableObservation,
    onChangePage: async (event, value) => {
      const page = value - 1;
      dispatch(
        setCallGetDataPageObservationTabPagination({
          currentPage: page,
          pageSize: pageSizeTableObservation,
        })
      );
      await callGetDataPageOfObservationTab({
        page: page,
      });
    },
    onChangePageSize: async (event) => {
      let selectPageSize = event.target.value;
      let page = currentPageTableObservation;
      if (selectPageSize * page > totalElementTableObservation) {
        page = Math.floor(totalElementTableObservation / selectPageSize);
      }
      // Update UI
      dispatch(
        setCallGetDataPageObservationTabPagination({
          currentPage: page,
          pageSize: selectPageSize,
        })
      );
      // Call API
      await callGetDataPageOfObservationTab({ page, pageSize: selectPageSize });
    },
    onCompletedSelectRangeDate: async (date) => {
      const filter = {
        ...filterTableObservation,
        date: date?.length === 2 ? date : null,
      };
      // Update UI
      dispatch(setCallGetDataPageObservationTabFilter(filter));
      // Call API
      await callGetDataPageOfObservationTab({ filter });
    },
    onCompletedSelectObservationType: async (value) => {
      const filter = {
        ...filterTableObservation,
        observation_type: value?.length > 0 ? value : null,
      };
      // Update UI
      dispatch(setCallGetDataPageObservationTabFilter(filter));
      // Call API
      await callGetDataPageOfObservationTab({ filter });
    },
    detail: dataDetailObservation,
    detailLoading: isLoadingdataDetailObservation,
    onOpenObservationDetail: async (id: number, isOnline: boolean) => {
      await callGetDataDetailOfObservationTab({ id, isOnline });
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      await callGetListPhaseOfCourseTab();
      await callGetPhaseBoardOfCourseTab();
      await callGetDataPageOfWorkshopTab();
      await callGetDataPageOfObservationTab();
    };
    fetchData().then(
      setFlagMyDevelopment((prevState) => {
        return { ...prevState, isPageLoading: false };
      })
    );
  }, []);

  return (
    <DashboardLayout>
      <Header />
      {flagMyDevelopment.isPageLoading && <Loading style={{ height: 200, margin: "10% auto" }} />}
      {!flagMyDevelopment.isPageLoading && (
        <MDBox className="my-development-container">
          <MDBox className="my-development-container__card_header">
            <UserCard {...propertiesUserCard} />
            <Grid className="my-development-container__card_header__tabs">
              {MyDevelopmentData.tabs.map((tag, index) => {
                return (
                  <GroupTabButton
                    key={index}
                    title={tag.title}
                    isActive={tabMyDevelopment === tag.title}
                    onClick={() => onChangeTabMyDevelopment(tag)}
                  />
                );
              })}
            </Grid>
          </MDBox>
          {flagMyDevelopment.isLoadingTab && (
            <Loading style={{ height: 200, margin: "10% auto" }} />
          )}
          {!flagMyDevelopment.isLoadingTab && (
            <>
              {tabMyDevelopment === MyDevelopmentTabEnum.Course && (
                <TabCourse {...propertiesTabCourse} />
              )}
              {tabMyDevelopment === MyDevelopmentTabEnum.Workshop && (
                <TabWorkshop {...propertiesTabWorkshop} />
              )}
              {tabMyDevelopment === MyDevelopmentTabEnum.Observation && (
                <TabObservation {...propertiesTabObservation} />
              )}
            </>
          )}
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default MyDevelopmentContainer;
