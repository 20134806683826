import { Button, Icon, MenuItem, Modal, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useMemo, useRef, useEffect } from "react";
import WeeklyPrevIcon from "components/icons/WeeklyPrevIcon";
import WeeklyNextIcon from "components/icons/WeeklyNextIcon";
import timezones from "layouts/calendar/data/timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import "./custom.css";
import { useMaterialUIController } from "context";
import { useWeeklyCalendar } from "./hooks/useWeeklyCalendar";
import {
  calendarContainer,
  calendarDayBottomHeader,
  calendarDayHeader,
  calendarDayTopHeader,
  calendarHeader,
  calendarSlotLabel,
  calendarTabMenu,
  calendarTabMenuItem,
  calendarTabMenuItemTitle,
  calendarTitle,
} from "./style";
import modalCalendarIcon from "assets/images/apollo-english/calendar-modal.png";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { useNavigate } from "react-router-dom";
import { useLazyGetOoolabLessonQuery } from "shared/redux/endpoints/sso";
import { useGetHubMutation } from "shared/redux/endpoints/sso";
import { toast } from "react-toastify";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";
import makeStyles from "pages/style";
import ModalWorkShop from "components/ModalWorkShop/ModalWorkShop";
import { ModalMyCalendarStudentAtRisk } from "components/ModalMyCalendar/ModalMyCalendarStudentAtRisk";
import { useDispatch } from "react-redux";
import { TypeClassSession } from "constant/TypeClassSession";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
  padding: 3,
  maxWidth: 400,
};

const customStyle = (disable) => ({
  minWidth: "150px",
  ...(disable && { backgroundColor: "#FAFAFA!important" }),
  borderRadius: "0.375rem",
  "& *": {
    fontFamily: "'Lexend', san-serif !important",
  },
  "& .MuiOutlinedInput-root": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& input, & input::placeholder": {
    fontSize: "12px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
  },

  "& input": {
    paddingLeft: `14px !important`,
  },
});

export interface IWeeklyCalendarProps {
  outerDate: string;
  dataWorkShop: any;
  onChangeTab: (tab: any) => void;
}

function WeeklyCalendar(props: IWeeklyCalendarProps) {
  const dispatch = useDispatch();
  const { outerDate, dataWorkShop, onChangeTab } = props;

  const classes = makeStyles();
  const [trigger] = useLazyGetOoolabLessonQuery();
  const [getHub] = useGetHubMutation();
  const [title, setTitle] = useState("");
  const [data, setData] = useState({});
  const [isOpen, setOpen] = useState(false);

  const calendarRef = useRef(null);

  const [constroller] = useMaterialUIController();

  const { miniSidenav } = constroller;

  const {
    events,
    eventsByDay,
    handleChangeDate,
    tab,
    setTab,
    loading,
    timezone,
    setTimeZone,
    tabs,
  } = useWeeklyCalendar(dataWorkShop);

  const tabValue = useMemo(() => tab.value, [tab]);

  const [view, setView] = useState("timeGridWeek"); // dayGridMonth | timeGridWeek

  useEffect(() => {
    calendarRef?.current?.getApi?.()?.gotoDate?.(outerDate);
    if (view === "timeGridWeek") {
      handleChangeDate(
        moment(outerDate, "YYYY-MM-DD").startOf("isoweek").format("DD-MM-YYYY"),
        moment(outerDate, "YYYY-MM-DD").endOf("isoWeek").format("DD-MM-YYYY")
      );
    } else {
      handleChangeDate(
        moment(outerDate, "YYYY-MM-DD").startOf("month").format("DD-MM-YYYY"),
        moment(outerDate, "YYYY-MM-DD").endOf("month").format("DD-MM-YYYY")
      );
    }
  }, [outerDate, view]);

  useEffect(() => {
    setTimeout(() => {
      calendarRef.current?.getApi().render();
    }, 500);
  }, [miniSidenav]);

  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(outerDate);
  }, [outerDate]);

  const _title = useMemo(() => {
    if (view === "dayGridMonth") {
      if (date) {
        return moment(date).format("MMMM YYYY");
      }
      const current = calendarRef.current?.getApi()?.currentData?.currentDate;
      return moment(current).format("MMMM YYYY");
    }

    if (date) {
      return `${moment(date).startOf("isoWeek").format("Do")} ${
        moment(date).startOf("isoWeek").format("MMMM") !==
        moment(date).endOf("isoWeek").format("MMMM")
          ? moment(date).startOf("isoWeek").format("MMMM")
          : ""
      } - ${moment(date).endOf("isoWeek").format("Do")} ${moment(date)
        .endOf("isoWeek")
        .format("MMMM")}`;
    }
    const startDate = calendarRef.current?.getApi()?.view.currentStart;
    const endDate = calendarRef.current?.getApi()?.view.currentEnd;
    return `${moment(startDate).format("Do")} ${
      moment(startDate).format("MMMM") !== moment(endDate).format("MMMM")
        ? moment(startDate).format("MMMM")
        : ""
    } - ${moment(endDate).format("Do")} ${moment(endDate).format("MMMM")}`;
  }, [calendarRef.current, view, title, date]);

  const navigate = useNavigate();

  useEffect(() => {
    onChangeTab(tab);
  }, [tab, timezone]);

  return (
    <MDBox sx={calendarContainer} id="weekly" className={view}>
      <MDBox sx={calendarHeader}>
        <MDBox sx={{ display: "flex", alignItems: "center", gap: "16px", height: 28 }}>
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              "& > div": {
                height: 28,
                cursor: "pointer",
              },
            }}
          >
            <MDBox
              onClick={() => {
                calendarRef.current?.getApi().prev();
                setTitle(calendarRef.current?.getApi().view.title || "");
                const start = calendarRef.current?.getApi().view.activeStart;
                const end = calendarRef.current?.getApi().view.activeEnd;
                setDate(null);
                handleChangeDate(
                  moment(start).format("DD-MM-YYYY"),
                  moment(end).format("DD-MM-YYYY")
                );
              }}
            >
              <Icon component={WeeklyPrevIcon} />
            </MDBox>
            <MDBox
              onClick={() => {
                calendarRef.current?.getApi().next();
                setTitle(calendarRef.current?.getApi().view.title || "");
                const start = calendarRef.current?.getApi().view.activeStart;
                const end = calendarRef.current?.getApi().view.activeEnd;
                setDate(null);
                handleChangeDate(
                  moment(start).format("DD-MM-YYYY"),
                  moment(end).format("DD-MM-YYYY")
                );
              }}
            >
              <Icon component={WeeklyNextIcon} />
            </MDBox>
          </MDBox>

          <MDTypography variant="span" sx={calendarTitle}>
            {_title}
          </MDTypography>
        </MDBox>
        <MDBox sx={calendarTabMenu} className={classes.containerCalendarFilterTabs}>
          {tabs.map((_tab, index) => {
            return (
              <MDBox
                key={index}
                sx={(theme) => calendarTabMenuItem(theme, { active: _tab.value === tab.value })}
                onClick={() => {
                  setTab(_tab);
                }}
              >
                <MDTypography sx={calendarTabMenuItemTitle}>{_tab.label}</MDTypography>
              </MDBox>
            );
          })}
          <InfoIcon
            type={TooltipType.TYPE_2}
            teacherInfoIconBlock={teacherInfoIconBlock.FILTER_TABS}
            color={TooltipIconColor.BLUE}
            className={classes.containerInfoIconWith4}
          />
        </MDBox>

        {/* <select
            value={timezone}
            onChange={(e) => {
              setTimeZone(e.target.value);
            }}
            style={{ backgroundImage: `url(${SelectArrowIcon})`, fontSize: 12, color: "#8392AB" }}
          >
            {timezones.map((timezone, index) => {
              return (
                <option
                  key={index}
                  value={timezone.value}
                  style={{ fontSize: 12, color: "#8392AB" }}
                >
                  (GMT {timezone.label})
                </option>
              );
            })}
          </select> */}
        <MDBox className={classes.containerCalendarBreakdownUnderSmallCalendar}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timezone}
            onChange={(e) => {
              setTimeZone(e.target.value);
            }}
            sx={{
              ...customStyle,
              width: "100px",
              mr: 2,
              "& input.MuiSelect-select": {
                padding: "10px !important",
              },
              "& .MuiInputBase-input": {
                // paddingLeft: `14px !important`,
                padding: "10px !important",
                // minHeight: "40.25px !important",
              },
            }}
          >
            {timezones.map((timezone, index) => {
              return (
                <MenuItem key={index} value={timezone.value}>
                  (GMT {timezone.label})
                </MenuItem>
              );
            })}
          </Select>
        </MDBox>
      </MDBox>
      <MDBox sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
        <InfoIcon
          type={TooltipType.TYPE_2}
          teacherInfoIconBlock={teacherInfoIconBlock.WEEK_MONTH}
          color={TooltipIconColor.BLUE}
          className={classes.containerInfoIconWith3}
        >
          <MDBox
            sx={() => ({
              display: "flex",
              background: "gray",
              borderRadius: "4px",
              overflow: "hidden",
            })}
          >
            <MDBox
              sx={() => ({
                background: view === "timeGridWeek" ? "#192d3d" : "#2576B9",
                padding: "6px 12px",
                cursor: "pointer",
              })}
              onClick={() => {
                setView("timeGridWeek");
                calendarRef.current?.getApi().changeView("timeGridWeek");
                calendarRef.current?.getApi().render();
              }}
            >
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...noteTypography(breakpoints),
                  color: "#FFFFFF",
                })}
              >
                Week
              </MDTypography>
            </MDBox>
            <MDBox
              sx={() => ({
                background: view === "dayGridMonth" ? "#192d3d" : "#2576B9",
                padding: "6px 12px",
                cursor: "pointer",
              })}
              onClick={() => {
                setView("dayGridMonth");
                calendarRef.current?.getApi().changeView("dayGridMonth");
                calendarRef.current?.getApi().render();
              }}
            >
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...noteTypography(breakpoints),
                  color: "#FFFFFF",
                })}
              >
                Month
              </MDTypography>
            </MDBox>
          </MDBox>
        </InfoIcon>
      </MDBox>
      <MDBox
        sx={({ functions: { pxToRem } }) => ({
          overflowX: "auto",
          marginBottom: pxToRem(16),
        })}
      >
        <MDBox
          minWidth="100%"
          sx={({ breakpoints, functions: { pxToRem } }) => ({
            maxWidth: "100%",
            width: pxToRem(800),
            [breakpoints.down(1600)]: {
              width: pxToRem(600),
            },
          })}
        >
          <FullCalendar
            // <editor-fold desc="Calendar props"/>
            ref={calendarRef}
            viewDidMount={({ view }) => {
              setTitle(view.title);
              const start = view.activeStart;
              const end = view.activeEnd;
              handleChangeDate(
                moment(start).format("DD-MM-YYYY"),
                moment(end).format("DD-MM-YYYY")
              );
            }}
            eventClick={(info) => {
              setData(info.event);
              setOpen(true);
            }}
            height="auto"
            titleFormat={(info) => {
              const startDate = info.start.marker;
              const endDate = info.end?.marker;

              return `${moment(startDate).format("Do")} ${
                moment(startDate).format("MMMM") !== moment(endDate).format("MMMM")
                  ? moment(startDate).format("MMMM")
                  : ""
              } - ${moment(endDate).format("Do")} ${moment(endDate).format("MMMM")}`;
            }}
            plugins={[dayGridPlugin, timeGridPlugin]}
            timeZone="UTC"
            initialView={view}
            headerToolbar={false}
            allDaySlot={false}
            firstDay={1}
            scrollTimeReset={true}
            slotDuration="01:00:00"
            scrollTime="09:00:00"
            dayHeaderContent={({ date }) => {
              let event = [];
              for (let i in eventsByDay) {
                if (moment(i).isSame(moment(date), "day")) {
                  event = eventsByDay[i];
                  break;
                }
              }
              const isToday = moment(date).isSame(moment(), "day");
              const isWeekends = moment(date).clone().day() % 6 === 0;
              return (
                <MDBox sx={calendarDayHeader}>
                  <MDTypography
                    sx={(theme) =>
                      calendarDayTopHeader(theme, {
                        isToday,
                        isWeekends,
                      })
                    }
                  >
                    {moment(date).format("ddd")}
                  </MDTypography>
                  {view !== "dayGridMonth" ? (
                    <MDTypography
                      sx={(theme) =>
                        calendarDayBottomHeader(theme, {
                          isToday,
                          isWeekends,
                        })
                      }
                    >
                      {moment(date).format("DD")}
                    </MDTypography>
                  ) : null}
                  {/*{event.length ? <Deadline numOfDeadlines={event.length} /> : <></>}*/}
                </MDBox>
              );
            }}
            slotLabelContent={({ date }) => {
              return (
                <MDTypography sx={calendarSlotLabel}>
                  {moment(date).utc().format("H:mm")}
                </MDTypography>
              );
            }}
            events={loading ? [] : [...events]}
            eventContent={(arg) => {
              const time =
                arg.event.extendedProps.deadline || moment(arg?.event?.start).add(-7, "h");
              return (
                <MDBox
                  sx={{
                    width: "100%",
                    backgroundColor: `${arg.backgroundColor}`,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    padding: "2px 6px",
                  }}
                >
                  <MDBox>
                    <MDTypography sx={({ breakpoints }) => ({ ...noteTypography(breakpoints) })}>
                      {moment(time).format("HH:mm")}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    sx={({ breakpoints }) => ({
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      ...descriptionTypography(breakpoints),
                    })}
                  >
                    {arg.event.title}
                  </MDBox>
                </MDBox>
              );
            }}
            // </editor-fold />
          />
        </MDBox>
      </MDBox>
      {data?.extendedProps?.type === "Training/Workshop" ? (
        <ModalWorkShop
          isOpen={isOpen}
          onClose={() => {
            setOpen(false);
            setData({});
          }}
          data={data}
        />
      ) : data?.extendedProps?.type === TypeClassSession.STUDENTS_AT_RISK ? (
        <ModalMyCalendarStudentAtRisk
          isOpen={isOpen}
          setOpen={setOpen}
          icon={modalCalendarIcon}
          onClose={() => {
            setOpen(false);
            setData({});
          }}
          dataExtendedProps={data?.extendedProps}
          dispatch={dispatch}
        />
      ) : (
        <Modal
          open={isOpen}
          onClose={() => {
            setOpen(false);
            setData({});
          }}
        >
          <MDBox sx={{ ...style }}>
            <MDBox display="flex" gap={1} alignItems="center" mb={3}>
              <MDBox
                sx={{
                  width: 48,
                  height: 48,
                  background: "#2576B9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <MDBox sx={{ width: 29 }} component="img" src={modalCalendarIcon}></MDBox>
              </MDBox>
              <MDBox>
                <MDTypography
                  sx={({ breakpoints }) => ({
                    ...titleTypography(breakpoints),
                    lineHeight: 1.37,
                  })}
                >
                  Details
                </MDTypography>
                <MDTypography
                  sx={({ breakpoints }) => ({
                    ...noteTypography(breakpoints),
                    lineHeight: 1.5,
                  })}
                >
                  Type: {data?.extendedProps?.type}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: 1,
              }}
            >
              {
                <>
                  {data?.extendedProps?.data?.className ? (
                    <MDTypography
                      sx={({ breakpoints }) => ({
                        ...descriptionTypography(breakpoints),
                        lineHeight: 1.5,
                      })}
                    >
                      Class name: {data?.extendedProps?.data?.className}
                    </MDTypography>
                  ) : null}
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...descriptionTypography(breakpoints),
                      lineHeight: 1.5,
                    })}
                  >
                    {data?.extendedProps?.type === "Lesson"
                      ? `Center name: ${data?.extendedProps?.data?.centerName ?? ""}`
                      : ""}
                    {data?.extendedProps?.type === "Feedback"
                      ? `Feedback subject: ${data?.extendedProps?.data?.feedBackSubject ?? ""}`
                      : ""}
                    {/* {data?.extendedProps?.type === "PT/Demo"
                ? `Center name: ${data?.extendedProps?.data?.centerName ?? ""}`
                : ""} */}
                    {data?.extendedProps?.type === "Daily Report"
                      ? `Session name: ${data?.extendedProps?.data?.sessionName ?? ""}`
                      : ""}
                    {data?.extendedProps?.type === "Progress Report" ? (
                      <MDBox
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <p>Class name: {data?.extendedProps?.progressReportsClassName ?? ""}</p>
                        <p>Number of reports: {data?.extendedProps?.data.length ?? 0}</p>
                      </MDBox>
                    ) : (
                      ""
                    )}
                    {data?.extendedProps?.type === "Gradebook Report" ? (
                      <MDBox
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <p>Class name: {data?.extendedProps?.gradeBookReportsClassName ?? ""}</p>
                        <p>Number of reports: {data?.extendedProps?.data.length ?? 0}</p>
                      </MDBox>
                    ) : (
                      ""
                    )}
                    {data?.extendedProps?.type === "EOCC & Test"
                      ? `Event: ${data?.extendedProps?.data?.event ?? ""}`
                      : ""}
                  </MDTypography>
                  {data?.extendedProps?.deadline ? (
                    <MDTypography
                      sx={({ breakpoints }) => ({
                        ...descriptionTypography(breakpoints),
                        lineHeight: 1.5,
                      })}
                    >
                      Deadline: {moment(data?.extendedProps?.deadline).format("DD-MM-YYYY HH:mm")}
                    </MDTypography>
                  ) : data?.extendedProps?.type === "PT/Demo" ? (
                    <>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...descriptionTypography(breakpoints),
                          lineHeight: 1.5,
                        })}
                      >
                        Center: {data?.extendedProps?.data?.centerName}
                      </MDTypography>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...descriptionTypography(breakpoints),
                          lineHeight: 1.5,
                        })}
                      >
                        Event: {data?.extendedProps?.data?.eventName}
                      </MDTypography>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...descriptionTypography(breakpoints),
                          lineHeight: 1.5,
                        })}
                      >
                        Event Type: {data?.extendedProps?.data?.eventTypeName}
                      </MDTypography>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...descriptionTypography(breakpoints),
                          lineHeight: 1.5,
                        })}
                      >
                        Teacher: {data?.extendedProps?.data?.fullNameEN}
                      </MDTypography>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...descriptionTypography(breakpoints),
                          lineHeight: 1.5,
                        })}
                      >
                        Start time: {moment(data?.start).add(-7, "h").format("DD-MM-YYYY HH:mm")}
                      </MDTypography>
                      {data?.end ? (
                        <MDTypography
                          sx={({ breakpoints }) => ({
                            ...descriptionTypography(breakpoints),
                            lineHeight: 1.5,
                          })}
                        >
                          End time: {moment(data?.end).add(-7, "h").format("DD-MM-YYYY HH:mm")}
                        </MDTypography>
                      ) : null}
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...descriptionTypography(breakpoints),
                          lineHeight: 1.5,
                        })}
                      >
                        Number of appointments: {data.extendedProps.data.appointment}
                      </MDTypography>
                    </>
                  ) : (
                    <>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...descriptionTypography(breakpoints),
                          lineHeight: 1.5,
                        })}
                      >
                        Start time: {moment(data?.start).add(-7, "h").format("DD-MM-YYYY HH:mm")}
                      </MDTypography>
                      {data?.end ? (
                        <MDTypography
                          sx={({ breakpoints }) => ({
                            ...descriptionTypography(breakpoints),
                            lineHeight: 1.5,
                          })}
                        >
                          End time: {moment(data?.end).add(-7, "h").format("DD-MM-YYYY HH:mm")}
                        </MDTypography>
                      ) : null}
                    </>
                  )}
                </>
              }
            </MDBox>
            <MDBox
              sx={{
                mt: 3,
                color: "#FFFFFF",
                fontFamily: "Lexend, san-serif",
                textTransform: "unset",
                width: "350px",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
              role={undefined}
              tabIndex={-1}
            >
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  color: "#FFFFFF",
                  backgroundColor: "#2576B9",
                  fontFamily: "Lexend, san-serif",
                  textTransform: "unset",
                  width: "-webkit-fill-available",
                }}
                role={undefined}
                tabIndex={-1}
                onClick={() => {
                  const type = data?.extendedProps?.type;
                  const item = data?.extendedProps?.data;
                  if (
                    type === "EOCC & Test" ||
                    type === "Lesson" ||
                    type === "PT/Demo" ||
                    type === "Training"
                  ) {
                    setOpen(false);
                    return;
                  }

                  let itemNew = item;
                  if (item && item.length) {
                    itemNew = item[0];
                  }
                  const id =
                    type === "Feedback"
                      ? itemNew?.feedback?.feedBackId
                      : type === TypeClassSession.STUDENTS_AT_RISK
                      ? itemNew?.id
                      : type === "Daily Report"
                      ? itemNew.dailyReportId
                      : type === "Progress Report"
                      ? itemNew.progressReportId
                      : type === "Gradebook Report"
                      ? itemNew.gradeBookReportId
                      : null;
                  navigate(
                    `/assistant/my-tasks?id=${id}&start=${
                      data?.start ? moment(data?.start).add(-7, "h").format("MM-DD-YYYY") : ""
                    }&end=${
                      data?.end ? moment(data?.end).add(-7, "h").format("MM-DD-YYYY") : ""
                    }&type=${type}`
                  );
                }}
              >
                {data?.extendedProps?.type === "EOCC & Test" ||
                data?.extendedProps?.type === "Lesson" ||
                data?.extendedProps?.type === "PT/Demo"
                  ? "OK"
                  : "Go to To-do list"}
              </Button>
              {data?.extendedProps?.type === "Lesson" ? (
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    color: "#FFFFFF",
                    backgroundColor: "#2576B9",
                    fontFamily: "Lexend, san-serif",
                    textTransform: "unset",
                    width: "-webkit-fill-available",
                  }}
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  onClick={async () => {
                    const res = await trigger({
                      lessonCode: data.extendedProps.data.id,
                    });
                    if (res?.data?.data?.url) {
                      window.open(res?.data?.data?.url, "_blank");
                    } else {
                      toast.error("Can't open LMS. Please try again", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        style: {
                          fontFamily: "Lexend",
                          fontSize: 14,
                        },
                      });
                    }
                  }}
                >
                  GO TO LMS
                </Button>
              ) : data?.extendedProps?.type === "PT/Demo" ? (
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    color: "#FFFFFF",
                    backgroundColor: "#2576B9",
                    fontFamily: "Lexend, san-serif",
                    textTransform: "unset",
                    width: "-webkit-fill-available",
                  }}
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  onClick={async () => {
                    const res = await getHub({
                      redirectURL: `http://hub24.apollo.vn:7777/event/ptonline`,
                    });
                    const _data = res?.data?.data;
                    if (_data) {
                      window.open(_data, "_blank");
                    }
                  }}
                >
                  GO TO HUB
                </Button>
              ) : data?.extendedProps?.type !== "EOCC & Test" &&
                data?.extendedProps?.type !== "Training" ? (
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    color: "#FFFFFF",
                    backgroundColor: "#2576B9",
                    fontFamily: "Lexend, san-serif",
                    textTransform: "unset",
                    width: "-webkit-fill-available",
                  }}
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  onClick={async () => {
                    setOpen(false);
                  }}
                >
                  OK
                </Button>
              ) : null}
            </MDBox>
          </MDBox>
        </Modal>
      )}
    </MDBox>
  );
}

export default WeeklyCalendar;
