import React, { useMemo } from "react";
import MDBox from "../MDBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Loading } from "shared/components/ui/Loading";
import { Grid } from "@mui/material";
import PageSize from "../UIDataTable/PageSize";
import RequestTutoringButton from "../Button/ButtonRequestTutoring";

export interface ITutoringRecordProps {
  isLoading: boolean;
  rows: any[];
  currentPage: number;
  pageSize: number;
  totalPage: number;
  totalElement: number;
  onChangePage: (event: any, value: number) => void;
  onChangePageSize: (event: any) => void;
}

const TutoringRecord = (props: ITutoringRecordProps) => {
  const {
    isLoading,
    rows,
    currentPage,
    pageSize,
    totalPage,
    totalElement,
    onChangePage,
    onChangePageSize,
  } = props;
  const columns = useMemo(() => [
    { id: "id" },
    { id: "centerCode", label: "Center code", minWidth: 120 },
    { id: "timestamp", label: "Timestamp", minWidth: 120 },
    { id: "typeOfTutoring", label: "Type of tutoring", minWidth: 200 },
    { id: "studentName", label: "Student's name", minWidth: 150 },
    { id: "studentCode", label: "Student code", minWidth: 150 },
    { id: "className", label: "Class name", minWidth: 150 },
    { id: "classLevel", label: "Class level", minWidth: 120 },
    { id: "emailAddress", label: "Requester", minWidth: 250 },
    { id: "unitToReview", label: "Unit to review", minWidth: 150 },
    { id: "requestConfirmation", label: "Request confirmation", minWidth: 150 },
    { id: "tutoringProgress", label: "Tutoring progress", minWidth: 150 },
    { id: "tutoringActivities", label: "TA's Tutoring activities", minWidth: 200 },
    {
      id: "achievement",
      label: () => (
        <>
          Achievement
          <br />
          The student met the session objectives
        </>
      ),
      minWidth: 350,
    },
    { id: "engagement", label: "Engagement", minWidth: 150 },
    { id: "tutoringResult", label: "Tutoring result", minWidth: 150 },
    { id: "taRecommendation", label: "TA recommendation", minWidth: 200 },
    {
      id: "taDetailedFeedback",
      label: () => (
        <>
          TA Detailed Feedback
          <br />
          (Free writing)
        </>
      ),
      minWidth: 700,
    },
  ]);
  return (
    <MDBox className="tutoring-record-container">
      <h2 className="heading-title">Tutoring Record</h2>
      <MDBox className="tutoring-record-table-container">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MDBox className="page-size-select-container">
              <PageSize pageSize={pageSize} onChangePageSize={onChangePageSize} />
            </MDBox>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <MDBox style={{ marginRight: 10 }}>
              <RequestTutoringButton
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSfrqwLaDdR-I7dpYIA8egx9LFy7PPxJbx_mgEjSMLEIqlg5uA/viewform",
                    "_blank"
                  );
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox className="table-container">
          <TableContainer
            sx={{ minHeight: isLoading ? 300 : undefined, maxHeight: 440, position: "relative" }}
          >
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <Loading style={{ width: 150, height: 100 }} />
              </div>
            )}

            <Table stickyHeader aria-label="sticky table">
              <TableHead className="table-header">
                <TableRow>
                  {columns
                    .filter((column) => column.id !== "id")
                    .map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {typeof column.label === "function" ? column.label() : column.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              {!isLoading && rows && rows.length > 0 && (
                <TableBody>
                  {rows.map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns
                        .filter((column) => column.id !== "id")
                        .map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </MDBox>
        {rows && rows.length > 0 && (
          <MDBox className="page-number-container">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography className="page-number-description">
                Showing {(currentPage - 1) * pageSize + 1} to{" "}
                {Math.min(currentPage * pageSize, totalElement)} of {totalElement} entries
              </Typography>
              <Pagination
                count={totalPage}
                page={currentPage}
                onChange={onChangePage}
                className="page-number-pagination"
              />
            </Stack>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default TutoringRecord;
