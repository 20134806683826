import React, { useState } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

const Search = ({ searchText, onSearch }) => {
  const [query, setQuery] = useState(searchText || "");

  const handleSearch = () => {
    onSearch(query.trim());
  };

  const onChange = (e) => {
    setQuery(e.target.value);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <MDBox className="search-container">
      <MDInput
        placeholder="Search here..."
        value={query}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </MDBox>
  );
};

export default Search;
