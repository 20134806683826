import Card from "@mui/material/Card";
import AccountLocked from "assets/images/apollo-english/account-locked.png";
import { Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
  padding: 6,
  maxWidth: "100%",
};

const ModalAccountLockedCard = ({ isOpenModalAccountLock, onClose }) => {
  return (
    <Modal open={isOpenModalAccountLock} onClose={onClose}>
      <Card className="modal-account-locked-card-container" sx={{ ...style }}>
        <img src={AccountLocked} alt="AccountLocked" />
        <h2>Account locked!</h2>
        <p className="error">
          ⚠️ You’ve entered the wrong PIN 3 times. For security reasons, your account has been
          temporarily disabled. Please contact your administrator or IT support to regain access.
        </p>
      </Card>
    </Modal>
  );
};

export default ModalAccountLockedCard;
