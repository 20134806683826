import { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import { headerStyles, mySelfStyles } from "./styles/myself";
import { PersonalInfomation } from "./components/PersonalInformation";
import { LabourInfomation } from "./components/LabourInformation";
import { LeaveSystem } from "./components/LeaveSystem";
import TagStatusPin from "components/TagStatusPin/TagStatusPin";
import { IMenuTabsProps, MenuTabs } from "./components/MenuTabs";
import MDBox from "components/MDBox";
import { useMySelfAPIs } from "shared/redux/endpoints/endPointMySelf";
import YourPinCode, { IYourPinCodeProps } from "components/YourPinCode/YourPinCode";
import { localStorageMySelfEnum, setIsLocked } from "shared/redux/slices/sliceMySelf";
import { useDispatch, useSelector } from "react-redux";
import PinInputCard, { PinInputCardProps } from "components/PinInputCard/PinInputCard";
import { ToastUtil } from "utilities/toastUtil";
import type { Error400, Success200 } from "utilities/toastUtil";
import { clearAllNotification } from "shared/redux/slices/notificationSlice";
import { Loading } from "shared/components/ui/Loading";

import {
  ChangePin,
  COUNT_ATTEMPTS_LEFT,
  CreatePin,
  FlagMySelf,
  VerifyPin,
} from "model/MySelfContainer.model";
import type { ITagStatusPinProps } from "components/TagStatusPin/TagStatusPin";
import { logOut } from "shared/redux/slices/authSlice";

const MySelfContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const email = user.email;

  const [selectedTab, setSelectedTab] = useState(0);
  const [createPin, setCreatePin] = useState(new CreatePin());
  const [changePin, setChangePin] = useState(new ChangePin());
  const [verifyPin, setVerifyPin] = useState(new VerifyPin());
  const [flagMySelt, setFlagMySelt] = useState(new FlagMySelf());

  // API
  const getMySelf = useMySelfAPIs.useGetMySelfWithCallbacks();
  const postCreatePin = useMySelfAPIs.usePostCreatePinWithCallbacks();
  const putUpdatePin = useMySelfAPIs.usePutUpdatePinWithCallbacks();
  const postVerifyPin = useMySelfAPIs.usePostVerifyPinWithCallbacks();
  const postResetPin = useMySelfAPIs.usePostResetPinWithCallbacks();
  const postLockAccount = useMySelfAPIs.usePostLockAccountWithCallbacks();

  const callGetMySelf = useCallback(async () => {
    await getMySelf(
      {},
      undefined,
      (response: Success200) => {
        setFlagMySelt((prevState) => {
          return {
            ...prevState,
            isEnablePin: response?.data?.isEnablePin || false,
            pinCodeHide: response?.data?.pinCodeHide || "",
          };
        });
      },
      (error: Error400) => {
        ToastUtil.showToastError400(error);
      }
    );
  }, [getMySelf]);

  const callCreatePin = useCallback(
    async (createPin: CreatePin) => {
      const body = { pinCode: createPin.pinSetup, confirmPinCode: createPin.confirmPinSetup };
      await postCreatePin(
        body,
        () => {
          setCreatePin((prevState) => {
            return { ...prevState, error: "" };
          });
        },
        async (response: Success200) => {
          setCreatePin(new VerifyPin());
          await callGetMySelf();
          ToastUtil.showToastSuccess200(response);
        },
        (error: Error400) => {
          ToastUtil.showToastError400(error);
        }
      );
    },
    [createPin]
  );

  const callChangePin = useCallback(
    async (changePin: ChangePin) => {
      const body = { pinCodeNew: changePin.pinChange };
      await putUpdatePin(
        body,
        () => {
          setChangePin((prevState) => {
            return { ...prevState, error: "" };
          });
        },
        async (response: Success200) => {
          setVerifyPin(new VerifyPin());
          setChangePin(new ChangePin());
          await callGetMySelf();
          ToastUtil.showToastSuccess200(response);
        },
        (error: Error400) => {
          ToastUtil.showToastError400(error);
        }
      );
    },
    [changePin]
  );

  const callPostVerifyPin = useCallback(async (pinCode: String) => {
    const body = { pinCode: pinCode };
    await postVerifyPin(
      body,
      undefined,
      (response: Success200) => {
        setVerifyPin((prevState) => {
          return {
            ...prevState,
            isPinVerified: true,
            attemptsLeft: COUNT_ATTEMPTS_LEFT,
            error: "",
          };
        });
        ToastUtil.showToastSuccess200(response);
      },
      (error: Error400) => {
        setVerifyPin((prevState) => {
          return {
            ...prevState,
            error: error?.data?.data?.message,
            attemptsLeft: prevState.attemptsLeft - 1,
          };
        });
        ToastUtil.showToastError400(error);
      }
    );
  }, []);

  const callResetPin = useCallback(async () => {
    await postResetPin(
      {},
      () => {},
      (response: Success200) => {
        setVerifyPin((prevState) => {
          return { ...prevState, resetClicked: true };
        });
        ToastUtil.showToastSuccess200(response);
      },
      (error: Error400) => {
        ToastUtil.showToastError400(error);
      }
    );
  }, []);

  const callPostLockAccount = useCallback(async () => {
    await postLockAccount(
      {},
      undefined,
      (response: Success200) => {
        ToastUtil.showToastSuccess200(response);
      },
      (error: Error400) => {
        ToastUtil.showToastError400(error);
      }
    );
  }, [postLockAccount]);

  useEffect(() => {
    const storedAttemptsLeft = localStorage.getItem(localStorageMySelfEnum.ATTEMPTS_LEFT + email);
    let attemptsLeft = storedAttemptsLeft ? Number(storedAttemptsLeft) : COUNT_ATTEMPTS_LEFT;
    setVerifyPin((prevState) => {
      return { ...prevState, isPinVerified: false, attemptsLeft };
    });

    const fetchData = async () => {
      await callGetMySelf();
      setFlagMySelt((prevState) => {
        return { ...prevState, isPageLoading: false };
      });
    };
    fetchData().then();
  }, []);

  useEffect(() => {
    if (!flagMySelt.isEnablePin) {
      setSelectedTab(5);
    } else {
      setSelectedTab(0);
    }
  }, [flagMySelt.isEnablePin]);

  useEffect(() => {
    localStorage.setItem(localStorageMySelfEnum.ATTEMPTS_LEFT + email, verifyPin.attemptsLeft);
    if (verifyPin.attemptsLeft <= 0) {
      const x = async () => {
        await callPostLockAccount();
      };
      x().then();
      setFlagMySelt((prevState) => {
        return { ...prevState, isLocked: true };
      });
    }
  }, [verifyPin.attemptsLeft]);

  useEffect(() => {
    if (flagMySelt.isLocked) {
      localStorage.removeItem(localStorageMySelfEnum.ATTEMPTS_LEFT + email);
      localStorage.removeItem(localStorageMySelfEnum.PIN_VERIFIED_TIME + email);
      dispatch(clearAllNotification());
      dispatch(setIsLocked(true));
      dispatch(logOut());
    }
  }, [flagMySelt.isLocked]);

  const propertiesMenuTabs: IMenuTabsProps = {
    selectedTab,
    onClickTab: (index: number) => {
      if (flagMySelt.isEnablePin) {
        setSelectedTab(index);
      }
    },
  };

  const propertiesTagStatusPin: ITagStatusPinProps = {
    isEnablePin: flagMySelt.isEnablePin,
  };

  const propertiesPinInputCard: PinInputCardProps = {
    verifyPin,
    setVerifyPin,
    onPinVerification: async (pinCode: string) => {
      await callPostVerifyPin(pinCode);
    },
    onResetPin: async () => {
      await callResetPin();
    },
  };

  const propertiesYourPinCode: IYourPinCodeProps = {
    createPin,
    setCreatePin,
    changePin,
    setChangePin,
    isEnablePin: flagMySelt.isEnablePin,
    pinCodeHide: flagMySelt.pinCodeHide,
    onCreatePin: async (createPin: CreatePin) => {
      await callCreatePin(createPin);
    },
    onChangePin: async (changePin: ChangePin) => {
      await callChangePin(changePin);
    },
  };

  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={(theme) => mySelfStyles(theme)}>
        {flagMySelt.isPageLoading ? (
          <Loading style={{ height: 200, margin: "10% auto" }} />
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              columnSpacing={3}
              sx={(theme) => headerStyles(theme)}
            >
              <MenuTabs {...propertiesMenuTabs} />
              <TagStatusPin {...propertiesTagStatusPin} />
            </Grid>

            {flagMySelt.isEnablePin && !verifyPin.isPinVerified ? (
              <PinInputCard {...propertiesPinInputCard} />
            ) : (
              <>
                {selectedTab === 0 && <PersonalInfomation />}
                {selectedTab === 1 && <LabourInfomation />}
                {selectedTab === 2 && <LeaveSystem />}
                {selectedTab === 5 && <YourPinCode {...propertiesYourPinCode} />}
              </>
            )}
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
};
export default MySelfContainer;
