import moment from "moment-timezone";

export const Timezone = {
  Singapore: "Asia/Singapore",
};

/**
 * Converts a duration in seconds into a formatted string of hours, minutes, and seconds.
 * @param {number} seconds - The number of seconds to convert.
 * @returns {string} - A formatted string representing the duration (e.g., "X hours and Y minutes").
 */
export const formatDuration = (seconds: number) => {
  if (typeof seconds !== "number" || seconds < 0) {
    throw new Error("Input must be a non-negative number");
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours === 0 && minutes === 0) {
    return `${remainingSeconds} seconds`;
  }

  if (hours === 0) {
    return `${minutes} minutes`;
  }

  if (minutes === 0) {
    return `${hours} hours`;
  }

  return `${hours} hours and ${minutes} minutes`;
};

/**
 * Format a given time to "DD MMMM YYYY, [at] HH:mm A" with a specific timezone.
 * @param {string|Date} time - The time to format (can be a string or Date object).
 * @param {string} timeZone - The timezone to apply (e.g., "Asia/Ho_Chi_Minh").
 * @returns {string} - The formatted time string.
 */
export const formatTimeDefault = (time: string, timeZone: Timezone) => {
  if (!time || !timeZone) {
    throw new Error('Both "time" and "timeZone" are required parameters');
  }

  const defaultFormat = "DD MMMM YYYY, [at] HH:mm A";
  return moment(time).tz(timeZone).format(defaultFormat);
};

/**
 * Format a given time to "DD MMMM YYYY, [at] HH:mm A" with a specific timezone.
 * @param {string|Date} time - The time to format (can be a string or Date object).
 * @param {string} timeZone - The timezone to apply (e.g., "Asia/Ho_Chi_Minh").
 * @returns {string} - The formatted time string.
 */
export const formatTime = (time: string) => {
  const defaultFormat = "DD MMMM YYYY, [at] HH:mm A";
  return moment(time).format(defaultFormat);
};

export const formatTimeWith = (time: string, format) => {
  return moment(time).format(format);
};
