import { FaSpinner } from "react-icons/fa";
import { useSelector } from "react-redux";

export interface ITagStatusPinProps {
  isEnablePin: boolean;
}

const TagStatusPin = (props: ITagStatusPinProps) => {
  const { isLoading } = useSelector((state) => state.mySelf.callGetMySelf);
  const { isEnablePin } = props;
  const text = isEnablePin ? "Pin code enabled" : "Pin code disabled";
  return (
    <div className="tag-status-pin">
      {isLoading ? (
        <span className="tag-status-pin-loading">
          <FaSpinner className="spinner-icon" />
        </span>
      ) : isEnablePin ? (
        <span className="tag-status-pin-enabled">{text}</span>
      ) : (
        <span className="tag-status-pin-unabled">{text}</span>
      )}
    </div>
  );
};

export default TagStatusPin;
