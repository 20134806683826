import { FaSpinner } from "react-icons/fa";
import type { CreatePin } from "model/MySelfContainer.model";
import MDBox from "components/MDBox";

export interface IPinCodeSetupFormProps {
  createPin: CreatePin;
  handlePinSetup: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPinSetup: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoadingSubmit: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const PinCodeSetupForm = (props: IPinCodeSetupFormProps) => {
  const { createPin, handlePinSetup, handleConfirmPinSetup, isLoadingSubmit, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} className="pin-setup-form">
      <div className="pin-setup-field">
        <label htmlFor="pin" className="pin-setup-label">
          Enter your PIN code here
        </label>
        <input
          id="pin"
          value={createPin.pinSetup}
          onChange={handlePinSetup}
          required
          maxLength="4"
          placeholder="Numbers only (4 digits)"
          className="pin-setup-input"
          pattern="\d*"
        />
      </div>
      <div className="pin-setup-field">
        <label htmlFor="confirmPin" className="pin-setup-label">
          Confirm your PIN code again
        </label>
        <input
          id="confirmPin"
          value={createPin.confirmPinSetup}
          onChange={handleConfirmPinSetup}
          required
          maxLength="4"
          placeholder="Numbers only (4 digits)"
          className="pin-setup-input"
        />
      </div>

      <MDBox style={{ display: "flex" }}>
        <button type="submit" className="pin-setup-button" disabled={isLoadingSubmit}>
          {isLoadingSubmit ? (
            <>
              <FaSpinner className="spinner-icon" /> Saving...
            </>
          ) : (
            "Save"
          )}
        </button>
        {createPin.error && <p className="pin-setup-error">{createPin.error}</p>}
      </MDBox>
    </form>
  );
};

export default PinCodeSetupForm;
