import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";

const initialState = {
  callGetListLink: {
    data: [],
    isLoading: false,
    error: null,
    response: null,
  },
};

const sliceHomePage = createSlice({
  name: "home-page",
  initialState,
  extraReducers: (builder) => {
    // putUpdatePin API handlers
    builder
      .addMatcher(api.endpoints.getListLink.matchPending, (state) => {
        state.callGetListLink.isLoading = true;
        state.callGetListLink.error = null;
      })
      .addMatcher(api.endpoints.getListLink.matchFulfilled, (state, action) => {
        state.callGetListLink.isLoading = false;
        state.callGetListLink.data = action.payload?.data || [];
        state.callGetListLink.response = action.payload || null;
      })
      .addMatcher(api.endpoints.getListLink.matchRejected, (state, action) => {
        state.callGetListLink.isLoading = false;
        state.callGetListLink.error = action.payload;
      });
  },
});

export default sliceHomePage.reducer;
