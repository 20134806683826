import { api } from "shared/redux/api";
import { useMutationWithCallbacks } from "../../hooks/useMutationWithCallbacks";
import { useLazyQueryWithCallbacks } from "../../hooks/useLazyQueryWithCallbacks";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMySelf: build.query({
      query: () => {
        return {
          url: `/private/user/v2/my-self`,
        };
      },
    }),
    postCreatePin: build.mutation({
      query: (arg) => ({
        url: "/private/user/v2/pin",
        method: "POST",
        body: arg,
      }),
    }),
    putUpdatePin: build.mutation({
      query: (arg) => ({
        url: "/private/user/v2/pin",
        method: "PUT",
        body: arg,
      }),
    }),
    postVerifyPin: build.mutation({
      query: (arg) => ({
        url: "/private/user/v2/verify-pin",
        method: "POST",
        body: arg,
      }),
    }),
    postResetPin: build.mutation({
      query: (arg) => ({
        url: "/private/user/v2/reset-pin",
        method: "POST",
        body: arg,
      }),
    }),
    postLockAccount: build.mutation({
      query: (arg) => ({
        url: "/private/user/v2/pin/account-locked",
        method: "POST",
        body: arg,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetMySelfQuery,
  usePostCreatePinMutation,
  usePutUpdatePinMutation,
  usePostVerifyPinMutation,
  usePostResetPinMutation,
  usePostLockAccountMutation,
} = injectedRtkApi;

export const useMySelfAPIs = {
  useGetMySelfWithCallbacks: useLazyQueryWithCallbacks(useLazyGetMySelfQuery),
  usePostCreatePinWithCallbacks: useMutationWithCallbacks(usePostCreatePinMutation),
  usePutUpdatePinWithCallbacks: useMutationWithCallbacks(usePutUpdatePinMutation),
  usePostVerifyPinWithCallbacks: useMutationWithCallbacks(usePostVerifyPinMutation),
  usePostResetPinWithCallbacks: useMutationWithCallbacks(usePostResetPinMutation),
  usePostLockAccountWithCallbacks: useMutationWithCallbacks(usePostLockAccountMutation),
};
