import ColumnModel from "model/Column.model";
import MDBox from "../MDBox";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import { Loading } from "shared/components/ui/Loading";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import React from "react";
import { Grid } from "@mui/material";
import PageSize from "./PageSize";
import Search from "./Search";
import EnhancedTableHead from "./EnhancedTableHead";

export interface IUIDataTableProps {
  className?: string;
  isLoading: boolean;
  columns: ColumnModel[];
  rows: any[];
  currentPage: number;
  pageSize: number;
  search?: string;
  order?: string;
  orderBy?: string;
  totalPage: number;
  totalElement: number;
  onChangePage: (event: any, value: number) => void;
  onChangePageSize: (event: any) => void;
  onSearch?: (event: any) => void;
  onSort?: (event: any) => void;
  customTableHeader?: React.ReactNode;
  customActions?: (row: any) => React.ReactNode;
}

const UIDataTable = (props: IUIDataTableProps) => {
  let {
    className,
    isLoading,
    columns,
    rows,
    currentPage,
    pageSize,
    search,
    order,
    orderBy,
    totalPage,
    totalElement,
    onChangePage,
    onChangePageSize,
    onSearch,
    onSort,
    customTableHeader,
    customActions,
  } = props;
  currentPage = currentPage + 1;

  const handleRequestSort = (event: React.MouseEvent<>, property: string) => {
    if (onSort) {
      onSort(event, property);
    }
  };

  return (
    <MDBox className={`${className} ui-data-table-container`}>
      {customTableHeader ? (
        customTableHeader
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MDBox className="page-size-select-container">
              <PageSize pageSize={pageSize} onChangePageSize={onChangePageSize} />
            </MDBox>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Search searchText={search} onSearch={onSearch} />
          </Grid>
        </Grid>
      )}

      <MDBox className="table-container">
        <TableContainer
          sx={{ minHeight: isLoading ? 300 : undefined, maxHeight: 440, position: "relative" }}
        >
          {isLoading && (
            <MDBox className="loading-content-table">
              <Loading style={{ width: 150, height: 100 }} />
            </MDBox>
          )}

          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              columns={columns}
              order={order}
              orderBy={orderBy}
            />

            {!isLoading && rows && rows.length > 0 && (
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column, index) => {
                      const value = row[column.id];
                      if (customActions && column.id === "action") {
                        return (
                          <TableCell
                            align="center"
                            className="table-actions"
                            key={index}
                            style={column.styleCell}
                          >
                            {customActions(row)}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={index} style={column.styleCell}>
                          {column.format ? column.format(value, row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </MDBox>
      {rows && rows.length > 0 && (
        <MDBox className="page-number-container">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography className="page-number-description">
              Showing {(currentPage - 1) * pageSize + 1} to{" "}
              {Math.min(currentPage * pageSize, totalElement)} of {totalElement} entries
            </Typography>
            <Pagination
              count={totalPage}
              page={currentPage}
              onChange={onChangePage}
              className="page-number-pagination"
            />
          </Stack>
        </MDBox>
      )}
    </MDBox>
  );
};

export default UIDataTable;
