import type {
  Course,
  Organizer,
  Slot,
  WorkshopDataResult,
} from "../model/MyDevelopmentContainer.model";
import { WorkshopTabEnum } from "../data/my-development/MyDevelopment.data";
import type { WorkshopAvailableTableInterface } from "../components/WorkshopAvailableTable/WorkshopAvailableTable.interface";
import { formatDuration, formatTime } from "./times";
import { SVGIcon } from "../components/icons";

const _getDescription = (
  locationlink: string,
  location: string,
  date_start_time: string,
  trainer: string
) => {
  return (
    <div style={{ display: "inline-flex", alignItems: "center", gap: "4px", flexWrap: "wrap" }}>
      {locationlink ? (
        <SVGIcon name={"online_status"} width={10} height={12} />
      ) : (
        <SVGIcon name={"location"} width={10} height={12} fill={"#2376BC"} />
      )}

      {locationlink ? (
        <>
          <span>Online:</span>{" "}
          <strong>
            <a href={locationlink} target="_blank" rel="noreferrer">
              Link
            </a>
          </strong>{" "}
          {"-"} <span>{location}</span>
        </>
      ) : (
        <span>{location}</span>
      )}

      {date_start_time && (
        <span>
          - <strong>{formatTime(date_start_time)}</strong>
        </span>
      )}

      {trainer && (
        <span>
          by <strong>{trainer}</strong>
        </span>
      )}
    </div>
  );
};

const _getLocation = (locationlink: string, location: string) => {
  return (
    <div style={{ display: "inline-flex", alignItems: "center", gap: "4px", flexWrap: "wrap" }}>
      {locationlink ? (
        <>
          <strong>
            <a href={locationlink} target="_blank" rel="noreferrer">
              {location}
            </a>
          </strong>
        </>
      ) : (
        <span>{location}</span>
      )}
    </div>
  );
};

const getDataWorkShopForCalendar = (data, email) => {
  const courses = data.courses || [];
  const registeredSlots = [];
  courses.forEach((course) => {
    const organizers = course.organizers || [];

    organizers.forEach((organizer) => {
      const slots = organizer.slots || {};
      const registered = slots.registered || [];
      const name = organizer.name || "";
      const courseId = course.course_info.id || "";
      const organizerId = organizer.id || "";

      registered.forEach((slot) => {
        let isAttended = false;
        organizer.slots.registered.forEach((registered: Slot) => {
          if (!isAttended && registered && registered.registration_status?.has_attended) {
            isAttended = true;
          }
        });
        if (!isAttended) {
          let startTime = new Date(slot.start_time);
          startTime.setDate(startTime.getDate());
          let startTimestamp = startTime.getTime();

          registeredSlots.push({
            ...slot,
            id: slot.id,
            startTime: startTimestamp,
            endTime: startTimestamp + slot.duration * 1000,
            duration: slot.duration * 1000,
            eventTypeName: name,
            centerName: slot.location,
            trainers: slot.trainers,
            courseId,
            organizerId,
            email,
            deadline: startTimestamp + slot.duration * 1000,
          });
        }
      });
    });
  });

  const attendedSlots = [];
  courses.forEach((course) => {
    const organizers = course.organizers || [];
    organizers.forEach((organizer: Organizer) => {
      const name = organizer.name || "";
      const courseId = course.course_info.id || "";
      const organizerId = organizer.id || "";

      organizer.slots.registered.forEach((registered: Slot) => {
        if (registered && registered.registration_status?.has_attended) {
          let startTime = new Date(registered.start_time);
          startTime.setDate(startTime.getDate());
          let startTimestamp = startTime.getTime();
          attendedSlots.push({
            ...registered,
            id: registered.id,
            startTime: startTimestamp,
            endTime: startTimestamp + registered.duration * 1000,
            duration: registered.duration * 1000,
            eventTypeName: name,
            centerName: registered.location,
            trainers: registered.trainers,
            courseId,
            organizerId,
            email,
            deadline: startTimestamp + registered.duration * 1000,
          });
        }
      });

      organizer.slots.unregistered.forEach((unregistered: Slot) => {
        if (unregistered && unregistered.registration_status?.has_attended) {
          let startTime = new Date(unregistered.start_time);
          startTime.setDate(startTime.getDate());
          let startTimestamp = startTime.getTime();
          attendedSlots.push({
            ...unregistered,
            id: unregistered.id,
            startTime: startTimestamp,
            endTime: startTimestamp + unregistered.duration * 1000,
            duration: unregistered.duration * 1000,
            eventTypeName: name,
            centerName: unregistered.location,
            trainers: unregistered.trainers,
            courseId,
            organizerId,
            email,
            deadline: startTimestamp + unregistered.duration * 1000,
          });
        }
      });

      organizer.slots.old.forEach((old: Slot) => {
        if (old && old.registration_status?.has_attended) {
          let startTime = new Date(old.start_time);
          startTime.setDate(startTime.getDate());
          let startTimestamp = startTime.getTime();
          attendedSlots.push({
            ...old,
            id: old.id,
            startTime: startTimestamp,
            endTime: startTimestamp + old.duration * 1000,
            duration: old.duration * 1000,
            eventTypeName: name,
            centerName: old.location,
            trainers: old.trainers,
            courseId,
            organizerId,
            email,
            deadline: startTimestamp + old.duration * 1000,
          });
        }
      });
    });
  });

  return [...registeredSlots, ...attendedSlots];
};

const convertDataFormWeLearnAPI = (result: WorkshopDataResult, type: WorkshopTabEnum) => {
  const output = [];

  result.courses.forEach((course: Course) => {
    course.organizers.forEach((organizer: Organizer) => {
      const workshopData: WorkshopAvailableTableInterface = {
        id: organizer.id,
        courseId: course.course_info.id,
        organizerId: organizer.id,
        workshopName: organizer.name,
        duration: 0,
        description: <div dangerouslySetInnerHTML={{ __html: organizer.description || "" }}></div>,
      };

      if (type === WorkshopTabEnum.Available) {
        if (organizer.slots.unregistered.length > 0 && organizer.slots.registered.length === 0) {
          let isAttended = false;
          organizer.slots.unregistered.forEach((unregistered: Slot) => {
            if (!isAttended && unregistered && unregistered.registration_status?.has_attended) {
              isAttended = true;
            }
          });

          if (!isAttended) {
            output.push({
              ...workshopData,
              duration: formatDuration(organizer.slots.unregistered[0].duration),
            });
          }
        }
      }

      if (type === WorkshopTabEnum.Registered) {
        if (organizer.slots.registered.length > 0) {
          let isAttended = false;
          organizer.slots.registered.forEach((registered: Slot) => {
            if (!isAttended && registered && registered.registration_status?.has_attended) {
              isAttended = true;
            }
          });

          if (!isAttended) {
            output.push({
              ...workshopData,
              duration: formatDuration(organizer.slots.registered[0].duration),
              rowSub: organizer.slots.registered.map((slot: Slot) => ({
                id: slot.id,
                description: _getDescription(
                  slot.locationlink,
                  slot.location,
                  slot.start_time,
                  slot.trainers.join(", ")
                ),
              })),
            });
          }
        }
      }

      if (type === WorkshopTabEnum.Attended) {
        organizer.slots.registered.forEach((registered: Slot) => {
          if (registered && registered.registration_status?.has_attended) {
            output.push({
              ...workshopData,
              duration: formatDuration(registered.duration),
              date_time_start: formatTime(registered.start_time),
              location: _getLocation(
                registered.locationlink,
                registered.location,
                registered.start_time,
                registered.trainers.join(", ")
              ),
              locationlink: registered.locationlink,
              trainer: registered.trainers.join(", "),
            });
          }
        });

        organizer.slots.unregistered.forEach((unregistered: Slot) => {
          if (unregistered && unregistered.registration_status?.has_attended) {
            output.push({
              ...workshopData,
              duration: formatDuration(unregistered.duration),
              date_time_start: formatTime(unregistered.start_time),
              location: unregistered.location,
              locationlink: unregistered.locationlink,
              trainer: unregistered.trainers.join(", "),
            });
          }
        });

        organizer.slots.old.forEach((old: Slot) => {
          if (old && old.registration_status?.has_attended) {
            output.push({
              ...workshopData,
              duration: formatDuration(old.duration),
              date_time_start: formatTime(old.start_time),
              location: old.location,
              locationlink: old.locationlink,
              trainer: old.trainers.join(", "),
            });
          }
        });
      }
    });
  });

  return output;
};

export const WorkshopUtil = {
  convertDataFormWeLearnAPI,
  getDataWorkShopForCalendar,
};
