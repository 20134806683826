import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";

const basePageApi = {
  data: [],
  currentPage: 0,
  pageSize: 5,
  totalPage: 0,
  totalElement: 0,
  isLoading: false,
  error: null,
};

const initialState = {
  callGetPageStudentTutoringRecord: basePageApi,
};

const sliceMyAssistant = createSlice({
  name: "myAssistant",
  initialState,
  reducers: {
    clearAllMySelf: () => initialState,
  },

  extraReducers: (builder) => {
    // getPageStudentTutoringRecord API handlers
    builder
      .addMatcher(api.endpoints.getPageStudentTutoringRecord.matchPending, (state) => {
        state.callGetPageStudentTutoringRecord.isLoading = true;
        state.callGetPageStudentTutoringRecord.error = null;
      })
      .addMatcher(api.endpoints.getPageStudentTutoringRecord.matchFulfilled, (state, action) => {
        state.callGetPageStudentTutoringRecord.isLoading = false;
        state.callGetPageStudentTutoringRecord.data = action.payload?.data?.data || [];
        state.callGetPageStudentTutoringRecord.pageSize = action.payload?.data?.pageSize || 0;
        state.callGetPageStudentTutoringRecord.totalPage = action.payload?.data?.totalPage || 0;
        state.callGetPageStudentTutoringRecord.totalElement =
          action.payload?.data?.totalElement || 0;
        state.callGetPageStudentTutoringRecord.currentPage =
          action.payload?.data?.currentPage + 1 || 0;
      })
      .addMatcher(api.endpoints.getPageStudentTutoringRecord.matchRejected, (state, action) => {
        state.callGetPageStudentTutoringRecord.isLoading = false;
        state.callGetPageStudentTutoringRecord.error = action.payload;
      });
  },
});

export default sliceMyAssistant.reducer;
