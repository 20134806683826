import React, { useState, useRef } from "react";
import { CheckPicker } from "rsuite";
import MDBox from "../MDBox";

export interface IComboboxMutiCheckBoxProps {
  data: { label: string, value: string }[];
  placeholder?: string;
  defaultValue?: string[];
  onSelect?: (selectedValues: string[]) => void;
  onClean?: () => void;
  style?: React.CSSProperties;
  className?: string;
}

const ComboboxMutiCheckBox: React.FC<IComboboxMutiCheckBoxProps> = ({
  data,
  placeholder = "Select options",
  defaultValue = [],
  onSelect,
  onClean,
  style,
  className,
}) => {
  const pickerRef = useRef(null);
  const [selectedValues, setSelectedValues] = useState(defaultValue);

  const handleSelect = (value: string[]) => {
    setSelectedValues(value);
    if (onSelect) onSelect(value);
  };

  return (
    <MDBox className={`${className} custom-checkpicker`} style={style}>
      <CheckPicker
        searchable={false}
        data={data}
        placeholder={placeholder}
        ref={pickerRef}
        value={selectedValues}
        onSelect={handleSelect}
        onClean={() => {
          setSelectedValues([]);
          if (onClean) onClean();
        }}
      />
    </MDBox>
  );
};

export default ComboboxMutiCheckBox;
