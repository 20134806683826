import React, { useMemo, useState } from "react";
import UIDataTable from "../UIDataTable/UIDataTable";
import Card from "@mui/material/Card";
import { CardContent, Grid } from "@mui/material";
import MDBox from "../MDBox";
import { Button, DateRangePicker } from "rsuite";
import Stack from "@mui/material/Stack";
import { formatTimeWith } from "utilities/times";
import DialogObservationDetail from "../DialogObservationDetail/DialogObservationDetail";
import { DateUtil } from "utilities/dateUtil";
import PageSize from "../UIDataTable/PageSize";
import ComboboxMutiCheckBox from "../Combobox/ComboboxMutiCheckBox";
import { ListTypeObservation } from "constant/TypeObservation";
import { TableUtil } from "utilities/tableUtil";
import type { IUIDataTableProps } from "../UIDataTable/UIDataTable";

export interface ITabObservationProps {
  isLoading: boolean;
  rows: any[];
  currentPage: number;
  pageSize: number;
  totalPage: number;
  totalElement: number;
  filter: any;
  onChangePage: (event: any, value: number) => void;
  onChangePageSize: (event: any) => void;
  onCompletedSelectRangeDate: (date: any) => void;
  onCompletedSelectObservationType: (value: any) => void;
  detail: any;
  detailLoading: boolean;
  onOpenObservationDetail: (value: any) => void;
}

const TabObservation = (props: ITabObservationProps) => {
  const {
    isLoading,
    rows,
    currentPage,
    pageSize,
    totalPage,
    totalElement,
    filter,
    onChangePage,
    onChangePageSize,
    onCompletedSelectRangeDate,
    onCompletedSelectObservationType,
    detail,
    detailLoading,
    onOpenObservationDetail,
  } = props;

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const columns = useMemo(() => [
    {
      id: "observationType",
      label: "Observation Type",
      minWidth: 188,
      styleCell: { ...TableUtil.styleCell, whiteSpace: "nowrap" },
      format: (value) => {
        if (value) {
          return value.toUpperCase();
        }
        return value;
      },
    },
    {
      id: "observationName",
      label: "Observation Name",
      minWidth: 200,
      styleCell: { ...TableUtil.styleCell },
    },
    {
      id: "observationDate",
      label: "Date of observation",
      minWidth: 230,
      styleCell: { ...TableUtil.styleCell, fontWeight: 400 },
      format: (value) => formatTimeWith(value, "DD-MMM-YYYY"),
      align: "center",
    },
    {
      id: "observationCode",
      label: "Observation Code",
      minWidth: 250,
      styleCell: { ...TableUtil.styleCell, fontWeight: 400 },
    },
    { id: "action", label: "Action", minWidth: 150 },
  ]);

  const propertiesUIDataTable: IUIDataTableProps = {
    isLoading,
    columns,
    rows,
    currentPage,
    pageSize,
    totalPage,
    totalElement,
    onChangePage,
    onChangePageSize,
    customTableHeader: (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MDBox className="page-size-select-container">
            <PageSize pageSize={pageSize} onChangePageSize={onChangePageSize} />
          </MDBox>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <MDBox className="observation-date-range-picker">
            <DateRangePicker
              style={{ width: 240 }}
              value={filter.date}
              format="dd MMM, yyyy"
              placeholder="Select date range"
              ranges={DateUtil.predefinedRanges}
              onOk={onCompletedSelectRangeDate}
              onChange={onCompletedSelectRangeDate}
              onClean={onCompletedSelectRangeDate}
            />
          </MDBox>

          <ComboboxMutiCheckBox
            style={{ width: 450 }}
            className="observation-select-type-container"
            data={ListTypeObservation}
            placeholder="Select observation type"
            defaultValue={[]}
            onConfirm={onCompletedSelectObservationType}
            onClean={onCompletedSelectObservationType}
            onSelect={onCompletedSelectObservationType}
          />
        </Grid>
      </Grid>
    ),
    customActions: (row) => (
      <Stack direction="row" spacing={2}>
        <Button
          className="action-button view-details"
          onClick={async () => {
            await onOpenObservationDetail(row.id, row.isOnline);
            setIsOpenDialog(true);
          }}
        >
          View details
        </Button>
        <Button
          className="action-button verify"
          onClick={() => {
            const url =
              "https://docs.google.com/forms/d/e/1FAIpQLSdXiEabBBVG6LpT17qTyqT3xHpfCjDyER8BNJPkqVqfcyf93g/viewform";
            window.open(url, "_blank");
          }}
        >
          Verify
        </Button>
      </Stack>
    ),
  };
  return (
    <Card className="observation-tab-card">
      <CardContent className="observation-tab-card-content">
        <UIDataTable {...propertiesUIDataTable} />
        <DialogObservationDetail
          isOpen={isOpenDialog}
          detail={detail}
          detailLoading={detailLoading}
          onClose={() => setIsOpenDialog(false)}
        />
      </CardContent>
    </Card>
  );
};

export default TabObservation;
