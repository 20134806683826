import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLazyGetReportPercentQuery } from "shared/redux/endpoints/assistant";
import { useLazyGetTodoQuery } from "shared/redux/endpoints/assistant";
import { dataGetTodo } from "../data/dataResponse";
import { TypeClassSession } from "constant/TypeClassSession";

export const colorsMap = [
  {
    status: "New",
    color: "#5E72E4",
  },
  {
    status: "New overdue",
    color: "#8392AB",
  },
  {
    status: "Overdue",
    color: "#8392AB",
  },
  {
    status: "In progress",
    color: "#11CDEF",
  },
  {
    status: "In progress overdue",
    color: "#2DCE89",
  },
  {
    status: "Completed overdue",
    color: "#F5365C",
  },
  {
    status: "Completed on time",
    color: "#DF25FD",
  },
  {
    status: "Completed",
    color: "#DF25FD",
  },
  {
    status: "Cancelled",
    color: "#26355D",
  },
];

const tabs = ["All", "Report", "Feedback", "Other"];

const groupReport = (progressReport, gradeBookReport) => {
  const _progressReport = [];
  const _gradebookReport = [];

  (progressReport.data || []).forEach((item) => {
    const className = item.className;
    const progressReportEventIndex = _progressReport.findIndex(
      (item) => item.className === className
    );
    if (progressReportEventIndex === -1) {
      _progressReport.push({
        ...item,
        items: [item],
      });
    } else {
      _progressReport[progressReportEventIndex].items = [
        ..._progressReport[progressReportEventIndex].items,
        item,
      ];
    }
  });

  (gradeBookReport.data || []).forEach((item) => {
    const className = item.className;
    const gradeBookReportIndex = _gradebookReport.findIndex((item) => item.className === className);
    if (gradeBookReportIndex === -1) {
      _gradebookReport.push({
        ...item,
        items: [item],
      });
    } else {
      _gradebookReport[gradeBookReportIndex].items = [
        ..._gradebookReport[gradeBookReportIndex].items,
        item,
      ];
    }
  });

  return {
    _gradebookReport,
    _progressReport,
  };
};

export function capitalizeFirstLetter(string) {
  if (string === "NOT_COMPLETED") return "To do";
  return (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()).replace(/_/g, " ");
}

export const useTasks = () => {
  const startOfMonth = moment().startOf("month").format("MM-DD-YYYY");
  const endOfMonth = moment().endOf("month").format("MM-DD-YYYY");
  const [trigger, { isFetching }] = useLazyGetTodoQuery();
  const [triggerPercent, { data, isFetching: isFetchingPercent }] = useLazyGetReportPercentQuery();

  useEffect(() => {
    triggerPercent();
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  const [list, setList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(0);

  const [start, setStart] = useState(startOfMonth);
  const [end, setEnd] = useState(endOfMonth);

  const handleNext = () => setPage((_page) => page + 1);
  const handlePrev = () => setPage((_page) => page - 1);

  useEffect(() => {
    setPage(0);
  }, [tabIndex]);

  const fetchToto = async (page, start, end, tabIndex) => {
    setList([]);
    setTotalPage(0);
    const response = await trigger({
      page,
      size: tabIndex === 0 || tabIndex === 1 ? 100 : 10,
      start: moment(start).format("DD-MM-YYYY"),
      end: moment(end).format("DD-MM-YYYY"),
    });

    if (response?.isSuccess && response?.data?.status) {
      const { data } = response.data;
      // const data = dataGetTodo;
      if (tabIndex === 0) {
        const { feedback, dailyReport, progressReport, studentAtRisk, gradeBookReport } = data;

        const { _progressReport, _gradebookReport } = groupReport(progressReport, gradeBookReport);

        setTotalPage(0);
        setList([
          ...(dailyReport?.data.map((item) => ({ ...item, type: "Daily Report" })) ?? []),
          ...(_progressReport?.map((item) => ({ ...item, type: "Progress Report" })) ?? []),
          ...(feedback?.data.map((item) => ({ ...item, type: "Feedback" })) ?? []),
          ...(studentAtRisk?.data.map((item) => ({
            ...item,
            type: TypeClassSession.STUDENTS_AT_RISK,
          })) ?? []),
          ...(_gradebookReport?.map((item) => ({ ...item, type: "Gradebook Report" })) ?? []),
        ]);
      } else if (tabIndex === 1) {
        const { dailyReport, progressReport, gradeBookReport } = data;
        const { _progressReport, _gradebookReport } = groupReport(progressReport, gradeBookReport);
        setTotalPage(0);
        setList([
          ...(dailyReport?.data.map((item) => ({ ...item, type: "Daily Report" })) ?? []),
          ...(_progressReport?.map((item) => ({ ...item, type: "Progress Report" })) ?? []),
          ...(_gradebookReport?.map((item) => ({ ...item, type: "Gradebook Report" })) ?? []),
        ]);
      } else if (tabIndex === 2) {
        const { feedback } = data;
        setTotalPage(feedback?.totalPage ?? 0);
        setList(feedback?.data.map((item) => ({ ...item, type: "Feedback" })) || []);
      } else if (tabIndex === 3) {
        const { studentAtRisk = {} } = data;
        setTotalPage(studentAtRisk?.totalPage ?? 0);
        setList(
          studentAtRisk?.data.map((item) => ({
            ...item,
            type: TypeClassSession.STUDENTS_AT_RISK,
          })) || []
        );
      }
    }
  };

  useEffect(() => {
    if ((start && end) || (!start && !end)) {
      const fetchData = async () => {
        await fetchToto(page, start, end, tabIndex);
      };
      fetchData().then();
    }
  }, [page, start, end, tabIndex]);

  const refetch = async () => {
    if ((start && end) || (!start && !end)) {
      await fetchToto(page, start, end, tabIndex);
    }
  };

  const tasksPieChart = useMemo(() => {
    return (data?.data?.tasks ?? []).map((task) => ({
      id: task.status.toLowerCase(),
      label:
        capitalizeFirstLetter(task.status) === "Not complete"
          ? "In progress"
          : capitalizeFirstLetter(task.status),
      value: task.number,
      percent: task.percent,
      color:
        colorsMap.find((item) => item.status === capitalizeFirstLetter(task.status))?.color ??
        "black",
    }));
  }, [data]);

  const feedbacksPieChart = useMemo(() => {
    return (data?.data?.feedBacks ?? []).map((task) => ({
      id: task.status.toLowerCase(),
      label: capitalizeFirstLetter(task.status),
      value: task.number,
      percent: task.percent,
      color:
        colorsMap.find((item) => item.status === capitalizeFirstLetter(task.status))?.color ??
        "black",
    }));
  }, [data]);

  const taskLabel = useMemo(() => {
    const total = (data?.data?.tasks ?? []).reduce((sum, item) => {
      return typeof item.number === "number" ? sum + item.number : sum;
    }, 0);

    const finished = data?.data?.tasks
      .filter((item) => capitalizeFirstLetter(item.status).includes("Completed"))
      .reduce((sum, item) => {
        return typeof item.number === "number" ? sum + item.number : sum;
      }, 0);
    return `${finished}/${total}`;
  }, [data]);

  const feedbackLabel = useMemo(() => {
    const total = (data?.data?.feedBacks ?? []).reduce((sum, item) => {
      return typeof item.number === "number" ? sum + item.number : sum;
    }, 0);

    const finished = data?.data?.feedBacks
      .filter((item) => capitalizeFirstLetter(item.status).includes("Completed"))
      .reduce((sum, item) => {
        return typeof item.number === "number" ? sum + item.number : sum;
      }, 0);
    return `${finished}/${total}`;
  }, [data]);

  const taskOverdue = useMemo(() => {
    return (
      data?.data?.tasks.find((item) => capitalizeFirstLetter(item.status) === "In progress overdue")
        ?.number ?? 0
    );
  }, [data]);

  const taskOnCompleteToday = useMemo(() => {
    return data?.data?.tasks
      .filter(
        (item) =>
          capitalizeFirstLetter(item.status) === "New" ||
          capitalizeFirstLetter(item.status) === "In progress"
      )
      .reduce((sum, item) => {
        return typeof item.number === "number" ? sum + item.number : sum;
      }, 0);
  }, [data]);

  const feedbackOverdue = useMemo(() => {
    return (
      data?.data?.feedBacks.find(
        (item) => capitalizeFirstLetter(item.status) === "In progress overdue"
      )?.number ?? 0
    );
  }, [data]);

  const feedbackOnCompleteToday = useMemo(() => {
    return data?.data?.feedBacks
      .filter(
        (item) =>
          capitalizeFirstLetter(item.status) === "New" ||
          capitalizeFirstLetter(item.status) === "In progress"
      )
      .reduce((sum, item) => {
        return typeof item.number === "number" ? sum + item.number : sum;
      }, 0);
  }, [data]);

  const minimumFetchingTime = isFetching;

  return {
    data,
    loading: isFetchingPercent,
    tasksPieChart,
    feedbacksPieChart,
    taskLabel,
    feedbackLabel,
    taskOverdue,
    taskOnCompleteToday,
    feedbackOverdue,
    feedbackOnCompleteToday,
    setStart,
    setEnd,
    list,
    totalPage,
    currentPage: page,
    tabs,
    tabIndex,
    setTabIndex,
    handleNext,
    handlePrev,
    setPage,
    refetch,
    minimumFetchingTime,
  };
};
