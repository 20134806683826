import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import MDTypography from "components/MDTypography";
import { Performance } from "./components/Performance";
import { Offtrack } from "./components/Offtrack";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useGetStudentDetailQuery } from "shared/redux/endpoints/assistant";
import { Loading } from "shared/components/ui/Loading";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import TutoringRecord, { ITutoringRecordProps } from "components/TutoringRecord/TutoringRecord";
import { useMyAsistantAPIs } from "shared/redux/endpoints/endPointMyAssistant";
import type { Error400, Success200 } from "utilities/toastUtil";
import { ToastUtil } from "utilities/toastUtil";
import { useSelector } from "react-redux";
import NotFound from "assets/images/apollo-english/not-found.png";

function StudentDetail() {
  let { id } = useParams();

  const { data, isLoading } = useGetStudentDetailQuery({
    id,
  });

  const {
    isLoading: isLoadingTutoringRecord,
    data: rows,
    currentPage,
    pageSize,
    totalPage,
    totalElement,
  } = useSelector((state) => state.myAssistant.callGetPageStudentTutoringRecord);

  // Call API
  const getPageStudentTutoringRecord = useMyAsistantAPIs.useGetPageStudentTutoringRecord();

  const callGetPageStudentTutoringRecord = useCallback(
    async (params) => {
      await getPageStudentTutoringRecord(
        params,
        undefined,
        (response: Success200) => {
          console.log(response);
        },
        (error: Error400) => {
          ToastUtil.showToastError400(error);
        }
      );
    },
    [getPageStudentTutoringRecord]
  );

  useEffect(() => {
    const fetchData = async () => {
      console.log("id", id);
      await callGetPageStudentTutoringRecord({
        studentId: id,
        page: currentPage,
        size: pageSize,
      });
    };
    fetchData().then();
  }, []);

  const propertiesTutoringRecord: ITutoringRecordProps = {
    isLoading: isLoadingTutoringRecord,
    rows,
    currentPage,
    pageSize,
    totalPage,
    totalElement,
    onChangePage: async (event, value) => {
      await callGetPageStudentTutoringRecord({
        studentId: id,
        page: value - 1,
        size: pageSize,
      });
    },
    onChangePageSize: async (event) => {
      let selectPageSize = event.target.value;
      let page = currentPage;
      if (selectPageSize * currentPage > totalElement) {
        page = Math.floor(totalElement / selectPageSize) + 1;
      }

      await callGetPageStudentTutoringRecord({
        studentCode: id,
        page: page - 1,
        size: event.target.value,
      });
    },
  };

  return (
    <DashboardLayout>
      <Header />
      <MDBox
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          position: "relative",
          top: pxToRem(-20),
          margin: "0 auto",
          background: "white",
          width: pxToRem(1200),
          padding: `${pxToRem(24)} ${pxToRem(12)}`,
          borderRadius: pxToRem(15),

          [breakpoints.up("xxl")]: {
            maxWidth: `calc(100% - 150px)`,
          },

          [breakpoints.up("xl")]: {
            maxWidth: `calc(100% - 120px)`,
          },

          [breakpoints.up("lg")]: {
            maxWidth: `calc(100% - 100px)`,
          },

          [breakpoints.up("md")]: {
            maxWidth: `calc(100% - 80px)`,
          },

          [breakpoints.down("md")]: {
            maxWidth: `calc(100% - 40px)`,
          },
        })}
      >
        {isLoading ? (
          <MDBox
            sx={{
              paddingLeft: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading style={{ width: 300, height: 200 }} />
          </MDBox>
        ) : data?.data?.data.length === 0 ? (
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              height: 600,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 36,
              objectFit: "contain",

              [breakpoints.down(1600)]: {
                height: 400,
              },
            })}
            component="img"
            src={NotFound}
          ></MDBox>
        ) : (
          <>
            <MDBox
              sx={{
                paddingLeft: "12px",
              }}
            >
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...titleTypography(breakpoints),
                  lineHeight: 1.625,
                  letterSpacing: "-0.8px",
                  color: "#2D3748",
                })}
              >
                {data?.data?.data?.[0]?.studentName}&apos; Detail
              </MDTypography>
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...descriptionTypography(breakpoints),
                  lineHeight: 1.5,
                  letterSpacing: "-0.8px",
                  color: "#2D3748",
                })}
              >
                Student detail page
              </MDTypography>
            </MDBox>
            <Performance data={data?.data?.data ?? []} />
            <Offtrack data={data?.data?.data ?? []} />
          </>
        )}
        <TutoringRecord {...propertiesTutoringRecord} />
      </MDBox>
    </DashboardLayout>
  );
}

export default StudentDetail;
