import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import Card from "./components/Card";
import SpecialCard from "./components/SpecialCard";
import Leaderboard from "./components/Leaderboard";
import Table from "./components/TableContent";
import Advertise from "./components/Advertise";
import { cards } from "./data/card";
import leaderboards from "./data/leaderboar";
import categories from "./data/categories";
import { useMaterialUIController } from "context";
import {
  homeContainer,
  studentAtRiskStyle,
  studentAtRiskTitleStyle,
  styleSelect,
  homeStyles,
} from "./style";
import makeStyles from "pages/style";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";
import { Category } from "./components/Category";
import { useEffect } from "react";
import { useLazyGetPerformanceQuery } from "shared/redux/endpoints/teacher";
import FormField from "layouts/pages/account/components/FormField";
import ChevronDownIcon from "assets/images/apollo-english/chevron-down.svg";
import { useStudentAtRisk } from "./hooks/useStudentAtRisk";
import { useSelector } from "react-redux";
import { selectAuth } from "../../shared/redux/slices/authSlice";
import { Task } from "./components/Task";
import { Event } from "./components/Event";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "../../components/InfoIcon/teacherInfoIcon";
import { useHomePage } from "../../shared/redux/endpoints/endPointHomePage";
import type { Error400, Success200 } from "../../utilities/toastUtil";
import { ToastUtil } from "../../utilities/toastUtil";

const customStyle = {
  height: "100%",
  minWidth: "160px",
  "& *": {
    fontFamily: "'Lexend', san-serif !important",
  },
  "& .MuiOutlinedInput-root": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& input, & input::placeholder": {
    fontSize: "12px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: "Lexend",
  },

  "& input": {
    paddingLeft: `14px !important`,
    padding: "8px",
  },
};

function Home() {
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;
  const [trigger, { data: dataPerformance, isLoading }] = useLazyGetPerformanceQuery();
  const classes = makeStyles();

  const {
    classCode,
    data,
    setClassCode,
    dataClass = {},
    listClass = [],
    page,
    setPage,
    isLoading: isLoadingStudentAtRisk,
    isEmpty,
    offtrackDuration,
    setOfftrackDuration,
    durations,
    setDurations,
    status,
    criteria,
  } = useStudentAtRisk();

  useEffect(() => {
    trigger();
    const fetchData = async () => {
      await getListLink(null, undefined, undefined, (error: Error400) => {
        ToastUtil.showToastError400(error);
      });
    };
    fetchData().then();
  }, []);

  const currentKPI = dataPerformance?.data?.teacherKPI?.currentMonthTeachingKPI ?? 0;
  const lastKPI = dataPerformance?.data?.teacherKPI?.lastMonthTeachingKPI ?? 0;

  const auth = useSelector(selectAuth);

  const role = auth.role;

  const getListLink = useHomePage.useGetListLink();
  const { data: dataGetListLink } = useSelector((state) => state.homePage.callGetListLink);

  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={(theme) => homeStyles(theme, { miniSidenav })}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={2}
          sx={homeContainer}
        >
          {!isLoading ? (
            <>
              <Grid item xs={12} sm={6} md={4} xl={2.4}>
                <SpecialCard progress={currentKPI} diff={currentKPI - lastKPI} />
              </Grid>
              {cards.map((card, index) => {
                let progress = 0,
                  diff = 0;
                switch (index) {
                  case 0:
                    const currentMonthRate =
                      dataPerformance?.data?.renewalRate?.currentMonthRate ?? 0;
                    const lastMonthRate = dataPerformance?.data?.renewalRate?.lastMonthRate ?? 0;
                    progress = currentMonthRate;
                    diff = currentMonthRate - lastMonthRate;
                    break;
                  case 1:
                    const currentWeekCompleteRate =
                      dataPerformance?.data?.weLearnCourseProgress?.currentWeekCompleteRate ?? 0;
                    const lastWeekCompleteRate =
                      dataPerformance?.data?.weLearnCourseProgress?.lastWeekCompleteRate ?? 0;
                    progress = currentWeekCompleteRate;
                    diff = currentWeekCompleteRate - lastWeekCompleteRate;
                    break;
                  case 2:
                    const currentMonthTeachingHours =
                      dataPerformance?.data?.teachingHour?.currentMonthTeachingHours ?? 0;
                    const lastMonthTeachingHours =
                      dataPerformance?.data?.teachingHour?.lastMonthTeachingHours ?? 0;
                    progress = currentMonthTeachingHours ?? 0;
                    diff = currentMonthTeachingHours - lastMonthTeachingHours;
                    break;
                  case 3:
                    const lastMonthConversionRate =
                      dataPerformance?.data?.conversionRate?.lastMonthRate ?? 0;
                    const currentMonthConversionRate =
                      dataPerformance?.data?.conversionRate?.currentMonthRate ?? 0;
                    progress = currentMonthConversionRate ?? 0;
                    diff = currentMonthConversionRate - lastMonthConversionRate;
                    break;
                  default:
                    break;
                }
                return (
                  <Grid key={index} item xs={12} sm={6} md={4} xl={2.4}>
                    <Card {...card} progress={progress} diff={diff} />
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              {Array.from(new Array(5)).map((item) => (
                <Grid key={item} item xs={12} sm={6} md={4} xxl={2.4}>
                  <Skeleton variant="rectangular" height={100} />
                </Grid>
              ))}
            </>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          sx={({ breakpoints, functions: { pxToRem } }) => ({
            marginTop: pxToRem(20),

            [breakpoints.down("xxl")]: {
              rowGap: 4,
            },
          })}
          columnSpacing={2.5}
          height="100%"
        >
          {/*{!isLoading &&*/}
          {/*  leaderboards.map((leaderboard, index) => (*/}
          {/*    <Grid key={index} item xs={12} lg={6} xxl={3.6}>*/}
          {/*      <Leaderboard {...leaderboard} id={index} data={dataPerformance?.data?.teacherKPI} />*/}
          {/*    </Grid>*/}
          {/*  ))}*/}
          {role === "CSO" ? (
            <Grid container item xs={12} xxl={7.2} columnSpacing={3} rowGap={3}>
              {!isLoading &&
                leaderboards.map((leaderboard, index) => (
                  <Grid key={index} item xs={12} lg={6} xxl={6}>
                    <Leaderboard
                      {...leaderboard}
                      id={index}
                      data={dataPerformance?.data?.teacherKPI}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Grid item xs={12} lg={8} xxl={7.5}>
              <MDBox height="100%" sx={(theme) => studentAtRiskStyle(theme)}>
                <InfoIcon
                  teacherInfoIconBlock={teacherInfoIconBlock.STUDENT_AT_RISK}
                  type={TooltipType.TYPE_1}
                  color={TooltipIconColor.BLUE}
                  className={classes.containerInfoIconWith1}
                >
                  <MDTypography
                    px={3}
                    py={2}
                    lineHeight={1}
                    variant="h3"
                    sx={(theme) => studentAtRiskTitleStyle(theme)}
                  >
                    STUDENTS AT RISK
                  </MDTypography>
                </InfoIcon>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={2.25}
                  width="100%"
                  height="100%"
                  px={3}
                  pb={3}
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("lg")]: {
                      justifyContent: "flex-start",
                    },
                  })}
                >
                  {/* <Grid
                    item
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("md")]: { width: "100%" },
                    })}
                  >
                    <DateSelect
                      onChangeStartDate={(e) => {
                        setStart(e);
                      }}
                      onChangeEndDate={(e) => {
                        setEnd(e);
                      }}
                      defaultEndDate={moment(new Date()).endOf("month").format("MM-DD-YYYY")}
                      defaultStartDate={moment(new Date()).startOf("month").format("MM-DD-YYYY")}
                      disable={isLoadingStudentAtRisk}
                    />
                  </Grid> */}
                  <Grid
                    item
                    sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}
                  >
                    <FormControl sx={{ minWidth: 160, width: "100%" }} size="medium">
                      <InputLabel>Class</InputLabel>
                      <Select
                        id="demo-select-small"
                        value={classCode}
                        label="Class"
                        disabled={listClass.length === 0}
                        onChange={(e) => {
                          setClassCode(e.target.value);
                        }}
                        IconComponent={() => (
                          <div
                            style={{
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              marginRight: 8,
                            }}
                          >
                            <img src={ChevronDownIcon} style={{ height: 12 }} />
                          </div>
                        )}
                        sx={(theme) => styleSelect(theme)}
                      >
                        <MenuItem value="">
                          <em>All</em>
                        </MenuItem>
                        {listClass.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}
                  >
                    <Autocomplete
                      value={offtrackDuration}
                      multiple
                      options={criteria}
                      onChange={(e, v) => {
                        setOfftrackDuration(v);
                      }}
                      popupIcon={
                        <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                          <img src={ChevronDownIcon} />
                        </div>
                      }
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          variant="outlined"
                          label="Offtrack category"
                          customStyle={customStyle}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}
                  >
                    <Autocomplete
                      value={durations}
                      options={status ?? []}
                      onChange={(e, v) => {
                        setDurations(v);
                      }}
                      popupIcon={
                        <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                          <img src={ChevronDownIcon} />
                        </div>
                      }
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          variant="outlined"
                          label="Duration"
                          customStyle={customStyle}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Table
                  data={data}
                  dataClass={dataClass}
                  currentPage={page}
                  setCurrentPage={setPage}
                  handleNext={() => setPage((page) => page + 1)}
                  handlePrev={() => setPage((page) => page - 1)}
                  isLoading={isLoadingStudentAtRisk}
                  isEmpty={isEmpty}
                />
              </MDBox>
            </Grid>
          )}

          {role === "CSO" ? (
            <Grid item xs={12} lg={6} xxl={4.8}>
              <Task />
            </Grid>
          ) : (
            <Grid item xs={12} lg={4} xxl={4.5} sx={{ minHeight: 600 }}>
              <Advertise />
            </Grid>
          )}
        </Grid>

        {role === "CSO" ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            mt={3}
            columnSpacing={3}
            rowGap={3}
          >
            {categories.map((category, index) => (
              <Grid item width="100%" xs={12} xl={4} xxl={3.6} key={index}>
                <Category
                  key={index}
                  index={index}
                  category={category}
                  dataLink={dataGetListLink}
                />
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              xxl={4.8}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Event />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            mt={3}
            columnSpacing={3}
            rowGap={3}
            sx={{ height: "100%" }}
          >
            {!isLoading &&
              leaderboards
                .filter((item) => item.header !== "TEACHER LEADER BOARD")
                .map((leaderboard, index) => (
                  <Grid key={index} item xs={12} xl={4} xxl={4}>
                    <Leaderboard
                      {...leaderboard}
                      id={index}
                      data={dataPerformance?.data?.teacherKPI}
                    />
                  </Grid>
                ))}

            {categories.map((category, index) => (
              <Grid item width="100%" xs={12} xl={4} xxl={4} key={index}>
                <Category index={index} category={category} dataLink={dataGetListLink} />
              </Grid>
            ))}
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Home;
