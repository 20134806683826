import React from "react";
import MDBox from "../MDBox";
import { ProfileHeader } from "pages/my-self/components/ProfileHeader";
import Card from "@mui/material/Card";
import PinCodeChangeForm from "../PinCodeChangeForm/PinCodeChangeForm";
import PinCodeSetupForm from "../PinCodeSetupForm/PinCodeSetupForm";
import { useSelector } from "react-redux";
import { ChangePin, CreatePin } from "model/MySelfContainer.model";
import type { IPinCodeChangeFormProps } from "../PinCodeChangeForm/PinCodeChangeForm";
import type { IPinCodeSetupFormProps } from "../PinCodeSetupForm/PinCodeSetupForm";

export interface IYourPinCodeProps {
  createPin: CreatePin;
  setCreatePin: (value: CreatePin) => void;
  changePin: ChangePin;
  isEnablePin: boolean;
  pinCodeHide: string;
  setChangePin: (value: ChangePin) => void;
  onCreatePin: (createPin: CreatePin) => void;
  onChangePin: (changePin: ChangePin) => void;
}

const YourPinCode = (props: IYourPinCodeProps) => {
  const {
    createPin,
    setCreatePin,
    changePin,
    setChangePin,
    isEnablePin,
    onCreatePin,
    onChangePin,
    pinCodeHide,
  } = props;

  const { isLoading: loadingPostCreatePin } = useSelector(
    (state) => state.mySelf.callPostCreatePin
  );
  const { isLoading: loadingPutUpdatePin } = useSelector((state) => state.mySelf.callPutUpdatePin);

  const handlePinSetup = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setCreatePin({ ...createPin, pinSetup: value, error: "" });
    }
  };

  const handleConfirmPinSetup = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setCreatePin({ ...createPin, confirmPinSetup: value, error: "" });
    }
  };

  const handlePinChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setChangePin({ ...changePin, pinChange: value, error: "" });
    }
  };

  const handleConfirmPinChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setChangePin({ ...changePin, confirmPinChange: value, error: "" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEnablePin) {
      setChangePin((prev) => ({ ...prev, error: "" }));

      if (!/^[0-9]{4}$/.test(changePin.pinChange)) {
        setChangePin((prev) => ({
          ...prev,
          error: "PIN must be exactly 4 digits. Please try again.",
        }));
        return;
      }

      if (changePin.pinChange !== changePin.confirmPinChange) {
        setChangePin((prev) => ({ ...prev, error: "Pin does not match. Please try again." }));
        return;
      }
      onChangePin(changePin);
    } else {
      setCreatePin((prev) => ({ ...prev, error: "" }));

      if (!/^[0-9]{4}$/.test(createPin.pinSetup)) {
        setCreatePin((prev) => ({
          ...prev,
          error: "PIN must be exactly 4 digits. Please try again.",
        }));
        return;
      }

      if (createPin.pinSetup !== createPin.confirmPinSetup) {
        setCreatePin((prev) => ({ ...prev, error: "Pin does not match. Please try again." }));
        return;
      }
      onCreatePin(createPin);
    }
  };

  const propertiesPinCodeSetupForm: IPinCodeSetupFormProps = {
    createPin,
    handlePinSetup,
    handleConfirmPinSetup,
    isLoadingSubmit: loadingPostCreatePin,
    handleSubmit,
  };

  const propertiesPinCodeChangeForm: IPinCodeChangeFormProps = {
    changePin,
    handlePinChange,
    handleConfirmPinChange,
    isLoadingSubmit: loadingPutUpdatePin,
    handleSubmit,
    pinCodeHide,
  };

  return (
    <MDBox className="your-pin-code-container">
      <MDBox className="card-profile-header">
        <ProfileHeader />
      </MDBox>
      <Card className="pin-card-container">
        <h2 className="pin-card-title">{isEnablePin ? "Change PIN code" : "Set PIN code"}</h2>
        <p className="pin-card-description">
          {isEnablePin
            ? "You'll be prompted to enter PIN code each time you open Myself."
            : "You'll be prompted to enter PIN code each time you open Myself."}
        </p>
        <Card className="pin-card-box">
          {isEnablePin ? (
            <PinCodeChangeForm {...propertiesPinCodeChangeForm} />
          ) : (
            <PinCodeSetupForm {...propertiesPinCodeSetupForm} />
          )}
        </Card>
      </Card>
    </MDBox>
  );
};

export default YourPinCode;
