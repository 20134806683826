export const TypeObservation = {
  PROBATION: "PROBATION",
  POP_IN: "POP IN",
  FORMAL: "FORMAL",
  REGULAR: "Regular",
};

export const ListTypeObservation = [
  {
    label: TypeObservation.PROBATION,
    value: TypeObservation.PROBATION,
  },
  {
    label: TypeObservation.POP_IN,
    value: TypeObservation.POP_IN,
  },
  {
    label: TypeObservation.FORMAL,
    value: TypeObservation.FORMAL,
  },
  {
    label: TypeObservation.REGULAR.toUpperCase(),
    value: TypeObservation.REGULAR,
  },
];
