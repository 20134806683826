import { api } from "../api";
import { useLazyQueryWithCallbacks } from "../../hooks/useLazyQueryWithCallbacks";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPageStudentTutoringRecord: build.query({
      query: (arg) => {
        const { studentId, page, size } = arg;
        return {
          url: `/private/student/v1/tutoring-record/${studentId}?page=${page}&size=${size}&sort=id%2Casc`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetPageStudentTutoringRecordQuery } = injectedRtkApi;

export const useMyAsistantAPIs = {
  useGetPageStudentTutoringRecord: useLazyQueryWithCallbacks(
    useLazyGetPageStudentTutoringRecordQuery
  ),
};
