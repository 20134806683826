import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const CustomButton = styled(Button)({
  fontFamily: "Lexend, sans-serif",
  background: "linear-gradient(121.78deg, #11CDEF 9.06%, #1171EF 88.15%)",
  color: "#FFFFFF",
  height: "30px",
  textTransform: "none",
  borderRadius: "8px",
  padding: "8px 16px 8px 16px",
  fontSize: "12px",
  fontWeight: "bold",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    color: "#FFFFFF",
    transform: "scale(1.05)",
    boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
  },
  "&:focus": {
    color: "#FFFFFF",
  },
  "&:focus:not(:hover)": {
    color: "#FFFFFF",
  },
  "&:active": {
    color: "#FFFFFF",
  },
});

const RequestTutoringButton = ({ onClick }) => {
  return <CustomButton onClick={onClick}>Request tutoring</CustomButton>;
};

export default RequestTutoringButton;
