import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const CustomButton = styled(Button)({
  fontFamily: "Lexend, sans-serif",
  background: "linear-gradient(124deg, #5E72E4 -1.8%, #825EE4 105.23%)",
  color: "#FFFFFF",
  height: "30px",
  textTransform: "none",
  borderRadius: "8px",
  padding: "6px 32px 6px 32px",
  fontSize: "12px",
  fontWeight: "bold",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    color: "#FFFFFF",
    transform: "scale(1.05)",
    boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
  },
  "&:focus": {
    color: "#FFFFFF",
  },
  "&:focus:not(:hover)": {
    color: "#FFFFFF",
  },
  "&:active": {
    color: "#FFFFFF",
  },
  "& .MuiButton-startIcon": {
    marginRight: 5,
  },
});

const CustomAddIcon = styled(AddIcon)({
  width: "22px",
  height: "22px",
});

const ButtonAdminAddNew = ({ onClick }) => {
  return (
    <CustomButton startIcon={<CustomAddIcon className={"add-new"} />} onClick={onClick}>
      Add New
    </CustomButton>
  );
};

export default ButtonAdminAddNew;
